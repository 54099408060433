<i18n locale="en">
{
  "empty-title": "Photo"
}
</i18n>

<template>
  <PreviewSheet background="primary" radius="0" class="image-preview px-2 py-3">
    <PreviewSheet class="overflow-hidden" :placeholder="!content.data">
      <img
        v-if="content.data?.image"
        class="image-preview__image"
        :src="content.data.image"
      />
      <PreviewSheet
        v-else
        background="secondary"
        class="pa-6 flex-col align-center justify-center h-full w-full"
      >
        <PreviewIcon color="secondary" size="24" :icon="emptyIcon" />
        <PreviewTypography
          :text="$t('empty-title')"
          color="secondary"
          variant="paragraphSmall"
        />
      </PreviewSheet>
    </PreviewSheet>
  </PreviewSheet>
</template>

<script>
import { PhImageSquare } from 'phosphor-vue/dist/phosphor-vue.esm'

import { PreviewSheet, PreviewTypography, PreviewIcon } from '@/lib/builder'

export default {
  name: 'ImagePreview',
  components: {
    PreviewIcon,
    PreviewSheet,
    PreviewTypography,
  },
  props: {
    content: { type: Object, required: true },
  },
  data() {
    return {
      emptyIcon: PhImageSquare,
      isLoading: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.image-preview {
  &__image {
    width: 100%;
    border-radius: inherit;
    object-fit: scale-down;
  }
}
</style>
