<i18n locale="en">
{
  "title": "Give the Perfect Gift for Any Occasion"
}
</i18n>

<template>
  <PreviewSheet>
    <OwnStack class="gift-cards-preview">
      <GiftCardOrderType />
      <OwnStack class="gift-cards-preview__form-wrapper" spacing="12">
        <GiftCardTypeSelector />
        <OwnStack row class="gift-cards-preview__form-layout">
          <OwnStack class="gift-cards-preview__form-heading" spacing="8">
            <PreviewDecorationLine class="gift-cards-preview__line" />
            <OwnStack spacing="2">
              <PreviewTypographyLegacy
                :text="currentBlockParams.heading"
                variant="heading2"
              />
              <PreviewTypographyLegacy
                :text="currentBlockParams.subheading"
                variant="paragraphRegular"
              />
            </OwnStack>
          </OwnStack>
          <GiftCardForm class="gift-cards-preview__form" />
        </OwnStack>
      </OwnStack>
    </OwnStack>
  </PreviewSheet>
</template>

<script>
import {
  PreviewDecorationLine,
  PreviewSheet,
  PreviewTypographyLegacy,
} from '@/lib/builder'
import { OwnStack } from '@/ui'

import GiftCardForm from './GiftCardForm'
import GiftCardOrderType from './GiftCardOrderType'
import GiftCardTypeSelector from './GiftCardTypeSelector'

export default {
  name: 'GiftCardsPreview',
  components: {
    GiftCardForm,
    GiftCardOrderType,
    GiftCardTypeSelector,
    OwnStack,
    PreviewDecorationLine,
    PreviewSheet,
    PreviewTypographyLegacy,
  },
  props: {
    content: { type: Object, required: true },
  },
  computed: {
    currentBlockParams() {
      const { content } = this

      return content?.params
    },
  },
}
</script>

<style lang="scss" scoped>
.gift-cards-preview {
  width: 1280px;
  margin: 0 auto;
  &__line {
    width: 84px;
  }
  &__heading {
    margin-left: 16px;
    margin-top: 120px;
    margin-bottom: 120px;
    width: 720px;
  }
  &__form-wrapper {
    padding-bottom: 80px;
  }
  &__form-layout {
    gap: 126px;
  }
  &__form,
  &__form-heading {
    width: 50%;
  }
}
</style>
