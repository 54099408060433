<i18n locale="en">
{
  "no-data": "Design your own text!\n\nhttps://owner.com"
}
</i18n>

<template>
  <OwnStack class="text-preview" justify="center">
    <OwnStack class="text-preview__bubble" spacing="3">
      <OwnType
        color="primary"
        variant="paragraph"
        :text="content.data?.text || $t('no-data')"
        hotlink-urls
        preserve-whitespace
      />
    </OwnStack>
  </OwnStack>
</template>

<script>
import { OwnStack, OwnType } from '@/ui'

export default {
  name: 'TextPreview',
  components: { OwnStack, OwnType },
  props: {
    content: { type: Object, required: true },
  },
}
</script>

<style lang="scss" scoped>
.text-preview {
  margin-left: 50px; /* Push the edit button off to the left */

  &__bubble {
    background-color: $background-secondary;
    padding: 16px;
    border-radius: 18px;
    width: 220px;
  }
}
</style>
