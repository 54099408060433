<i18n locale="en">
{
  "actions": {
    "replace": "Replace",
    "remove": "Remove"
  },
  "labels": {
    "upload": "Upload a photo",
    "upload-limit-reached": "{limit}/{limit} photo limit reached. Delete a photo before you can upload another.",
    "upload-with-limit": "Upload a photo {currentTotal}/{limit}"
  }
}
</i18n>

<template>
  <div ref="reorder-grid" class="multi-image-input">
    <ImageInput
      :aspect-ratio="aspectRatio"
      :crop-width="2000"
      :label="label"
      h-full
      :disabled="limit !== undefined && images.length >= limit"
      :upload-label="uploadLabel"
      class="multi-image-input__input"
      hide-edit-button
      @input="onImageUploadComplete"
    />
    <div
      v-for="(img, idx) of images"
      :key="img"
      class="multi-image-input__drag-item"
      :style="
        hoveringIndex === idx
          ? {
              backgroundImage: `linear-gradient(228.05deg, rgba(0, 0, 0, 0.648) 0%, rgba(0, 0, 0, 0) 14.25%), url(${img})`,
            }
          : { backgroundImage: `url(${img})` }
      "
      @mouseenter="hoveringIndex = idx"
      @mouseleave="hoveringIndex = undefined"
    >
      <OwnActionMenu
        v-if="hoveringIndex === idx"
        class="multi-image-input__action-menu"
        :actions="actions"
        @action="onAction($event, idx)"
      >
        <template #trigger>
          <PhDotsThreeCircle class="text-color-white" size="24" />
        </template>
      </OwnActionMenu>
    </div>
  </div>
</template>

<script>
import { PhDotsThreeCircle } from 'phosphor-vue/dist/phosphor-vue.esm'
import Sortable from 'sortablejs'

import { OwnActionMenu } from '@/ui'
import { mapModel } from '@/utils/computed'
import { reorder } from '@/utils/helpers'

import { ImageInput } from './ImageInput'

const actionGroups = [
  {
    color: 'danger',
    value: 'remove',
  },
]

export default {
  name: 'MultiImageInput',
  components: {
    ImageInput,
    OwnActionMenu,
    PhDotsThreeCircle,
  },
  props: {
    /** Aspect Ratio */
    aspectRatio: { type: Number, default: undefined },

    /** Label of image */
    label: { type: String, required: true },

    /**
     * Maximum number of images to accept.
     */
    limit: {
      type: Number,
      default: undefined,
      validator: (value) => value > 0,
    },

    /**
     * Array of images
     */
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      actions: actionGroups.map((action) => ({
        ...action,
        label: this.$t(`actions.${action.value}`),
      })),
      hoveringIndex: undefined,
    }
  },
  computed: {
    ...mapModel('images'),
    uploadLabel() {
      const { limit, value } = this
      if (limit === undefined) {
        return this.$t('labels.upload')
      }
      if (value.length >= limit) {
        return this.$t('labels.upload-limit-reached', { limit })
      }

      return this.$t('labels.upload-with-limit', {
        currentTotal: value.length,
        limit,
      })
    },
  },
  mounted() {
    Sortable.create(this.$refs['reorder-grid'], {
      animation: 150,
      draggable: '.multi-image-input__drag-item',
      onEnd: async ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return

        // These are `index - 1` because of a little weirdness in the grid rendering. The grid has to
        // be the ref to keep styling but the first element is not draggable.  Sortable doesn't take that
        // into account so we have to here.
        await this.reorderImages({
          newIndex: newIndex - 1,
          oldIndex: oldIndex - 1,
        })
      },
    })
  },
  methods: {
    onAction(event, idx) {
      if (event === 'remove') {
        this.removeImage(idx)
      }
    },
    onImageUploadComplete(newImageURL) {
      const { images } = this
      const updatedValue = [...images, newImageURL]

      this.updateModelValue(updatedValue)
      this.$emit('change', { data: updatedValue, type: 'upload' })
    },
    removeImage(targetIdx) {
      const { images } = this
      const updatedValue = images.filter((_img, idx) => idx !== targetIdx)

      this.updateModelValue(updatedValue)
      this.$emit('change', { data: updatedValue, type: 'remove' })
    },
    reorderImages({ newIndex, oldIndex }) {
      const { images } = this

      const updatedValue = reorder(images, oldIndex, newIndex)

      this.updateModelValue(updatedValue)
      this.$emit('change', { data: updatedValue, type: 'reorder' })
    },
    updateModelValue(newValue) {
      this.images = newValue
    },
  },
}
</script>

<style lang="scss" scoped>
.multi-image-input {
  display: grid;
  gap: 16px 16px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(min-content, max-content);

  &__input {
    aspect-ratio: 1 / 1;
  }

  &__drag-item {
    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    position: relative;

    border-radius: 8px;

    cursor: move;
  }

  &__action-menu {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}
</style>
