import { PhLeaf } from 'phosphor-vue/dist/phosphor-vue.esm'

import { websiteBlockMethods } from '@/features/website-builder/builder/blocks/common/methods'

import { type BuilderBlockModule } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type FeaturesSection,
  type FeaturesSectionCreate,
  type FeaturesSectionUpdate,
  type WebsiteResource,
} from '../../WebsiteBuilder.types'

import FeaturesEditor from './editor/FeaturesEditor.vue'
import { createFeaturesData } from './methods/createFeaturesData'
import FeaturesPreview from './preview/FeaturesPreview.vue'

export const FeaturesBlockModule: BuilderBlockModule<
  FeaturesSection,
  FeaturesSectionCreate,
  FeaturesSectionUpdate,
  WebsiteResource
> = {
  createData: createFeaturesData,
  customEditor: true,
  editor: FeaturesEditor,
  meta: [
    {
      icon: PhLeaf,
      key: 'features',
      label: i18n.t('website-builder.blocks.features'),
      sort: 3,
    },
  ],
  methods: websiteBlockMethods,
  preview: FeaturesPreview,
}
