<template>
  <PreviewSheet class="features-preview" radius="0">
    <OwnStack align="center" justify="center" spacing="16">
      <PreviewTypography :text="content.params.title" variant="heading2" />
      <OwnStack
        class="features-preview__items"
        :style="style"
        justify="center"
        wrap="wrap"
        row
      >
        <FeaturesCard
          v-for="feature in features"
          :key="feature.title"
          :feature="feature"
        />
      </OwnStack>
    </OwnStack>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { PreviewSheet, PreviewTypography } from '@/lib/builder'
import { OwnStack } from '@/ui'

import type { FeaturesSection } from '../../../WebsiteBuilder.types'

import FeaturesCard from './FeaturesCard.vue'

const props = defineProps<{
  content: FeaturesSection
}>()

const features = computed(() => {
  return props.content.params.features
})

const numColumns = computed(() => {
  const numberOfFeatures = features.value.length

  return numberOfFeatures === 4 || numberOfFeatures > 6 ? 4 : 3
})

const style = computed(() => {
  if (numColumns.value === 4) {
    return { maxWidth: '1200px' }
  } else if (numColumns.value === 3) {
    return { maxWidth: '900px' }
  } else {
    return {}
  }
})
</script>

<style lang="scss" scoped>
.features-preview {
  padding: 120px;

  &__items {
    column-gap: 40px;
    row-gap: 56px;
    align-self: center;
  }
}
</style>
