<i18n locale="en">
{
  "placeholders": {
    "all": "All Locations",
    "choose": "Choose a location",
    "filter": "Filter locations..."
  },
  "options": {
    "all": "All Locations"
  }
}
</i18n>

<template>
  <OwnDropdown
    v-show="!(hideSingle && options.length <= 1)"
    v-model="selectedLocationId"
    class="location-selector"
    :options="options"
    :placeholder="
      forceSelection ? $t('placeholders.choose') : $t('placeholders.filter')
    "
    :filter-keys="['label']"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import { OwnDropdown } from '@/ui'
import { mapModel } from '@/utils/computed'

export default {
  name: 'LocationSelector',
  components: {
    OwnDropdown,
  },
  props: {
    /**
     * Forces a single location to be selected
     */
    forceSelection: { type: Boolean, default: false },

    /** Hides the component for a single location */
    hideSingle: { type: Boolean, default: false },

    /**
     * @model
     */
    value: { type: String, default: null },
  },
  computed: {
    ...mapModel('selectedLocationId'),
    ...mapGetters({
      locations: 'core/locations/*',
    }),
    options() {
      const { forceSelection, locations } = this

      const locationOptions = locations.map((location) => ({
        label: location.name,
        value: location.id,
      }))

      if (locations.length > 1 && !forceSelection) {
        locationOptions.unshift({
          label: this.$t('placeholders.all'),
          value: null,
        })
      }

      return locationOptions
    },
  },
  created() {
    const { locations } = this

    if (locations.length === 1) {
      this.selectedLocationId = locations[0].id
    }
  },
}
</script>
