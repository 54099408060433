
{
  "labels": {
    "order-online": "Order Online"
  },
  "bottom-links": {
    "home": "Home",
    "team": "Meet Our Team",
    "events": "Caterings & Events",
    "giftCards": "Gift Cards",
    "careers": "Careers",
    "terms": "Terms Of Use",
    "privacy": "Privacy Policy",
    "accessibility": "Accessibility Statement"
  },
  "legalSection": "Small print",
  "copyright": "2024 All Rights Reserved",
  "madeWithOwner": "Made with Owner"
}
