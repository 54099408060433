import Vue from 'vue'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'

export const discardStagedChangesMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): void => {
  const currentCommittedChanges = state._builder_commandQueue

  Vue.set(
    state,
    '_builder_commandQueue',
    currentCommittedChanges.filter((command) => command.committed)
  )
}
