<i18n locale="en">
{
  "label": "Theme",
  "options": {
    "light": "Light",
    "dark": "Dark"
  }
}
</i18n>

<template>
  <OwnInputContainer :label="$t('label')">
    <OwnSegmentedControl
      :value="themePalette"
      :options="options"
      @input="onPaletteUpdate"
    />
  </OwnInputContainer>
</template>

<script>
import { mapActions } from 'vuex'

import { useMergedTheme } from '@/lib/builder'
import { OwnInputContainer, OwnSegmentedControl } from '@/ui'

const options = [
  {
    value: 'light',
  },
  {
    value: 'dark',
  },
]

export default {
  name: 'ThemePaletteEditor',
  components: {
    OwnInputContainer,
    OwnSegmentedControl,
  },
  mixins: [useMergedTheme],
  data() {
    return {
      options: options.map((option) => ({
        ...option,
        label: this.$t(`options.${option.value}`),
      })),
    }
  },
  computed: {
    themePalette() {
      const { mergedTheme } = this

      return mergedTheme?.palette
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    async onPaletteUpdate(newColor) {
      const update = {
        theme: {
          palette: newColor,
        },
      }

      await this.updateResource(update)
    },
  },
}
</script>
