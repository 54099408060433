
{
  "labels": {
    "carousel-assets": "Carousel photos",
    "headline": "Title",
    "layout": "Layout"
  },
  "layout": {
    "card": "1",
    "cover": "2",
    "cover-center": "3"
  },
  "placeholders": {
    "headline": "Aa"
  }
}
