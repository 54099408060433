
{
  "labels": {
    "photo": "Profile Photo",
    "name": "Name",
    "comment": "Review",
    "rating": "Rating",
    "done": "Done"
  }
}
