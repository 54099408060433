import { ConfiguredClient } from '@/api'

import store from '@/store'

import { type CampaignResource, type CampaignResourceUpdate } from '../types'

import { fetchResource } from './fetchEmailData'

export const updateResourceHandler = async (
  campaignId: string,
  { subject, name, sections, executeAt }: CampaignResourceUpdate
): Promise<CampaignResource> => {
  const { emailTemplateId } = store.getters[
    'emailBuilder/currentResource'
  ] as unknown as CampaignResource

  await Promise.all([
    ConfiguredClient.notifications.v1.templates.emails.email.updateEmailTemplate(
      { body: { name, sections, subject }, params: { emailTemplateId } }
    ),
    ConfiguredClient.marketing.v1.campaigns.campaign.updateCampaign({
      body: {
        executeAt: executeAt ? new Date(executeAt).toISOString() : null,
        name,
      },
      params: { campaignId },
    }),
  ])

  const { resource } = await fetchResource(campaignId)

  return resource
}
