import { type RouteConfig } from 'vue-router'

import { applyMetaToChildren } from '@/utils/router'

export const toolsRouter: RouteConfig[] = [
  {
    children: applyMetaToChildren(
      [
        {
          component: async () => import('../views/SmsSubscriberToolView.vue'),
          meta: {
            title: 'tools.sms-subscriber-import',
          },
          name: 'brand.tools.sms-subscriber-import',
          path: 'sms-subscriber-import',
        },
        {
          component: async () => import('../views/DomainToolView.vue'),
          meta: {
            title: 'tools.dns',
          },
          name: 'brand.tools.dns',
          path: 'dns',
        },
        {
          component: async () => import('../views/CreateHotLaunchToolView.vue'),
          meta: {
            title: 'tools.hot-launch',
          },
          name: 'brand.tools.hot-launch',
          path: 'hot-launch',
        },
        {
          component: async () => import('../views/OnboardingToolView.vue'),
          meta: {
            title: 'tools.onboarding',
          },
          name: 'brand.tools.onboarding',
          path: 'onboarding',
        },
      ],
      {
        roles: ['pb:*'],
      }
    ),
    component: async () => import('../views/ToolsRouterView.vue'),
    path: 'tools',
    redirect: { name: 'brand.tools.sms-suscriber-import' },
  },
]
