
{
  "labels": {
    "email": "Email",
    "phone": "Phone Number",
    "date": "Date/time",
    "name": "Full Name",
    "address": "Address"
  },
  "placeholders": {
    "date": "Select date",
    "time": "Select time",
    "phone": "Phone Number",
    "email": "Email",
    "address": "Address",
    "name": "Aa"
  }
}
