<template>
  <div class="form-builder-preview-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormBuilderPreviewWrapper',
}
</script>

<style lang="scss" scoped>
.form-builder-preview-wrapper {
  margin: 0 auto;
  max-width: 600px;

  .builder-preview {
    padding: 80px 0 64px;
  }
}
</style>
