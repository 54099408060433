
{
  "heading": {
    "label": "Title",
    "placeholder": "Aa"
  },
  "subheading": {
    "label": "Description",
    "placeholder": "Aa"
  }
}
