<i18n locale="en">
{
  "labels": {
    "title": "Title",
    "options": "Options",
    "required": "Required"
  }
}
</i18n>

<template>
  <OwnStack spacing="8">
    <OwnInputContainer
      :label="$t('labels.title')"
      :errors="label.status.errors"
    >
      <OwnInput v-model="label.value" :placeholder="$t('labels.title')" />
    </OwnInputContainer>
    <OwnStack spacing="3">
      <OwnType
        :text="$t('labels.options')"
        color="primary"
        variant="subtitle"
      />
      <OwnToggle
        v-model="required.value"
        :label="$t('labels.required')"
        control
      />
    </OwnStack>
  </OwnStack>
</template>

<script>
import { mapGetters } from 'vuex'

import { FormBuilder } from '@/forms'
import { OwnStack, OwnInputContainer, OwnInput, OwnToggle, OwnType } from '@/ui'

import { FormattedInputCommands } from '../commands/FormattedInputCommands'

export default {
  name: 'FormattedInputEditor',
  components: {
    OwnInput,
    OwnInputContainer,
    OwnStack,
    OwnToggle,
    OwnType,
  },
  mixins: [
    FormBuilder({
      label: {
        validators: [],
      },
      required: {
        initialValue: false,
        validators: [],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  computed: {
    ...mapGetters({
      blockById: 'formBuilder/blockById',
    }),
    currentBlock() {
      const { id } = this

      return this.blockById(id)
    },
    currentBlockParams() {
      const { currentBlock } = this

      return currentBlock?.params
    },
    type() {
      const { currentBlock } = this

      return currentBlock?.type
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues(currentBlockParams)
  },
  methods: {
    async onFormControlUpdate(controlName, update) {
      const { id } = this

      await FormattedInputCommands.updateBlock({
        targetId: id,
        update: { params: { [controlName]: update } },
      })
    },
  },
}
</script>
