import Vue from 'vue'

import defaultState from './state'

export default {
  ADD_SETTINGS(state, { settings }) {
    Vue.set(state, 'settings', {
      ...state.settings,
      ...settings,
    })
  },
  SANITIZE_SETTINGS(state) {
    Vue.set(state, 'settings', defaultState.settings)
  },
  UPDATE_SETTINGS(state, { updatedSettings, locationId }) {
    const thisLocationSettings = state.settings[locationId]

    if (thisLocationSettings) {
      const newSettings = {
        ...thisLocationSettings,
        ...updatedSettings,
      }

      Vue.set(state.settings, locationId, newSettings)
    }
  },
}
