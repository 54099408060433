import merge from 'lodash/merge'

import { type TemplateData } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type FormCreate,
  type FormSectionCreate,
} from '../../types/FormBuilder.types'
import { FormattedInputBlockModule } from '../blocks/formatted-input'
import { SelectBlockModule } from '../blocks/select'
import { TextInputBlockModule } from '../blocks/text-input'

export const eventTemplate = async (
  brandId: string
): Promise<TemplateData<FormCreate, FormSectionCreate>> => ({
  blocks: [
    FormattedInputBlockModule.createData({ brandId, type: 'name' }),
    FormattedInputBlockModule.createData({ brandId, type: 'email' }),
    FormattedInputBlockModule.createData({ brandId, type: 'phone' }),
    merge(FormattedInputBlockModule.createData({ brandId, type: 'date' }), {
      params: {
        label: i18n.t('form-builder.templates.event.date-label'),
        required: true,
      },
    }),
    merge(SelectBlockModule.createData({ brandId, type: 'select' }), {
      params: {
        label: i18n.t('form-builder.templates.event.event-type.label'),
        options: [
          'birthday',
          'corporate',
          'wedding',
          'fundraiser',
          'other',
        ].map((key) =>
          i18n.t(`form-builder.templates.event.event-type.${key}`)
        ),
        required: true,
      },
    }),
    merge(TextInputBlockModule.createData({ brandId, type: 'text-field' }), {
      params: {
        label: i18n.t('form-builder.templates.event.describe-label'),
        placeholder: null,
        required: true,
        variant: 'multiline',
      },
    }),
    merge(SelectBlockModule.createData({ brandId, type: 'select' }), {
      params: {
        label: i18n.t('form-builder.templates.event.number.label'),
        options: ['one', 'ten', 'twenty', 'thirty', 'forty', 'fifty'].map(
          (key) => i18n.t(`form-builder.templates.event.number.${key}`)
        ),
        required: true,
      },
    }),
    merge(SelectBlockModule.createData({ brandId, type: 'select' }), {
      params: {
        label: i18n.t('form-builder.templates.event.catering.label'),
        options: ['no', 'yes'].map((key) =>
          i18n.t(`form-builder.templates.event.catering.${key}`)
        ),
        required: true,
      },
    }),
  ],
  resource: {
    brandId,
    description: i18n.t('form-builder.templates.event.description'),
    submit: i18n.t('form-builder.templates.event.submit'),
    title: i18n.t('form-builder.templates.event.title'),
    type: 'event',
  },
})
