<i18n locale="en">
  {
    "labels": {
      "video": "Video"
    }
  }
  </i18n>

<template>
  <OwnInputContainer :label="$t('labels.video')" :errors="video.status.errors">
    <YoutubeInput v-model="video.value" />
  </OwnInputContainer>
</template>

<script>
import YoutubeInput from '@/components/inputs/YoutubeInput'
import { FormBuilderModelSync, Validators } from '@/forms'
import { OwnInputContainer } from '@/ui'

export default {
  name: 'ContentVideoEditor',
  components: {
    OwnInputContainer,
    YoutubeInput,
  },
  mixins: [
    FormBuilderModelSync({
      video: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
    }),
  ],
  methods: {
    onFormControlUpdate(controlName, value, status) {
      this.$emit('update', { controlName, status, value })
    },
  },
}
</script>
