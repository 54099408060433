
{
  "title": "Upcoming events near you",
  "actions": {
    "see-more": "See More Items"
  },
  "empty": {
    "header": "There are no events right now",
    "sub": "Check back later to see if we’ve added any"
  }
}
