import client from '../client'

/**
 * Starts the password reset process
 * @param {object} userData login request auth header
 * @param {string} userData.email User's email
 */
export const startPasswordReset = ({ email }) =>
  client.post('/auth/verify', { email, type: 'otp' })

/**
 * Sends email to user to start onboarding process
 * @param {string} email User's email
 */
export const sendOnboardingEmail = (email) =>
  client.post('/auth/verify', { email, type: 'onboarding' })

/**
 * Validates the token is valid
 * @param {string} token Token to validate
 * @param {string} tokenType Type of token
 */
export const validateToken = (token, tokenType) =>
  client.post(
    '/auth/validate',
    { type: tokenType },
    {
      headers: {
        Authorization: `Otp ${token}`,
      },
    }
  )

/**
 * Send the new password
 * @param {string} password User's new password
 * @param {string} token Otp token
 */
export const resetPassword = (password, token) =>
  client.post(
    '/auth/reset',
    { password },
    {
      headers: {
        Authorization: `Otp ${token}`,
      },
    }
  )

/**
 * Calls the login endpoint
 * @param {object} auth login request auth header
 * @param {string} auth.email User's email
 * @param {string} auth.password Attempted password
 * @returns {Promise} a login request
 */
export const login = ({ email, password }) =>
  client.post(
    '/auth/login',
    {},
    {
      auth: { password, username: email },
    }
  )

/**
 * Calls the register endpoint
 * @param {object} newUserData login request auth header
 * @param {string} newUserData.firstName User's First Name
 * @param {string} newUserData.lastName User's Last Name
 * @param {string} newUserData.email User's Email
 * @param {string} newUserData.password User's Password
 */
export const register = (newUserData) =>
  client.post('/auth/register', newUserData)

/**
 * Calls the logout endpoint
 * @returns {Promise} a logout request
 */
export const logout = () => client.post('/auth/logout')
