<i18n locale="en">
{
  "title": "Some of the sections you’ve added haven’t been completed:",
  "warnings": {
    "item": "Menu Item",
    "coupon": "Coupon",
    "heading": "Text (heading)",
    "subheading": "Text (subheading)",
    "paragraph": "Text (paragraph)",
    "image": "Image",
    "text": "Text message body"
  }
}
</i18n>

<template>
  <div class="invalid-configuration-content">
    <OwnStack spacing="6">
      <OwnType variant="paragraph" color="primary" :text="$t('title')" />
      <OwnStack spacing="3">
        <OwnStack v-for="(warning, i) in warnings" :key="i" row spacing="2">
          <PhWarning size="24" class="status-warning" />
          <OwnType
            variant="paragraph"
            color="primary"
            :text="$t(`warnings.${warning}`)"
          />
        </OwnStack>
      </OwnStack>
    </OwnStack>
  </div>
</template>

<script>
import { PhWarning } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnStack, OwnType } from '@/ui'

export default {
  name: 'InvalidConfigurationContent',
  components: { OwnStack, OwnType, PhWarning },
  props: {
    warnings: { type: Array, default: () => [] },
  },
}
</script>
