
{
  "errors": {
    "load": "There was a problem loading the analytics for this campaign"
  },
  "labels": {
    "sales": "Sales",
    "orders": "Orders",
    "click-rate": "Click Rate",
    "sent": "Sent",
    "delivered": "Delivered",
    "opened": "Opened",
    "bounced": "Bounced"
  }
}
