import { type RouteConfig } from 'vue-router'

/**
 * Creates a list of Order routes under a specific scope
 * @param {string} scope location or brand
 * @returns {object[]} a list of routes
 */
export const getOrdersRoutes = (scope: 'brand' | 'location'): RouteConfig[] => [
  {
    component: async () => import('@/views/BrandView/OrdersView.vue'),
    meta: {
      isLocationPage: true,
      scopes: ['orders:read'],
      title: 'restaurant.orders',
    },
    name: `${scope}.orders`,
    path: 'orders',
    props: { scope },
  },
  {
    component: async () => import('@/views/BrandView/OrdersView.vue'),
    meta: {
      isLocationPage: true,
      scopes: ['orders:read'],
      title: 'restaurant.orders',
    },
    name: `${scope}.orders.order`,
    path: 'orders/:orderId?',
    props: { scope },
  },
]
