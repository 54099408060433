<template>
  <ItemSelectorSingle
    v-if="resource.locationId && resource.brandId"
    class="item-editor"
    :value="itemId"
    :location-id="resource.locationId"
    :brand-id="resource.brandId"
    @input="update"
  />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

import { ItemSelectorSingle } from '@/features/menu'
import { useMappedGetter } from '@/store'

import { CampaignResource, ItemSection } from '../../../types'
import { ItemCommands } from '../commands/ItemCommands'

const props = defineProps<{ id: string }>()

const itemId = ref<string>()

const blockById = useMappedGetter<(blockId: string) => ItemSection>(
  'emailBuilder/blockById'
)
const resource = useMappedGetter<CampaignResource>(
  'emailBuilder/currentResource'
)
const currentBlock = computed<ItemSection>(() => {
  return blockById.value(props.id)
})

const update = async (selectedItemId: string) => {
  itemId.value = selectedItemId
  await ItemCommands.updateBlock({
    targetId: props.id,
    update: { data: { params: { itemId: itemId.value }, type: 'static' } },
  })
}
onMounted(() => {
  if (currentBlock.value?.data?.type === 'static') {
    itemId.value = currentBlock.value?.data?.params?.itemId
  }
})
</script>
