<i18n locale="en">
{
  "options": {
    "America/Anchorage": "Alaska Time (AKST / AKDT)",
    "America/Chicago": "Central Time (CST)",
    "America/New_York": "Eastern Time (EST)",
    "Pacific/Honolulu": "Hawaii Standard Time (HST)",
    "America/Phoenix": "Mountain Standard Time (MST) (no DST)",
    "America/Denver": "Mountain Daylight Time (MDT)",
    "America/Los_Angeles": "Pacific Time (PST)"
  }
}
</i18n>

<template>
  <OwnDropdown
    v-model="modelValue"
    :options="options"
    :filter-keys="['label']"
  />
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'

import { OwnDropdown } from '@/ui'
import { useI18n } from '@/utils'

const i18n = useI18n()

const allowedTimezones = [
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Denver',
  'America/Phoenix',
  'America/Chicago',
  'America/New_York',
] as const

export type AllowedTimezone = (typeof allowedTimezones)[number]

const props = defineProps<{ value: AllowedTimezone }>()
const emit = defineEmits<{ (event: 'input', value: AllowedTimezone): void }>()

const modelValue = useVModel(props, 'value', emit, { eventName: 'input' })

const options = allowedTimezones.map((value) => ({
  label: i18n.$t(`options.${value}`),
  value,
}))
</script>
