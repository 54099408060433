<template>
  <div v-if="blockModule" class="builder-block-preview-wrapper">
    <PreviewButtonSet v-if="showEditGroupButtons" editor-side="left">
      <PreviewIconButton
        v-if="blockModule?.editor"
        :icon="PhPencilSimple"
        @click="$emit('edit')"
      />

      <OwnRule
        v-if="blockModule?.editor && blockModule.canDelete"
        class="builder-block-preview-wrapper__rule"
        type="dashed"
      />

      <PreviewIconButton
        v-if="blockModule.canDelete"
        :icon="PhTrash"
        @click="$emit('delete')"
      />
    </PreviewButtonSet>

    <slot></slot>

    <PreviewButtonSet v-if="showReorderButtons" editor-side="right">
      <PreviewIconButton
        :icon="PhArrowCircleUp"
        :disabled="!blockModule.canReorderUp"
        @click="$emit('reorder', -1)"
      />

      <OwnRule class="builder-block-preview-wrapper__rule" type="dashed" />

      <PreviewIconButton
        :icon="PhArrowCircleDown"
        :disabled="!blockModule.canReorderDown"
        @click="$emit('reorder', 1)"
      />
    </PreviewButtonSet>
  </div>
</template>

<script>
import { isDefined } from '@vueuse/core'
import {
  PhArrowCircleUp,
  PhArrowCircleDown,
  PhPencilSimple,
  PhTrash,
} from 'phosphor-vue/dist/phosphor-vue.esm'

import globalStore from '@/store'

import { OwnRule } from '@/ui'

import isSidebarOpenMixin from '../../mixins/isSidebarOpen'

import PreviewButtonSet from './actions/PreviewButtonSet.vue'
import PreviewIconButton from './actions/PreviewIconButton.vue'

export default {
  name: 'BuilderBlockPreviewWrapper',
  components: {
    OwnRule,
    PreviewButtonSet,
    PreviewIconButton,
  },
  mixins: [isSidebarOpenMixin],
  props: {
    blockModule: { type: Object, default: undefined },
    editMinHeight: { type: [String, Number], default: 100 },
  },
  data() {
    return {
      PhArrowCircleDown,
      PhArrowCircleUp,
      PhPencilSimple,
      PhTrash,
    }
  },
  inject: ['storeModule', 'readonly'],
  computed: {
    currentBuilderMode() {
      const { storeModule } = this

      return globalStore.getters[`${storeModule}/editMode`]
    },
    minHeight() {
      const { showEditButtons, editMinHeight } = this

      return showEditButtons && !!editMinHeight ? `${editMinHeight}px` : null
    },
    showEditButtons() {
      const { currentBuilderMode, readonly, isSidebarOpen } = this

      if (readonly) return false

      if (isSidebarOpen) return false

      return currentBuilderMode === 'build'
    },
    showEditGroupButtons() {
      const { showEditButtons, blockModule } = this

      if (!showEditButtons) return false

      return blockModule.canDelete || isDefined(blockModule.editor)
    },
    showReorderButtons() {
      const { showEditButtons, blockModule } = this

      if (!showEditButtons) return false

      return blockModule.canReorderUp || blockModule.canReorderDown
    },
  },
}
</script>

<style lang="scss" scoped>
.builder-block-preview-wrapper {
  position: relative;
  min-height: v-bind(minHeight);

  &__rule {
    margin: 0 12px;
    width: calc(100% - 24px);
  }
}
</style>
