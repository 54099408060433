import { type PreviewStaticModule } from '@/lib/builder'

import FormFooterEditor from './editor/FormFooterEditor.vue'

import FormFooterPreview from './preview/FormFooterPreview.vue'

export const FormFooterStaticModule: PreviewStaticModule = {
  editor: FormFooterEditor,
  preview: FormFooterPreview,
}
