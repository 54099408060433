<i18n locale="en">
{
  "heading": {
    "label": "Title",
    "placeholder": "Aa"
  },
  "subheading": {
    "label": "Description",
    "placeholder": "Aa"
  }
}
</i18n>

<template>
  <OwnStack spacing="8">
    <OwnInputContainer
      :errors="heading.status.errors"
      :label="$t('heading.label')"
      :max-chars="MAX_HEADING_LENGTH"
      :value="heading.value"
    >
      <OwnInput
        v-model="heading.value"
        :placeholder="$t('heading.placeholder')"
      />
    </OwnInputContainer>
    <OwnInputContainer
      :errors="subheading.status.errors"
      :label="$t('subheading.label')"
      :max-chars="MAX_SUBHEADING_LENGTH"
      :value="subheading.value"
    >
      <OwnTextarea
        v-model="subheading.value"
        :placeholder="$t('subheading.placeholder')"
      />
    </OwnInputContainer>
  </OwnStack>
</template>

<script>
import { mapGetters } from 'vuex'

import { FormBuilder, Validators } from '@/forms'
import { OwnStack, OwnInput, OwnInputContainer, OwnTextarea } from '@/ui'

import { GiftCardsCommands } from '../commands/GiftCardsCommands'

const MAX_HEADING_LENGTH = 50
const MAX_SUBHEADING_LENGTH = 200

export default {
  name: 'GiftCardsEditor',
  components: {
    OwnInput,
    OwnInputContainer,
    OwnStack,
    OwnTextarea,
  },
  mixins: [
    FormBuilder({
      heading: {
        debounce: 250,
        validateOnInit: true,
        validators: [
          Validators.required,
          Validators.maxLength(MAX_HEADING_LENGTH),
        ],
      },
      subheading: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.maxLength(MAX_SUBHEADING_LENGTH)],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  data() {
    return {
      MAX_HEADING_LENGTH,
      MAX_SUBHEADING_LENGTH,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    currentBlockParams() {
      const { id } = this

      return this.blockById(id)?.params
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues({
      heading: currentBlockParams.heading,
      subheading: currentBlockParams.subheading,
    })
  },
  methods: {
    async applyGiftCardsUpdate(newValue) {
      const { id } = this

      await GiftCardsCommands.updateBlock({
        targetId: id,
        update: {
          params: {
            ...newValue,
          },
        },
      })
    },
    async onFormControlUpdate(controlName, value) {
      await this.applyGiftCardsUpdate({ [controlName]: value })
    },
  },
}
</script>
