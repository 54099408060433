<i18n locale="en">
{
  "action": "Go back and update"
}
</i18n>

<template>
  <OwnStack row justify="end" align="center">
    <OwnButton :text="$t(`action`)" primary @click="goToBuilder" />
  </OwnStack>
</template>

<script>
import { OwnButton, OwnStack } from '@/ui'

import { emailBuilderRouteManager } from '../../../builders/email'
import { textBuilderRouteManager } from '../../../builders/text'

export default {
  name: 'InvalidConfigurationFooter',
  components: { OwnButton, OwnStack },
  props: {
    campaign: { type: Object, default: null },
  },
  methods: {
    async goToBuilder() {
      const routeManager =
        this.campaign.transport === 'sms'
          ? textBuilderRouteManager
          : emailBuilderRouteManager

      const resourcePageRoute = await routeManager.editResourcePage(
        this.campaign.id
      )

      await this.$router.push(resourcePageRoute)

      this.$emit('close') // Close the dialog if already on the builder page
    },
  },
}
</script>
