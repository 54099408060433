
{
  "placeholders": {
    "all": "All Locations",
    "choose": "Choose a location",
    "filter": "Filter locations..."
  },
  "options": {
    "all": "All Locations"
  }
}
