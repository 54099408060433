import Vue from 'vue'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'

export const setCurrentCommandMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  currentCommand: string
): void => {
  Vue.set(state, '_builder_currentCommand', currentCommand)
}
