
{
  "labels": {
    "label": "Title",
    "options": "Options",
    "choices": "Choices",
    "required": "Required",
    "add": "Add option"
  }
}
