
{
  "title": "Generate An Email",
  "actions": {
    "okay": "Okay"
  },
  "errors": {
    "generate": "You need to generate an email to continue. On the left, enter your occasion and optionally select a menu item, coupon, and tone of voice and click “Generate”."
  }
}
