<i18n locale="en">
{
  "links": {
    "terms": "Terms of Use",
    "privacy": "Privacy Policy",
    "unsubscribe": "Unsubscribe",
    "feedback": "Share Feedback",
    "owner": "Made with Owner"
  }
}
</i18n>

<template>
  <PreviewSheet radius="0">
    <div>
      <PreviewSheet radius="0" class="pt-17 gap-10" background="primary">
        <span><!-- This is a gap between footer and content --></span>
      </PreviewSheet>
      <div v-if="hasLoyalty" class="px-2">
        <EmailLoyalty />
      </div>
      <div class="flex-col pa-8 gap-4 w-full justify-center align-center">
        <div class="flex-row gap-2">
          <img :src="instagramIcon" height="16" />
          <img :src="facebookIcon" height="16" />
        </div>
        <div class="flex-row gap-4">
          <PreviewTypography
            :text="$t('links.terms')"
            color="secondary"
            variant="paragraphSmall"
          />
          <PreviewTypography
            :text="$t('links.privacy')"
            color="secondary"
            variant="paragraphSmall"
          />
          <PreviewTypography
            :text="$t('links.unsubscribe')"
            color="secondary"
            variant="paragraphSmall"
          />
          <PreviewTypography
            :text="$t('links.feedback')"
            color="secondary"
            variant="paragraphSmall"
          />
        </div>
        <div>
          <PreviewTypography
            :text="brand.name + ' 2024 all rights reserved'"
            color="secondary"
            variant="paragraphSmall"
          />
        </div>
        <div class="flex-row gap-3">
          <img :src="appleBadge" height="35" />
          <img :src="googleBadge" height="35" />
        </div>
        <PreviewSheet
          background="primary"
          border="divider"
          class="flex-row gap-2 py-1 pl-3 pr-1"
        >
          <PreviewTypography
            :text="$t('links.owner')"
            color="secondary"
            variant="paragraphSmall"
          />
          <img :src="ownerIcon" height="20" />
        </PreviewSheet>
      </div>
    </div>
  </PreviewSheet>
</template>

<script>
import { mapGetters } from 'vuex'

import { PreviewTypography, PreviewSheet, useMergedTheme } from '@/lib/builder'

import EmailLoyalty from './EmailLoyalty.vue'

export default {
  name: 'EmailFooter',
  components: {
    EmailLoyalty,
    PreviewSheet,
    PreviewTypography,
  },
  mixins: [useMergedTheme],
  computed: {
    ...mapGetters({
      brand: 'brand/brand',
      campaign: 'emailBuilder/currentResource',
      getLocation: 'brand/locationById',
      locations: 'brand/locations',
    }),
    appleBadge() {
      const { mergedTheme } = this

      if (mergedTheme.palette == 'dark') {
        return '/static/app-stores/apple-dark.svg'
      } else {
        return '/static/app-stores/apple.svg'
      }
    },
    facebookIcon() {
      const { mergedTheme } = this

      if (mergedTheme.palette == 'dark') {
        return '/static/social-icons/facebook-dark.svg'
      } else {
        return '/static/social-icons/facebook.svg'
      }
    },
    googleBadge() {
      const { mergedTheme } = this

      if (mergedTheme.palette == 'dark') {
        return '/static/app-stores/google-dark.svg'
      } else {
        return '/static/app-stores/google.svg'
      }
    },
    hasLoyalty() {
      const { campaign, getLocation, locations } = this
      if (!campaign) return false
      const { locationId } = campaign

      return locationId
        ? getLocation(locationId).features.loyalty
        : locations.some(({ features: { loyalty } }) => !!loyalty)
    },
    instagramIcon() {
      const { mergedTheme } = this

      if (mergedTheme.palette == 'dark') {
        return '/static/social-icons/instagram-dark.svg'
      } else {
        return '/static/social-icons/instagram.svg'
      }
    },
    ownerIcon() {
      const { mergedTheme } = this

      if (mergedTheme.palette == 'dark') {
        return '/branding/owner-profile-round-dark.svg'
      } else {
        return '/branding/owner-profile-round.svg'
      }
    },
  },
}
</script>
