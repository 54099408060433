import { mapGetters } from 'vuex'

/**
 * Reloads the page with an updated locale URL param whenever the locale is switched
 * @mixin
 */
export default {
  name: 'LocaleHandler',
  computed: {
    ...mapGetters({
      $_lh_locale: 'app/locale',
    }),
  },
  watch: {
    $_lh_locale() {
      window.location.reload()
    },
  },
  created() {
    // this.$i18n.locale = this.$_lh_locale
  },
}
