<template>
  <OwnStack spacing="2" row shrink>
    <div class="nav-button">
      <PreviewSheet class="nav-button__nav-button-sheet" background="secondary">
        <OwnIcon :icon="PhCaretLeft" :style="caretStyle" />
      </PreviewSheet>
    </div>
    <div class="nav-button">
      <PreviewSheet class="nav-button__nav-button-sheet" background="secondary">
        <OwnIcon :icon="PhCaretRight" :style="caretStyle" />
      </PreviewSheet>
    </div>
  </OwnStack>
</template>
<script lang="ts" setup>
import { PhCaretRight, PhCaretLeft } from 'phosphor-vue/dist/phosphor-vue.esm'

import { computed } from 'vue'

import { PreviewSheet, useMergedThemeComp } from '@/lib/builder'

import { OwnStack, OwnIcon } from '@/ui'

const theme = useMergedThemeComp()

const caretStyle = computed(() => {
  return {
    color: theme.value?.colors.text.primary,
  }
})
</script>
<style lang="scss" scoped>
.nav-button {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;

  &__nav-button-sheet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
