import { brandsApi } from '@/api'

import {
  type WebsiteResource,
  type WebsiteSection,
} from '@/features/website-builder/builder/WebsiteBuilder.types'
import { type BuilderCoreState } from '@/lib/builder'

export const publishStyleChanges = async (
  update: BuilderCoreState<WebsiteResource, WebsiteSection>
): Promise<BuilderCoreState<WebsiteResource, WebsiteSection>> => {
  const promises = []

  if (update.resource?.brand) {
    promises.push(
      brandsApi.updateBrandAssets(update.resource.brand.id, {
        ...update.resource.brand.assets,
        logo: update.resource.brand.assets.logo,
      })
    )
  }

  if (update.resource?.theme) {
    promises.push(
      brandsApi.updateBrightnessPalette(
        update.resource.theme.brandId,
        update.resource.theme.palette
      )
    )

    promises.push(
      brandsApi.updateThemeOverrides(
        update.resource.theme.brandId,
        update.resource.theme?.overrides ?? {}
      )
    )
  }

  await Promise.all(promises)

  return update
}
