import { PhForkKnife } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type CampaignResource,
  type ItemSection,
  type ItemSectionCreate,
  type ItemSectionUpdate,
} from '../../types'
import { emailBlockMethods } from '../common/methods'

import ItemEditor from './editor/ItemEditor.vue'
import { createItemData } from './methods/createItemData'
import ItemPreview from './preview/ItemPreview.vue'

export const ItemBlockModule: BuilderBlockModule<
  ItemSection,
  ItemSectionCreate,
  ItemSectionUpdate,
  CampaignResource
> = {
  canAdd: ({ resource: { locationId } }) => !!locationId,
  createData: createItemData,
  editor: ItemEditor,
  meta: [
    {
      icon: PhForkKnife,
      key: 'item',
      label: i18n.t('email-builder.blocks.item'),
    },
  ],
  methods: emailBlockMethods,
  preview: ItemPreview,
}
