<i18n locale="en">
{
  "content": "This will use {proposedCampaignUsage} of your monthly {monthlyLimit} text message budget. You’ll have {remainingAfterProposedUsage} remaining for this month.",
  "error": "Failed to check usage limits",
  "would-exceed-limits": "This would exceed your monthly {monthlyLimit} text message budget. You have used {messagesSentUsage} this month. Try scheduling for another month.",
  "first-monthly-campaign-exceeds-limit": "This SMS campaign will exceed your {monthlyLimit} monthly budget. You will not be able to send any additional one-time SMS campaigns this month."
}
</i18n>

<template>
  <div v-if="status === 'loaded'">
    <OwnType
      v-if="limitCheck?.firstMonthlySmsCampaign"
      :text="
        limitCheck?.wouldExceedLimits
          ? $t('first-monthly-campaign-exceeds-limit', {
              monthlyLimit,
            })
          : $t('content', {
              proposedCampaignUsage,
              monthlyLimit,
              remainingAfterProposedUsage,
            })
      "
    />

    <OwnType
      v-else
      :text="
        limitCheck?.wouldExceedLimits
          ? $t('would-exceed-limits', {
              monthlyLimit,
              messagesSentUsage,
            })
          : $t('content', {
              proposedCampaignUsage,
              monthlyLimit,
              remainingAfterProposedUsage,
            })
      "
    />
  </div>
  <OwnSkeletonLoader v-else-if="status === 'loading'" :height="42" />
  <OwnType v-else :text="$t('error')" />
</template>

<script lang="ts" setup>
import { Olympus } from '@owner/olympus-client'
import { computed, onMounted, ref, watch } from 'vue'

import { ConfiguredClient } from '@/api'
import { CurrencyFormatter } from '@/format'
import { logError } from '@/logger'
import { useMappedGetter } from '@/store'
import { OwnSkeletonLoader, OwnType } from '@/ui'

import { CampaignResource } from '../types'

const props = defineProps<{ executeAt: Date }>()
const emit = defineEmits<{
  (event: 'update', wouldExceedLimits: boolean): void
}>()

const status = ref<'loading' | 'error' | 'loaded'>('loading')
const limitCheck = ref<Olympus.Sms.LimitCheck | undefined>(undefined)

const proposedCampaignUsage = computed<string>(() =>
  limitCheck.value ? CurrencyFormatter(limitCheck.value.consumedByCampaign) : ''
)
const monthlyLimit = computed<string>(() =>
  limitCheck.value
    ? CurrencyFormatter(limitCheck.value.monthlySmsCostLimit)
    : ''
)
const remainingAfterProposedUsage = computed<string>(() =>
  limitCheck.value
    ? CurrencyFormatter(limitCheck.value.remainingAfterCampaign)
    : ''
)
const messagesSentUsage = computed<string>(() =>
  limitCheck.value
    ? CurrencyFormatter(limitCheck.value.smsMessagesSentCost)
    : ''
)

const campaign = useMappedGetter<CampaignResource | undefined>(
  'textBuilder/currentResource'
)
const checkCampaignUsageAgainstLimits = async () => {
  if (!campaign.value?.id) {
    return undefined
  }

  // Prevent users from bypassing the limit check by pretending that the limit would be exceeded until loading is complete
  emit('update', true)
  status.value = 'loading'
  try {
    const result =
      await ConfiguredClient.dashboard.v1.campaigns.campaign.checkLimits({
        params: { campaignId: campaign.value.id },
        query: {
          month: (props.executeAt ?? new Date()).toISOString(),
        },
      })
    limitCheck.value = result

    if (result.firstMonthlySmsCampaign) {
      emit('update', false)
    } else {
      emit('update', result.wouldExceedLimits)
    }
    status.value = 'loaded'
  } catch (err) {
    logError('Failed to check campaign usage against limits')
    status.value = 'error'
  }
}

onMounted(checkCampaignUsageAgainstLimits)
watch(() => props.executeAt, checkCampaignUsageAgainstLimits)
</script>
