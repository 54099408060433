
{
  "actions": {
    "add-action": "Add Action"
  },
  "options": {
    "locations": "Locations",
    "profile": "Profile",
    "loyalty": "Loyalty",
    "orders": "Orders"
  }
}
