<i18n locale="en">
{
  "actions": {
    "edit": "Edit",
    "delete": "Delete"
  }
}
</i18n>

<template>
  <OwnActionMenu
    class="flex-shrink-0"
    :actions="actions"
    @action="$emit('action', { action: $event, item })"
  >
    <template #trigger>
      <PhDotsThreeCircle class="text-color-secondary" size="24" />
    </template>
  </OwnActionMenu>
</template>

<script>
import {
  PhDotsThreeCircle,
  PhPencil,
  PhTrash,
} from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnActionMenu } from '@/ui'

export default {
  name: 'NavItemActionMenu',
  components: { OwnActionMenu, PhDotsThreeCircle },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    actions() {
      const { item } = this

      const actions = []

      if (item.type !== 'partner') {
        actions.push({
          icon: PhPencil,
          value: 'edit',
        })
      }

      actions.push({
        color: 'danger',
        icon: PhTrash,
        value: 'delete',
      })

      return actions.map((action) => ({
        ...action,
        label: this.$t(`actions.${action.value}`),
      }))
    },
  },
}
</script>
