export const growthPagesEn = {
  'growth-report': {
    title: 'View your Report',
  },
  'growth-scan': {
    title: 'Scan your Website',
  },
} as const

export const growthTranslationsEn = {} as const
