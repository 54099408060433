import { ConfiguredClient } from '@/api'

import { type BuilderFetchArgs, type BuilderFetchResult } from '@/lib/builder'

import { type Form, type FormSection } from '../../types/FormBuilder.types'

export const fetchFormData = async ({
  brandId,
  resourceId,
}: BuilderFetchArgs): Promise<BuilderFetchResult<Form, FormSection>> => {
  const { form, sections } =
    await ConfiguredClient.dashboard.v1.brands.brand.forms.getFormData({
      params: { brandId, formId: resourceId },
    })

  return {
    blocks: sections,
    resource: form,
  }
}
