import { PhForkKnife } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type WebsiteResource,
  type TopPicksSectionCreate,
  type TopPicksSection,
  type TopPicksSectionUpdate,
} from '../../WebsiteBuilder.types'

import { websiteBlockMethods } from '../common/methods'

import { createTopPicksData } from './methods/createTopPicksData'
import PopularItemsPreview from './preview/PopularItemsPreview.vue'

export const TopPicksBlockModule: BuilderBlockModule<
  TopPicksSection,
  TopPicksSectionCreate,
  TopPicksSectionUpdate,
  WebsiteResource
> = {
  createData: createTopPicksData,
  meta: [
    {
      icon: PhForkKnife,
      key: 'top-picks',
      label: i18n.t('website-builder.blocks.top-picks'),
      sort: 2,
    },
  ],
  methods: websiteBlockMethods,
  preview: PopularItemsPreview,
}
