import Vue from 'vue'

import defaultState from './state'

export default {
  ADD_CONFIGURATIONS(state, { configurations }) {
    Vue.set(state, 'configurations', {
      ...state.configurations,
      ...configurations,
    })
  },
  SANITIZE_CONFIGURATIONS(state) {
    Vue.set(state, 'configurations', defaultState.configurations)
  },
  SET_DRAFT_CONFIGURATION(state, configuration) {
    Vue.set(state, 'draftConfiguration', configuration)
  },
  SET_SAVING(state, saveInProgress) {
    Vue.set(state, 'saveInProgress', saveInProgress)
  },
  UPDATE_CONFIGURATION(state, { configurationUpdate, locationId }) {
    const thisLocationConfiguration = state.configurations[locationId]

    if (thisLocationConfiguration) {
      const newConfig = {
        ...thisLocationConfiguration,
        ...configurationUpdate,
      }

      Vue.set(state.configurations, locationId, newConfig)
    }
  },
}
