
{
  "heading": {
    "label": "Title",
    "placeholder": "Aa"
  },
  "pdf": {
    "empty-state": {
      "start": "Drag & drop or",
      "emphasis": "choose a PDF",
      "end": "to upload"
    },
    "label": "PDF",
    "replace": "Replace PDF",
    "select": "Select PDF"
  },
  "subheading": {
    "label": "Description",
    "placeholder": "Aa"
  },
  "upload-error": "Failed to upload PDF"
}
