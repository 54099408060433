<i18n locale="en">
{
  "labels": {
    "headline": "Heading",
    "subheading": "Paragraph"
  }
}
</i18n>

<template>
  <OwnStack spacing="8">
    <OwnInputContainer
      :label="$t(`labels.${type}`)"
      :errors="text.status.errors"
    >
      <component
        :is="editorComponent"
        v-model="text.value"
        :placeholder="$t(`labels.${type}`)"
      />
    </OwnInputContainer>
  </OwnStack>
</template>

<script>
import { mapGetters } from 'vuex'

import { FormBuilder, Validators } from '@/forms'
import { OwnStack, OwnInputContainer, OwnInput, OwnTextarea } from '@/ui'

import { HeadlineCommands } from '../commands/HeadlineCommands'

export default {
  name: 'HeadlineEditor',
  components: {
    OwnInputContainer,
    OwnStack,
  },
  mixins: [
    FormBuilder({
      text: {
        debounce: 500,
        validators: [Validators.required],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  computed: {
    ...mapGetters({
      blockById: 'formBuilder/blockById',
    }),
    currentBlock() {
      const { id } = this

      return this.blockById(id)
    },
    currentBlockParams() {
      const { currentBlock } = this

      return currentBlock?.params
    },
    editorComponent() {
      const { type } = this

      return type === 'headline' ? OwnInput : OwnTextarea
    },
    type() {
      const { currentBlock } = this

      return currentBlock?.type
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues({ text: currentBlockParams.text })
  },
  methods: {
    async onFormControlUpdate(controlName, update) {
      const { id, isFormValid } = this

      if (isFormValid) {
        await HeadlineCommands.updateBlock({
          targetId: id,
          update: { params: { [controlName]: update } },
        })
      }
    },
  },
}
</script>
