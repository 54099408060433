
{
  "create": "Create",
  "error": "Must provide a name",
  "name": {
    "description": "A brief description or summary that highlights the content or purpose.",
    "label": "Text Name",
    "placeholder": "My Text"
  },
  "title": "Create Text"
}
