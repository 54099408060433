import { ConfiguredClient } from '@/api'

import { type BuilderFetchArgs, type BuilderFetchResult } from '@/lib/builder'

import { labelNavItems } from '../../utils/navItems'

import {
  type WebsiteResource,
  type WebsiteSection,
} from '../WebsiteBuilder.types'

export const styleFetchDataHandler = async ({
  brandId,
}: BuilderFetchArgs): Promise<
  BuilderFetchResult<WebsiteResource, WebsiteSection>
> => {
  const { funnel, page, sections, theme, brand, nav, keyword } =
    await ConfiguredClient.dashboard.v1.brands.brand.website.pages.getPageDataByPath(
      {
        params: {
          brandId,
        },
        query: {
          page: 'index',
          type: 'reserved',
        },
      }
    )

  return {
    blocks: sections,
    resource: {
      ...page,
      brand,
      funnel,
      keyword,
      nav: labelNavItems(nav),
      theme,
    },
  }
}
