
{
  "heading": {
    "title": "Subject"
  },
  "footer": {
    "titles": {
      "scheduled": "Scheduled by You",
      "sent": "Sent by You"
    },
    "subtitle": "This campaign was designed by you."
  }
}
