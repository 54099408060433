import { PhFileText } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type WebsiteResource,
  type PdfSectionCreate,
  type PdfSection,
  type PdfSectionUpdate,
} from '../../WebsiteBuilder.types'

import { websiteBlockMethods } from '../common/methods'

import PdfEditor from './editor/PdfEditor.vue'
import { createPdfData } from './methods/createPdfData'
import PdfPreview from './preview/PdfPreview.vue'

export const PdfBlockModule: BuilderBlockModule<
  PdfSection,
  PdfSectionCreate,
  PdfSectionUpdate,
  WebsiteResource
> = {
  createData: createPdfData,
  editor: PdfEditor,
  meta: [
    {
      icon: PhFileText,
      key: 'pdf',
      label: i18n.t('website-builder.blocks.pdf'),
      sort: 6,
    },
  ],
  methods: websiteBlockMethods,
  preview: PdfPreview,
}
