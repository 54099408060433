<template>
  <OwnStack class="editor-layout h-full">
    <OwnScrollContainer class="h-full">
      <div class="editor-layout__content">
        <slot></slot>
      </div>
    </OwnScrollContainer>

    <OwnStack v-if="$slots['actions']" class="editor-layout__editor-actions">
      <slot name="actions"></slot>
    </OwnStack>
  </OwnStack>
</template>

<script>
import { OwnScrollContainer, OwnStack } from '@/ui'

export default {
  name: 'EditorLayout',
  components: { OwnScrollContainer, OwnStack },
}
</script>

<style lang="scss" scoped>
.editor-layout {
  &__content {
    padding: 40px 16px;

    @include respond-to('md-and-up') {
      padding: 40px;
    }
  }

  &__editor-actions {
    padding: 16px;
    border-top: 1px solid $background-divider;
  }
}
</style>
