import { type Route } from 'vue-router'

import { websiteBuilderModuleRouter } from '../builder/router/websiteBuilderModuleRouter'

export const websiteBuilderRouter = [
  ...websiteBuilderModuleRouter,
  {
    component: async () => import('../views/WebsiteBuilderPagesView.vue'),
    meta: {
      title: 'website.pages',
    },
    name: 'brand.website.pages.home',
    path: 'pages',
  },
  {
    component: async () =>
      import('../views/WebsiteBuilderAnnouncementsView.vue'),
    meta: {
      title: 'website.announcements',
    },
    name: 'brand.website.announcements',
    path: 'announcements',
  },
  {
    children: [
      {
        component: async () =>
          import('../views/links/FunnelLinkDetailView.vue'),
        name: 'brand.website.links.detail',
        path: ':slug',
        props: (route: Route) => ({
          slug: route.params.slug,
        }),
      },
    ],
    component: async () => import('../views/links/FunnelLinkMainView.vue'),
    meta: {
      title: 'website.settings',
    },
    name: 'brand.website.links',
    path: 'links',
  },
]
