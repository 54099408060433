
{
  "label": "Link Order",
  "description": "Links that don’t fit will fall into a “More” menu.",
  "types": {
    "page": "Page",
    "action": "Action",
    "partner": "Internal Link",
    "external": "External Link"
  },
  "actions": {
    "add-page": "Page",
    "add-link": "Link",
    "add-action": "Action"
  }
}
