import { actions } from './actions'
import { getters } from './getters'
import state from './model'
import { mutations } from './mutations'

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
