
{
  "titles" : {
    "add": "Add Action",
    "update": "Update Action"
  },
  "actions": {
    "update": "Update",
    "add": "Add"
  },
  "options": {
    "locations": "Locations",
    "profile": "Profile",
    "loyalty": "Loyalty",
    "orders": "Orders"
  }
}
