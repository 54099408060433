import { type CreateBlockData } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import type {
  TextFieldSectionCreate,
  TextFieldSection,
} from '../../../../types/FormBuilder.types'

export const createTextInputData = ({
  type,
  brandId,
}: CreateBlockData<TextFieldSection>): TextFieldSectionCreate => ({
  brandId,
  params: {
    label: i18n.t('form-builder.blocks.text-field'),
    placeholder: null,
    required: false,
    variant: null,
  },
  type,
})
