import { type RouteConfig } from 'vue-router'

export const billingRouter: RouteConfig[] = [
  {
    children: [
      {
        component: async () => import('../views/BillingHomeView.vue'),
        meta: {
          title: 'billing.invoices',
        },
        name: 'brand.billing.invoices',
        path: 'invoices',
      },
      {
        component: async () => import('../views/BillingDetailView.vue'),
        meta: {
          title: 'billing.invoices',
        },
        name: 'brand.billing.invoices.detail',
        path: 'invoices/:invoiceId',
      },
      {
        component: async () => import('../views/BillingPaymentMethodView.vue'),
        meta: {
          title: 'billing.subscriptions',
        },
        name: 'brand.billing.methods',
        path: 'methods',
      },
    ],
    component: {
      name: 'BillingRouterView',
      // eslint-disable-next-line xss/no-mixed-html
      template: '<router-view></router-view>',
    },
    name: 'brand.billing',
    path: 'billing',
    redirect: { name: `brand.billing.invoices` },
  },
]
