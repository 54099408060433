<template>
  <PreviewSheet
    class="button"
    :class="[variant === 'secondary' && 'button--secondary']"
    :style="style"
    :background="background"
    radius="control"
  >
    <PreviewTypography
      :text="title"
      :color="textColor"
      variant="buttonRegular"
    />
    <PhCaretRight :style="caretStyle" weight="bold" />
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { PhCaretRight } from 'phosphor-vue/dist/phosphor-vue.esm'
import { computed } from 'vue'

import { useMergedTheme } from './mixins/useMergedThemeComp'
import PreviewSheet from './PreviewSheet.vue'
import PreviewTypography from './PreviewTypography.vue'

const props = withDefaults(
  defineProps<{
    caret?: boolean
    title: string
    subtitle?: string
    size?: 'small' | 'large'
    variant?: 'primary' | 'secondary' | 'tertiary'
  }>(),
  {
    caret: false,
    size: 'large',
    subtitle: undefined,
    variant: 'primary',
  }
)

const theme = useMergedTheme()

const background = computed(() => {
  return props.variant === 'primary' ? 'primary' : 'transparent'
})

const primaryColor = computed(() => {
  if (theme.value) {
    return theme.value.colors.brand.primary
  }

  return undefined
})

const textColor = computed(() => {
  if (props.variant === 'primary') return 'white'
  if (props.variant === 'secondary') return 'primary'

  return undefined
})

const caretStyle = computed(() => {
  return {
    color:
      props.variant === 'primary' ? 'white' : theme.value?.colors.text.primary,
    opacity: 0.5,
  }
})

const style = computed(() => {
  if (props.variant === 'primary') {
    return {
      background: primaryColor.value,
    }
  }

  if (props.variant === 'secondary') {
    return {
      borderColor: theme.value?.colors.background.divider,
    }
  }

  return undefined
})
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  min-height: 48px;
  padding: 12px 16px;
  white-space: nowrap;
  flex-shrink: 0;

  &--secondary {
    border-width: 1px;
    border-style: solid;
  }
}
</style>
