<i18n locale="en">
{
  "add-your-resume": "Add your resume / cover letter (PDF only)",
  "apply": "Apply",
  "fields": {
    "application": "Application",
    "attachment": "Attachment",
    "email": "Email",
    "full-name": "Full name",
    "jobs": "Positions",
    "location": "Location",
    "message": "Message",
    "phone": "Phone number"
  },
  "placeholders": {
    "message": "Anything to share as we consider your application?",
    "phone": "Phone number"
  },
  "salary": {
    "range": "Salary range is {min} to {max}",
    "starting-at": "Salaries starting at {salary}",
    "up-to": "Salaries up to {salary}"
  }
}
</i18n>

<template>
  <OwnStack class="careers-form" spacing="4">
    <PreviewFormGroup :title="$t('fields.location')">
      <div class="careers-form__option-columns">
        <OwnStack
          v-for="({ id, name, address }, index) of locations"
          :key="id"
          spacing="3"
          row
          align="center"
        >
          <PreviewRadio
            :checked="activeLocationId ? activeLocationId === id : index === 0"
          />
          <OwnStack spacing="1">
            <PreviewTypography
              variant="paragraphRegular"
              color="primary"
              :text="name"
            />
            <PreviewTypography
              v-if="address"
              variant="paragraphSmall"
              color="secondary"
              :text="address.street"
            />
          </OwnStack>
        </OwnStack>
      </div>
    </PreviewFormGroup>
    <PreviewFormGroup v-if="jobs.length > 0" :title="$t('fields.jobs')">
      <div class="careers-form__option-columns">
        <OwnStack
          v-for="job in jobs"
          :key="job.id"
          row
          spacing="3"
          align="center"
        >
          <PreviewCheckbox />
          <OwnStack spacing="1">
            <PreviewTypography
              variant="paragraphRegular"
              color="primary"
              :text="job.title"
            />
            <PreviewTypography
              v-if="job.salary.max || job.salary.min"
              variant="paragraphSmall"
              color="secondary"
              :text="salaryRange(job.salary)"
            />
          </OwnStack>
        </OwnStack>
      </div>
    </PreviewFormGroup>
    <PreviewFormGroup :title="$t('fields.application')">
      <OwnStack spacing="6">
        <OwnStack spacing="2">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="$t('fields.full-name')"
          />
          <PreviewTextField :placeholder="$t('fields.full-name')" />
        </OwnStack>
        <OwnStack spacing="2" class="w-full">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="$t('fields.email')"
          />
          <PreviewTextField :placeholder="$t('fields.email')" />
        </OwnStack>
        <OwnStack spacing="2" class="w-full">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="$t('fields.phone')"
          />
          <PreviewTextField :placeholder="$t('placeholders.phone')" />
        </OwnStack>
        <OwnStack spacing="2" class="w-full">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="$t('fields.message')"
          />
          <PreviewTextarea
            :placeholder="$t('placeholders.message')"
            :rows="5"
          />
        </OwnStack>
        <OwnStack spacing="2" class="w-full">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="$t('fields.attachment')"
          />
          <OwnStack
            class="careers-form__upload"
            spacing="1"
            justify="between"
            row
            :style="uploadStyle"
          >
            <PreviewTypography
              variant="paragraphRegular"
              :text="$t('add-your-resume')"
            />
            <PhPaperclip weight="bold" />
          </OwnStack>
        </OwnStack>
      </OwnStack>
    </PreviewFormGroup>
    <PreviewButton class="careers-form__submit-button" :title="$t('apply')" />
  </OwnStack>
</template>

<script>
import { keyBy } from 'lodash'
import { PhPaperclip } from 'phosphor-vue/dist/phosphor-vue.esm'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { CurrencyFormatter } from '@/format'
import {
  builderConsumer,
  PreviewButton,
  PreviewCheckbox,
  PreviewFormGroup,
  PreviewRadio,
  PreviewTextarea,
  PreviewTextField,
  PreviewTypography,
  useMergedTheme,
} from '@/lib/builder'
import { logError } from '@/logger'
import { OwnStack } from '@/ui'

export default {
  name: 'CareersForm',
  components: {
    OwnStack,
    PhPaperclip,
    PreviewButton,
    PreviewCheckbox,
    PreviewFormGroup,
    PreviewRadio,
    PreviewTextField,
    PreviewTextarea,
    PreviewTypography,
  },
  mixins: [builderConsumer('jobs', 'onMessage'), useMergedTheme],
  props: {
    /** Job IDs */
    jobIds: { type: Array, required: true },
  },
  data() {
    return {
      jobsById: {},
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      activeLocationId: 'core/location/active/id',
      locations: 'core/brand/active/locations',
      mergedTheme: 'core/brand/active/mergedTheme',
    }),
    jobs() {
      const { jobIds, jobsById } = this

      return jobIds
        .map((jobId) => jobsById[jobId])
        .filter((maybeJob) => maybeJob !== undefined)
    },
    uploadStyle() {
      const { mergedTheme } = this

      return {
        borderColor: mergedTheme.colors.background.divider,
        borderRadius: `${mergedTheme.borderRadius.control}rem`,
        color: mergedTheme.colors.text.secondary,
      }
    },
  },
  created() {
    this.fetchJobs()
  },
  methods: {
    async fetchJobs() {
      const { activeBrandId, activeLocationId, locations } = this

      // Default to the first location in the list if no location is selected
      const locationId = activeLocationId ?? locations[0]?.id

      // Don't fetch jobs without a location
      if (!locationId) {
        return
      }

      try {
        const response =
          await ConfiguredClient.dashboard.v1.brands.brand.careers.getJobs({
            query: {
              brandId: activeBrandId,
              limit: 100,
              locationId,
            },
          })

        this.jobsById = keyBy(response.results, 'id')
      } catch (err) {
        logError(err)
      }
    },
    async onMessage(message) {
      if (message.topic === 'newJobAdded' || message.topic === 'jobEdited') {
        await this.fetchJobs()
      }
    },
    salaryRange(salary) {
      if (salary.min && !salary.max) {
        return this.$t('salary.starting-at', {
          salary: CurrencyFormatter(salary.min),
        })
      } else if (salary.max && !salary.min) {
        return this.$t('salary.up-to', {
          salary: CurrencyFormatter(salary.max),
        })
      }
      return this.$t('salary.range', {
        max: CurrencyFormatter(salary.max),
        min: CurrencyFormatter(salary.min),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.careers-form {
  &__form-group {
    padding: 24px;
  }
  &__option-columns {
    display: grid;
    row-gap: 16px;
    column-gap: 24px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  &__upload {
    border-width: 1px;
    border-style: dashed;
    padding: 20px;
  }

  &__submit-button {
    width: 100%;
    justify-content: center;
  }
}
</style>
