import Vue from 'vue'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'

export const dequeueBlockCommandsMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  blockId: string
): void => {
  Vue.set(
    state,
    '_builder_commandQueue',
    state._builder_commandQueue.filter(
      (command) => command.context.targetId !== blockId
    )
  )
}
