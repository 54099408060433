<i18n locale="en">
  {
    "cta": "View Full Menu",
    "header": "Try our most popular items",
    "insufficientItems": "This section will only display if there are at least three available popular items with images.",
    "subtitle": "Treat yourself to our must-try list that has everyone talking"
  }
</i18n>
<template>
  <PreviewSheet class="popular-items-preview">
    <OwnStack justify="center" align="center" spacing="14">
      <OwnStack align="center" spacing="8">
        <OwnStack
          class="popular-items-preview__headline"
          justify="center"
          align="center"
          spacing="4"
        >
          <PreviewTypography
            :text="$t('header')"
            class="popular-items-preview__center-text"
            variant="heading2"
          />
          <PreviewTypography
            :text="$t('subtitle')"
            class="popular-items-preview__center-text"
            color="secondary"
            variant="paragraphRegular"
          />
        </OwnStack>
        <PreviewButton :title="$t('cta')" caret />
      </OwnStack>
      <OwnStack
        v-if="popularItems.length > 2"
        class="popular-items-preview__items"
        spacing="4"
        row
        justify="center"
      >
        <MenuItemCard
          v-for="item of popularItems"
          :key="item.id"
          :item="item"
        />
      </OwnStack>
      <PreviewTypography
        v-else
        :text="$t('insufficientItems')"
        variant="titleLarge"
      />
    </OwnStack>
  </PreviewSheet>
</template>
<script lang="ts" setup>
import { type Olympus } from '@owner/olympus-client'
import { onMounted, ref } from 'vue'

import { ConfiguredClient } from '@/api'
import { Location } from '@/core'
import { PreviewButton, PreviewSheet, PreviewTypography } from '@/lib/builder'
import { useMappedGetter } from '@/store'
import { OwnStack } from '@/ui'

import type { TopPicksSection } from '../../../WebsiteBuilder.types'

import MenuItemCard from './MenuItemCard.vue'

defineProps<{
  content: TopPicksSection
}>()

const popularItems = ref<Olympus.Menu.Item[]>([])
const activeBrandId = useMappedGetter<string | undefined>(
  'core/brand/active/id'
)
const locations = useMappedGetter<Location[] | undefined>(
  'core/brand/active/locations'
)

const fetchPopularMenuItems = async (brandId: string, locationId: string) => {
  const response = await ConfiguredClient.items.get({
    query: {
      available: true,
      brandId,
      limit: 20,
      locationId,
      popular: true,
    },
  })

  const items = response.results
    .filter((item) => item.image != null)
    .slice(0, 6)

  popularItems.value = items
}

onMounted(async () => {
  if (
    activeBrandId.value != null &&
    locations.value != null &&
    locations.value.length > 0 &&
    locations.value[0] != null
  ) {
    await fetchPopularMenuItems(activeBrandId.value, locations.value[0].id)
  }
})
</script>
<style lang="scss" scoped>
.popular-items-preview {
  padding: 120px 0;

  &__center-text {
    text-align: center;
  }
}
</style>
