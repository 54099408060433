import merge from 'lodash/merge'

import { type TemplateData } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import { ItemBlockModule } from '../blocks/ItemSection'
import { TextBlockModule } from '../blocks/TextSection'

import { type EmailSectionCreate, type CampaignResourceCreate } from '../types'

export const menuItemTemplate = async (
  brandId: string
): Promise<TemplateData<CampaignResourceCreate, EmailSectionCreate>> => {
  return {
    blocks: [
      merge(
        TextBlockModule.createData({
          brandId,
          type: 'heading',
        }),
        {
          data: { text: i18n.t('email-builder.defaults.item.heading') },
        }
      ),
      merge(
        TextBlockModule.createData({
          brandId,
          type: 'subheading',
        }),
        {
          data: { text: i18n.t('email-builder.defaults.item.subheading') },
        }
      ),
      ItemBlockModule.createData({
        brandId,
        type: 'item',
      }),
    ],
    resource: {
      brandId,
      locationId: null,
      name: i18n.t('email-builder.defaults.item.subject'),
      sections: [],
      subject: i18n.t('email-builder.defaults.item.subject'),
    },
  }
}
