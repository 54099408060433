
{
  "max": {
    "label": "Max",
    "placeholder": "Maximum"
  },
  "min": {
    "label": "Min",
    "placeholder": "Minimum"
  },
  "salary": "Per hour salary range"
}
