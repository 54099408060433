<template>
  <OwnStack
    :class="['copyable', border && 'copyable--border']"
    justify="between"
    align="center"
    el="button"
    spacing="2"
    row
    :shrink="iconOnly"
    @click.stop="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <OwnType
      v-if="!iconOnly"
      class="text--truncate"
      :text="label ?? value"
      :variant="variant"
      :value="label ?? value"
      color="inherit"
    />
    <template v-if="showIcon">
      <slot name="icon">
        <PhClipboardText class="flex-shrink-0" size="16" />
      </slot>
    </template>
  </OwnStack>
</template>

<script lang="ts" setup>
import { useClipboard } from '@vueuse/core'
import { PhClipboardText } from 'phosphor-vue/dist/phosphor-vue.esm'

import { computed, ref } from 'vue'

import { FontType, OwnStack, OwnType } from '@/ui'
import { useI18n, useNotify } from '@/utils'

const props = withDefaults(
  defineProps<{
    border?: boolean
    iconBehavior?: 'show' | 'hover'
    iconOnly?: boolean
    label?: string
    successMessage?: string
    variant?: FontType
    value: string
  }>(),
  {
    border: true,
    iconBehavior: 'show',
    iconOnly: false,
    label: undefined,
    successMessage: undefined,
    variant: 'monospace',
  }
)

const isHovering = ref(false)

const notify = useNotify()
const i18n = useI18n()
const { copy } = useClipboard()

const onClick = async () => {
  await copy(props.value)
  notify(props.successMessage ?? i18n.$t('copy.success'), 'success')
}

const onMouseEnter = () => {
  isHovering.value = true
}
const onMouseLeave = () => {
  isHovering.value = false
}

const showIcon = computed(() => {
  if (props.iconBehavior === 'show') return true

  return isHovering.value
})
</script>

<style lang="scss" scoped>
.copyable {
  padding: 4px;
  color: $text-color-secondary;
  transition: transitionOne(border);

  &--border {
    border: 1px dashed $background-divider;
    border-radius: 4px;

    &:hover {
      border: 1px solid $background-divider;
    }
  }

  &:active {
    color: rgba($text-color-secondary, 60%);
  }
}
</style>
