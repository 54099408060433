/**
 * Omit a value from an object
 * @param {string} key Key to omit
 * @param {object} object Object to omit from
 * @returns {object} Object omitting key
 */
export const omit = (key, object) => {
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const { [key]: omission, ...rest } = object

  return rest
}
