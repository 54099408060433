
{
  "days": {
    "future": "on {date}",
    "today": "Today",
    "tomorrow": "Tomorrow"
  },
  "actions": {
    "schedule": "Change Schedule",
    "subject": "Update Subject",
    "design": "Edit Design",
    "send": "Send Now"
  },
  "titles": {
    "scheduled": "Sending",
    "unscheduled": "Not Scheduled"
  },
  "subtitle": "{day}, {time}"
}
