import { type MutationTree } from 'vuex'

import model, { type OAuthState, type StripeState } from './model'

export const mutations: MutationTree<OAuthState> = {
  RESET_STRIPE_STATE: (state: OAuthState): void => {
    state.stripe = model.stripe
  },
  SET_STRIPE_STATE: (state: OAuthState, stripe: StripeState): void => {
    state.stripe = stripe
  },
}
