<i18n locale="en">
{
  "cta": "Order Now",
  "empty-title": "Menu Item",
  "errors": {
    "load": "Unable to load item"
  }
}
</i18n>

<template>
  <PreviewSheet background="primary" radius="0" class="px-2 py-3">
    <PreviewSheet
      border="divider"
      background="secondary"
      class="item-preview__container"
      :placeholder="!item"
    >
      <OwnSkeletonLoader v-if="isLoading" height="180" class="h-full w-full" />
      <div v-else class="flex-row h-full w-full">
        <PreviewSheet
          v-if="item"
          background="primary"
          class="flex-row h-full w-full"
        >
          <img
            v-if="item.image"
            :alt="item.displayName"
            :src="item.image"
            class="item-preview__image h-full"
          />
          <div class="flex-1 flex-col justify-between px-6 py-6">
            <div class="flex-col">
              <PreviewTypography
                :text="item.displayName ?? item.name"
                color="primary"
                variant="titleSmall"
              />
              <PreviewTypography
                v-if="item.description"
                :text="
                  item.description.length > 50
                    ? item.description.substring(0, 50 - 3) + '...'
                    : item.description
                "
                color="secondary"
                variant="paragraphSmall"
              />
            </div>
            <PreviewButton
              variant="secondary"
              size="xsmall"
              :title="$t('cta')"
            />
          </div>
        </PreviewSheet>
        <div
          v-else
          background="secondary"
          class="pa-6 flex-col align-center justify-center h-full w-full"
        >
          <PreviewIcon color="secondary" size="24" :icon="emptyIcon" />
          <PreviewTypography
            :text="$t('empty-title')"
            color="secondary"
            variant="paragraphSmall"
          />
        </div>
      </div>
    </PreviewSheet>
  </PreviewSheet>
</template>

<script>
import { isDefined } from '@vueuse/core'
import { PhForkKnife } from 'phosphor-vue/dist/phosphor-vue.esm'

import { ConfiguredClient } from '@/api'

import {
  PreviewIcon,
  PreviewSheet,
  PreviewButton,
  PreviewTypography,
} from '@/lib/builder'
import notify from '@/mixins/notify'
import { OwnSkeletonLoader } from '@/ui'

export default {
  name: 'ItemPreview',
  components: {
    OwnSkeletonLoader,
    PreviewButton,
    PreviewIcon,
    PreviewSheet,
    PreviewTypography,
  },
  mixins: [notify],
  props: {
    content: { type: Object, required: true },
  },
  data() {
    return {
      emptyIcon: PhForkKnife,
      isLoading: false,
      item: null,
    }
  },
  computed: {
    itemId() {
      const { content } = this

      return content?.data?.params?.itemId
    },
  },
  watch: {
    itemId: 'loadItem',
  },
  async mounted() {
    await this.loadItem()
  },
  methods: {
    async loadItem() {
      if (!isDefined(this.itemId)) return
      try {
        this.isLoading = true
        this.item = await ConfiguredClient.items.item.get({
          params: { menuItemId: this.itemId },
        })
      } catch (error) {
        this.$notify(this.$t('errors.load'), 'error')
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.item-preview {
  &__container {
    height: 166px;
  }

  &__image {
    height: 164px;
    width: 164px;
    border-radius: 16px;
  }
}
</style>
