
{
  "labels": {
    "size": "Size",
    "text": "Text"
  },
  "size-options": {
    "heading": "Headline",
    "subheading": "Subheading",
    "paragraph": "Body"
  },
  "placeholders": {
    "text": "Start typing your message"
  }
}
