
{
  "titles": {
    "sent": "Sent",
    "scheduled": "Scheduled"
  },
  "days": {
    "future": "on {date}",
    "today": "Today",
    "tomorrow": "Tomorrow"
  },
  "content": {
    "sent": "This can take a couple of minutes depending on how many people you’re sending it to.",
    "scheduled": "Your campaign will be sent {date} at {time}."
  },
  "actions": {
    "close": "Done",
    "view": "View Campaign"
  }
}
