import { camelToSnake } from '@owner/casing'
import { UnleashClient } from 'unleash-proxy-client'

export const FeatureFlags = new UnleashClient({
  appName: 'owner-hermes',
  clientKey: import.meta.env.VITE_UNLEASH_KEY,
  environment:
    import.meta.env.MODE === 'production' ? 'production' : 'development',
  url: 'https://us.app.unleash-hosted.com/usgg0011/api/frontend',
})

const convertUnleashToENVName = (str) =>
  `VITE_FF_${camelToSnake(str)
    .replace(/\./g, '_')
    .replace(/-/g, '_')
    .toUpperCase()}`

// Extend the isEnabled method to be able to handle FF set up in ENV variables
// This is useful so engineers can locally overwride whatever value is in Unleash
// The ENV variables format for FF is the following:
// If in Unleash we wrote a `.` in the ENV variable we will write a `_`
// If in Unleash we wrote a `-` in the ENV variable we will write a `_`
// If in Unleash we wrote in camelCase, in the ENV variable we will write SNAKE_CASE
// If in Unleash we wrote in lowerCase, in the ENV variable we will write in upper case
// We will prefix our ENV FF names with `VITE_FF_`
// Examples:
// Unleash: `hermes.customerImportRedesign being enabled` -> ENV: `VITE_FF_HERMES_CUSTOMER_IMPORT_REDESIGN=true`
// Unleash: `hermes.hot-launch-tool being disabled` -> ENV: `VITE_FF_HERMES_HOT_LAUNCH_TOOL=false`
FeatureFlags.isEnabled = (function (originalIsEnabled) {
  return function (featureFlag) {
    const environment = import.meta.env.MODE

    if (environment === 'development') {
      const ffName = convertUnleashToENVName(featureFlag)

      const envFlag = import.meta.env[ffName]

      if (typeof envFlag !== 'undefined') {
        return envFlag === 'true'
      }
    }

    return originalIsEnabled.call(this, featureFlag)
  }
})(FeatureFlags.isEnabled)

const FeatureFlagsPlugin = {
  install: function (Vue) {
    FeatureFlags.start()
    Vue.prototype.$featureFlags = FeatureFlags
  },
}

export default FeatureFlagsPlugin
