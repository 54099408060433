<template>
  <OwnStack class="star-rating" row>
    <OwnIcon
      v-for="(star, idx) in stars"
      :key="idx"
      class="star-rating__star"
      :class="[editable && 'star-rating__star--editable']"
      :icon="star.icon"
      :color="star.color"
      :size="size"
      @click="handleClick(idx)"
    />
  </OwnStack>
</template>
<script>
import { OwnIcon, OwnStack } from '@/ui'

import { mapModel } from '@/utils/computed'

export default {
  name: 'StarRating',
  components: {
    OwnIcon,
    OwnStack,
  },
  props: {
    /**
     * Star rating can be changed (use model)
     */
    editable: { type: Boolean, default: false },

    /**
     * Rating (1-5)
     */
    rating: { type: Number, default: undefined },

    /**
     * Star icon size
     */
    size: { type: String, default: undefined },

    /**
     * Star rating model
     * @model
     */
    value: { type: Number, default: undefined },
  },
  computed: {
    ...mapModel('starModel'),
    effectiveRating() {
      const { rating, starModel, editable } = this

      if (editable) return starModel

      return rating
    },
    stars() {
      const { effectiveRating } = this
      return [
        ...Array(effectiveRating).fill({
          color: 'shine-400',
          icon: 'star-filled',
        }),
        ...Array(5 - effectiveRating).fill({
          color: 'slate-300',
          icon: 'star',
        }),
      ]
    },
  },
  methods: {
    handleClick(index) {
      const { editable } = this

      if (!editable) return

      this.starModel = index + 1
    },
  },
}
</script>
<style lang="scss" scoped>
.star-rating {
  &__star {
    &--editable {
      cursor: pointer;
    }
  }
}
</style>
