
{
  "labels": {
    "confirm": "Publish",
    "publishing": "Publishing",
    "success": "Form Published",
    "error": "Uh oh..."
  },
  "content": {
    "confirm": "This will update this form everywhere it is used.",
    "success": "Congrats! Your form is live and ready to add to your website."
  },
  "actions": {
    "end-edit": "End Editing",
    "publish": "Publish",
    "to-website": "Add to Website",
    "check": "Go back and check"
  }
}
