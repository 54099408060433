import Vue from 'vue'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'
import { removeObserver } from '@/utils/helpers'

export const setResourceMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  {
    data,
    target = ['current'],
  }: { data: TResource; target: ['current' | 'original'] }
): void => {
  if (target.includes('current')) {
    Vue.set(state._builder_currentState, 'resource', removeObserver(data))
  }

  if (target.includes('original')) {
    Vue.set(state._builder_originalState, 'resource', removeObserver(data))
  }
}
