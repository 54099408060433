import PbAnalytics from './PbAnalytics'

const pbAnalytics = PbAnalytics.getInstance()

pbAnalytics.configure()

let prevPage = undefined

const analytics = {
  /**
   * Identify User
   * @param {string} userId Id Of User
   */
  identify: (userId) => pbAnalytics.identify(userId),

  /**
   * Abstracts `pbAnalytics.track` to manage page loads.
   * Dedupes page load calls
   * @param {string} pageName Name of page being loaded
   */
  loadPage: (pageName) => {
    if (prevPage !== pageName) {
      prevPage = pageName

      pbAnalytics.track('Page Load', {
        pageName,
      })
    }
  },

  /**
   * Reset user id (logout)
   */
  reset: () => pbAnalytics.reset(),

  /**
   * Sets specific data about user
   * @param {object} data Data to set
   * @param {boolean} override Option to override data if already exists
   */
  setUserData: (data, override) => pbAnalytics.setUserData(data, override),

  /**
   * Track an analytics event
   * @param {string} eventName Name of event to track
   * @param {object} [args] Extra Args to pass
   */
  track: (eventName, args) => pbAnalytics.track(eventName, args),
}

export default analytics
