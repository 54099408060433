<i18n locale="en">
{
  "title": "Confirm delete",
  "message": "Are you sure you want to delete?",
  "actions": {
    "delete": "Delete"
  }
}
</i18n>

<template>
  <OwnDialog v-model="isVisible" @cancel="onCancel">
    <template #heading>
      <OwnDialogHeading :title="title ?? $t('title')" @cancel="onCancel" />
    </template>
    <template #default>
      <OwnDialogContent scroll>
        <p
          class="text-paragraph text-color-secondary"
          v-text="message ?? $t('message')"
        ></p>
      </OwnDialogContent>
    </template>
    <template #footer="{ close }">
      <OwnDialogFooter>
        <OwnButton
          :text="deleteActionLabel ?? $t('actions.delete')"
          danger
          @click="onConfirmDelete(close)"
        />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script>
import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui'

import { mapModel } from '@/utils/computed'

export default {
  name: 'DeleteConfirmationDialog',
  components: {
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
  },
  props: {
    /**
     * Override the delete action label
     */
    deleteActionLabel: { type: String, default: undefined },

    /**
     * Override default message
     */
    message: { type: String, default: undefined },

    /**
     * Override default title
     */
    title: { type: String, default: undefined },

    /**
     * Show Modal
     */
    value: { type: Boolean, default: false },
  },
  computed: {
    ...mapModel('isVisible'),
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
      this.isVisible = false
    },
    onConfirmDelete(close) {
      this.$emit('delete')
      if (close) {
        close()
      }
    },
  },
}
</script>
