<template>
  <OwnPopover
    align-x="right"
    align-y="bottom"
    :slide="['x']"
    content-offset="0"
    grow
  >
    <template #trigger="{ on, open, close }">
      <OwnStack
        class="chipset-input"
        :class="[
          showResultsList && 'chipset-input--open',
          isFocused && 'chipset-input--focused',
        ]"
        row
        @click="focusInput"
      >
        <ActionChip
          v-for="chipMetadata of chipList"
          :key="chipMetadata.id"
          :actions="actions"
          :is-active="chipMetadata.id === activeChip"
          :chip-data="chipMetadata"
          content-width="100"
          @actionOpen="closeMenu(close)"
          @action="$emit('chipAction', $event)"
        />
        <input
          ref="searchInput"
          v-model="searchQuery"
          class="chipset-input__input text-paragraph slate-400"
          type="text"
          @focus="onFocus"
          @blur="onBlur"
          @input="onSearchInput($event, on, { isOpen: open, close })"
        />
      </OwnStack>
    </template>
    <template #content="{ close }">
      <OwnStack class="chipset-input__content">
        <OwnStack class="chipset-input__result-container" align="start">
          <button
            v-for="result of resultList"
            :key="result.id"
            class="chipset-input__item text-paragraph"
            @click="resultSelected(result, close)"
            v-text="result.displayName"
          ></button>
        </OwnStack>

        <button v-if="buttonLabel" @click="buttonClicked(close)">
          <OwnStack
            class="chipset-input__item text-paragraph"
            justify="start"
            align="center"
            spacing="1"
            row
          >
            <OwnIcon class="bluetiful-500" :icon="PhPlus" />
            <span class="bluetiful-500" v-text="buttonLabel"></span>
          </OwnStack>
        </button>
      </OwnStack>
    </template>
  </OwnPopover>
</template>

<script>
import debounce from 'lodash/debounce'
import { PhPlus } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnIcon, OwnPopover, OwnStack } from '@/ui'

import ActionChip from './ActionChip'

export default {
  name: 'ChipsetInput',
  components: {
    ActionChip,
    OwnIcon,
    OwnPopover,
    OwnStack,
  },
  props: {
    /** Actions To Render */
    actions: {
      type: Array,
      default: () => [],
    },

    /**
     * Currently active chip id
     */
    activeChip: {
      type: String,
      default: undefined,
    },

    /**
     * Label for button
     */
    buttonLabel: {
      type: String,
      default: undefined,
    },

    /**
     * List of chips
     */
    chipList: {
      type: Array,
      default: () => [],
    },

    /**
     * Search Value
     */
    searchHandler: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      PhPlus,
      chipDisplay: [],
      isFocused: false,
      resultList: [],
      searchQuery: undefined,
      showResultsList: false,
    }
  },
  methods: {
    buttonClicked(closeCb) {
      this.$emit('buttonClick', this.searchQuery)
      this.searchQuery = ''
      this.closeMenu(closeCb)
    },
    closeMenu(closeCb) {
      this.showResultsList = false
      closeCb()
    },
    focusInput() {
      const searchInput = this.$refs.searchInput

      if (searchInput) {
        searchInput.focus()
      }
    },
    onBlur() {
      this.isFocused = false
    },
    onFocus() {
      this.isFocused = true
    },
    onSearchInput: debounce(function (_event, { click }, { isOpen, close }) {
      const { searchQuery } = this

      if (searchQuery.length) {
        this.sendSearchQuery(searchQuery)

        if (!isOpen) {
          click()
          this.showResultsList = true
        }
      } else {
        close()
        this.showResultsList = false
      }
    }, 250),
    resultSelected(result, closeCb) {
      this.$emit('resultSelected', result)
      this.searchQuery = ''
      this.closeMenu(closeCb)
    },
    async sendSearchQuery(searchQuery) {
      this.resultList = await this.searchHandler(searchQuery)
    },
  },
}
</script>

<style lang="scss" scoped>
.chipset-input {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid $background-divider;
  flex-wrap: wrap;
  gap: 8px;
  cursor: text;

  transition:
    border 0.2s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    color 0.2s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    box-shadow 0.2s cubic-bezier(0.68, -0.04, 0.26, 0.87);

  &:hover {
    border-color: $slate-400;
  }

  &--focused {
    border-color: $slate-400;
    box-shadow: 0 0 0 4px $slate-300;
  }

  &--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__input {
    max-width: 100px;
  }

  &__content {
    background-color: $white;
    border-left: 1px solid $slate-300;
    border-right: 1px solid $slate-300;
    border-bottom: 1px solid $slate-300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__result-container {
    border-bottom: 1px solid $bluetiful-100;
  }

  &__item {
    color: $slate-400;
    padding: 8px 16px;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: $bluetiful-100;
    }
  }
}
</style>
