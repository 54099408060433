import { camelToKebab } from '@owner/casing'

import i18n from '@/plugins/i18n'

import client from '../client'

/**
 * Gets a paginated list of orders for a location
 * @param {object} query A paginated query object
 * @param {string} [query.locationId] Optional Profitboss Location ID
 * @returns {Promise} resolves a list of order objects for a location
 */
export const getOrders = async (query) =>
  client.get('/orders', {
    ...query,
    includeStoreOrders: true,
  })

/**
 * Gets an order
 * @param {string} orderId Order ID to fetch
 * @returns {Promise} resolves a list of order objects for a location
 */
export const getOrder = async (orderId) => client.get(`/orders/${orderId}`)

/**
 * Updates an order object with a given ID
 * @param {string} orderId The order's id
 * @param {object} params The fields to update on an order
 * @returns {Promise} resolves to an object with the updated order object
 */
export const updateOrder = async (orderId, params) =>
  client.patch(`/orders/${orderId}`, params)

/**
 * Voids and refunds an order object by ID
 * @param {string} orderId The order ID to void
 * @returns {object} A success/failure flag
 */
export const voidOrder = async (orderId) =>
  client.post(`/orders/${orderId}/void`)

/**
 * Applies a credit back to the payment method of the customer on an order
 * @param {string} orderId ID of the order being refunded
 * @param {object} params request body
 * @param {string} params.comment A comment justifying the refund
 * @param {number} params.amount Amount of the refund
 * @returns {object} An order object
 */
export const applyRefund = async (orderId, params) =>
  client.post(`/orders/${orderId}/refund`, params)

/**
 * Adds an additional charge to the payment method of the customer on an order
 * @param {string} orderId ID of the order being charged
 * @param {object} params request body
 * @param {string} params.comment A comment justifying the charge
 * @param {number} params.amount Amount of the charge
 * @returns {object} An order object
 */
export const applyCharge = async (orderId, params) =>
  client.post(`/orders/${orderId}/charge`, params)

export const filters = Object.entries({
  status: ['cancelled', 'claimed', 'completed', 'unclaimed', 'voided'],
  type: ['delivery', 'pickup'],
})
  .reduce(
    (acc, [name, options]) =>
      acc.concat([
        {
          label: i18n.t(`orders.filters.${name}`),
          name,
          options: options.map((option) => ({
            label: i18n.t(`orders.filters.${name}-options.${option}`),
            name: option,
          })),
          type: 'Select',
        },
      ]),
    []
  )
  .concat(
    [
      // ... Add boolean filters here
    ].map((filterKey) => ({
      label: i18n.t(`orders.filters.${camelToKebab(filterKey)}`),
      name: filterKey,
      type: 'Boolean',
    }))
  )
