<i18n locale="en">
{
  "done": "Done",
  "labels": {
    "description": "Description",
    "icon": "Icon",
    "title": "Title"
  },
  "placeholders": {
    "description": "Aa",
    "title": "Aa"
  }
}
</i18n>

<template>
  <EditorLayout>
    <OwnStack spacing="8">
      <OwnInputContainer
        :label="$t('labels.title')"
        :errors="title.status.errors"
        :max-chars="MAX_TITLE_LENGTH"
        :value="title.value"
      >
        <OwnInput
          v-model="title.value"
          :placeholder="$t('placeholders.title')"
        />
      </OwnInputContainer>
      <OwnInputContainer
        :label="$t('labels.description')"
        :errors="description.status.errors"
        :max-chars="MAX_DESCRIPTION_LENGTH"
        :value="description.value"
      >
        <OwnTextarea
          v-model="description.value"
          :placeholder="$t('placeholders.description')"
        />
      </OwnInputContainer>

      <OwnInputContainer
        :label="$t('labels.icon')"
        :errors="icon.status.errors"
        :value="icon.value"
      >
        <IconPicker v-model="icon.value" :icons="icons" />
      </OwnInputContainer>
    </OwnStack>

    <template #actions>
      <OwnButton
        :disabled="!isFormValid"
        :text="$t('done')"
        primary
        @click="$emit('back')"
      />
    </template>
  </EditorLayout>
</template>

<script>
import { IconPicker } from '@/components/inputs'
import { FormBuilder, Validators } from '@/forms'
import { EditorLayout } from '@/lib/builder'
import {
  OwnButton,
  OwnInputContainer,
  OwnInput,
  OwnTextarea,
  OwnStack,
} from '@/ui'

import { featureIcons } from '../../../common/feature-icons'

const MAX_DESCRIPTION_LENGTH = 200
const MAX_TITLE_LENGTH = 50

export default {
  name: 'FeatureDetailEditor',
  components: {
    EditorLayout,
    IconPicker,
    OwnButton,
    OwnInput,
    OwnInputContainer,
    OwnStack,
    OwnTextarea,
  },
  mixins: [
    FormBuilder({
      description: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.maxLength(MAX_DESCRIPTION_LENGTH)],
      },
      icon: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
      title: {
        debounce: 250,
        validateOnInit: true,
        validators: [
          Validators.required,
          Validators.maxLength(MAX_TITLE_LENGTH),
        ],
      },
    }),
  ],
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      MAX_DESCRIPTION_LENGTH,
      MAX_TITLE_LENGTH,
      icons: featureIcons,
    }
  },
  created() {
    const { item } = this

    this.setInitialFormValues({
      description: item.description,
      icon: item.icon,
      title: item.title,
    })
  },
  methods: {
    onFormValuesUpdate(formValues) {
      this.$emit('update', formValues)
    },
  },
}
</script>
