
{
  "add-your-resume": "Add your resume / cover letter (PDF only)",
  "apply": "Apply",
  "fields": {
    "application": "Application",
    "attachment": "Attachment",
    "email": "Email",
    "full-name": "Full name",
    "jobs": "Positions",
    "location": "Location",
    "message": "Message",
    "phone": "Phone number"
  },
  "placeholders": {
    "message": "Anything to share as we consider your application?",
    "phone": "Phone number"
  },
  "salary": {
    "range": "Salary range is {min} to {max}",
    "starting-at": "Salaries starting at {salary}",
    "up-to": "Salaries up to {salary}"
  }
}
