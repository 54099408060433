
{
  "alignment": {
    "left": "Left",
    "right": "Right"
  },
  "labels": {
    "header": "Title",
    "body": "Subtitle",
    "action": "Button",
    "media": "Media",
    "alignment": "Alignment",
    "layout": "Background Photo"
  },
  "media": {
    "image": "Photo",
    "none": "None",
    "video": "Video"
  }
}
