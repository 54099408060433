<template>
  <OwnStack class="landing-hero-layout-cover">
    <OwnStack class="landing-hero-container" align="start" justify="end">
      <div class="landing-hero-layout-cover__image-container">
        <LandingHeroBackground :carousel-assets="carouselAssets" overlay />
      </div>
      <LandingHeroContentGroup
        :id="id"
        class="landing-hero-layout-cover__content"
        justify="end"
      />
    </OwnStack>
  </OwnStack>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useWebsiteSection } from '@/features/website-builder/builder/common/useWebsiteSection'

import { OwnStack } from '@/ui'

import LandingHeroBackground from './LandingHeroBackground.vue'
import LandingHeroContentGroup from './LandingHeroContentGroup.vue'

const props = defineProps<{
  id: string
}>()

const activeBlock = useWebsiteSection(props.id)

const carouselAssets = computed(() =>
  activeBlock.value?.params && 'carouselAssets' in activeBlock.value?.params
    ? activeBlock.value?.params?.carouselAssets
    : undefined
)
</script>

<style lang="scss" scoped>
.landing-hero-layout-cover {
  position: relative;
  min-height: 800px;
  padding: 0 8px 8px;

  &__background {
    z-index: 1;
  }

  &__content {
    position: relative;
    flex: 1;
    z-index: 2;
    padding: 80px 80px 120px 80px;
    max-width: 800px;
  }

  &__image-container {
    position: absolute;
    inset: 0;
  }
}

.landing-hero-container {
  overflow: hidden;
  border-radius: 32px;
  flex: 1;
  position: relative;
}
</style>
