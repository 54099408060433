import { camelToKebab } from '@owner/casing'

import i18n from '@/plugins/i18n'

import client from '../client'

/**
 * Fetches a Profitboss Customer
 * @param {string} customerId ID of the Profitboss customer
 * @returns {Promise} resolves a Profitboss Customer object
 */
export const getCustomer = async (customerId) =>
  client.get(`/customers/${customerId}`)

/**
 * Gets a paginated list of Customers by Brand
 * @param {string} brandId Brand Id
 * @param {object} query Query Object
 * @returns {Array} resolves array of Profitboss Customers object
 */
export const getBrandCustomers = async (brandId, query) =>
  client.get(`brands/${brandId}/customers`, query)

/**
 * Gets a paginated list of likes from a customer
 * @param {string} customerId Customer Id
 * @param {object} query Query Object
 * @param {string} query.brandId BrandId
 * @returns {Array} resolves array of Profitboss Customers object
 */
export const getCustomersLikes = async (customerId, query) =>
  client.get(`/customers/${customerId}/likes`, query)

/**
 * Gets a paginated list of Customers by Location
 * @param {string} locationId Location Id
 * @param {object} query Query Object
 * @returns {Array} resolves array of Profitboss Customers object
 */
export const getLocationCustomers = async (locationId, query) =>
  client.get(`locations/${locationId}/customers`, query)

/**
 * Import Customers
 * @param {string} locationId Location Id
 * @param {Array} customersToImport Query Object
 */
export const importCustomers = async (locationId, customersToImport) =>
  client.post(`locations/${locationId}/customer-import`, customersToImport)

export const filters = ['subscribed'].map((filterKey) => ({
  label: i18n.t(`customers.filters.${camelToKebab(filterKey)}`),
  name: filterKey,
  type: 'Boolean',
}))
