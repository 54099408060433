<i18n locale="en">
  {
    "download": "Download",
    "empty": "No PDF to display",
    "error": "Failed to load PDF"
  }
</i18n>

<template>
  <PreviewSheet
    class="pdf-preview"
    background="secondary"
    border="secondary"
    radius="0"
  >
    <OwnStack spacing="16">
      <OwnStack align="center" spacing="8">
        <OwnStack spacing="4">
          <PreviewTypography :text="params.heading" variant="heading2" />
          <PreviewTypography
            v-if="params.subheading"
            :text="params.subheading"
            variant="paragraphRegular"
            color="secondary"
          />
        </OwnStack>
        <PreviewButton :title="$t('download')" caret />
      </OwnStack>
      <VuePdfEmbed
        v-if="params.pdf && !error"
        :source="params.pdf"
        @loading-failed="error = true"
      />
      <PreviewTypography
        v-else-if="params.pdf && error"
        :text="$t('error')"
        class="text-center"
        color="danger"
        variant="paragraphLarge"
      />
      <PreviewTypography
        v-else-if="!params.pdf"
        :text="$t('empty')"
        class="text-center"
        color="danger"
        variant="paragraphLarge"
      />
    </OwnStack>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import { PreviewButton, PreviewSheet, PreviewTypography } from '@/lib/builder'
import { OwnStack } from '@/ui'

import type { PdfSection } from '../../../WebsiteBuilder.types'

const props = defineProps<{
  content: PdfSection
}>()

const params = computed(() => props.content.params)

const error = ref(false)

watch(
  () => params.value.pdf,
  () => {
    error.value = false
  }
)
</script>

<style lang="scss" scoped>
.pdf-preview {
  padding: 120px;
}
</style>
