export default {
  currentConfiguration: (state, _, __, rootGetters) =>
    state.configurations[rootGetters['core/location/active/id']],
  draftConfiguration: (state, getters) =>
    state.draftConfiguration || getters.currentConfiguration,
  locationConfiguration: (state) => (locationId) => {
    return state.configurations[locationId]
  },
  saveInProgress: (state) => state.saveInProgress,
}
