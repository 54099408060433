import Vue from 'vue'

import defaultState from './state'

export default {
  ADD_SPECIAL_HOURS(state, specialHourEntries) {
    Vue.set(state, 'specialHours', {
      ...state.specialHours,
      ...specialHourEntries,
    })
  },
  ADD_SPECIAL_HOUR_ENTRY(state, newEntry) {
    const locationId = newEntry.locationId
    const allEntries = [...state.specialHours[locationId], newEntry]

    Vue.set(state.specialHours, locationId, allEntries)
  },
  DELETE_SPECIAL_HOURS(state, { locationId, specialHoursId }) {
    const locationSpecialHours = state.specialHours[locationId]

    const updateIndex = locationSpecialHours.findIndex(
      ({ id }) => id === specialHoursId
    )

    if (updateIndex >= 0) {
      Vue.delete(state.specialHours[locationId], updateIndex)
    }
  },
  SANITIZE_SPECIAL_HOURS(state) {
    Vue.set(state, 'specialHours', defaultState.specialHours)
  },
  UPDATE_SPECIAL_HOURS(state, { specialHoursEntry, locationId }) {
    const locationSpecialHours = state.specialHours[locationId]

    const updateIndex = locationSpecialHours.findIndex(
      ({ id }) => id === specialHoursEntry.id
    )

    if (updateIndex >= 0) {
      locationSpecialHours[updateIndex] = {
        ...locationSpecialHours[updateIndex],
        ...specialHoursEntry,
      }
    }

    Vue.set(state.specialHours, locationId, [...locationSpecialHours])
  },
}
