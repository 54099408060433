<i18n locale="en">
{
  "labels": {
    "global-nav": "Changes to the navigation affect all pages."
  }
}
</i18n>

<template>
  <OwnStack spacing="8">
    <OwnType :text="$t('labels.global-nav')" />
    <LogoSizeEditor />
    <LogoPositionEditor />
    <NavigationOrderEditor />
  </OwnStack>
</template>

<script>
import { OwnStack, OwnType } from '@/ui'

import LogoPositionEditor from './LogoPositionEditor.vue'
import LogoSizeEditor from './LogoSizeEditor.vue'
import NavigationOrderEditor from './NavigationOrderEditor.vue'

export default {
  name: 'NavigationEditor',
  components: {
    LogoPositionEditor,
    LogoSizeEditor,
    NavigationOrderEditor,
    OwnStack,
    OwnType,
  },
}
</script>
