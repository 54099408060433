<i18n locale="en">
  {
    "actions": {
      "done": "Done"
    },
    "all-jobs": "All",
    "error": "Unable to load application",
    "headings": {
      "attachment": "Resume",
      "comment": "Message",
      "createdAt": "Date Received",
      "jobs": "Applying For",
      "email": "Email",
      "name": "Name",
      "phone": "Phone"
    },
    "loading": "Getting application...",
    "title": "Application Details",
    "view-resume": "View"
  }
</i18n>

<template>
  <OwnDialog :value="true" @cancel="onClose">
    <template #heading>
      <OwnDialogHeading :title="$t('title')" @cancel="onClose" />
    </template>

    <OwnDialogContent scroll>
      <OwnStack v-if="!isLoading" spacing="8">
        <OwnStack spacing="1">
          <OwnType
            :text="$t('headings.name')"
            color="primary"
            variant="subtitle"
          />
          <OwnType :text="item?.name ?? '—'" />
        </OwnStack>
        <OwnStack spacing="1">
          <OwnType
            :text="$t('headings.email')"
            color="primary"
            variant="subtitle"
          />
          <OwnType :text="item?.email ?? '—'" />
        </OwnStack>
        <OwnStack spacing="1">
          <OwnType
            :text="$t('headings.phone')"
            color="primary"
            variant="subtitle"
          />
          <OwnType :text="phone" />
        </OwnStack>
        <OwnStack spacing="1">
          <OwnType
            :text="$t('headings.jobs')"
            color="primary"
            variant="subtitle"
          />
          <OwnType :text="jobs" />
        </OwnStack>
        <OwnStack v-if="item?.comment" spacing="1">
          <OwnType
            :text="$t('headings.comment')"
            color="primary"
            variant="subtitle"
          />
          <OwnType :text="item?.comment" />
        </OwnStack>
        <OwnStack v-if="item?.attachment" spacing="1" align="start">
          <OwnType
            :text="$t('headings.attachment')"
            color="primary"
            variant="subtitle"
          />
          <button
            class="text-button-large bluetiful-500"
            @click="openResume(item)"
            v-text="$t('view-resume')"
          ></button>
        </OwnStack>
        <OwnStack spacing="1">
          <OwnType
            :text="$t('headings.createdAt')"
            color="primary"
            variant="subtitle"
          />
          <OwnType :text="createdAt" />
        </OwnStack>
      </OwnStack>
      <DefaultComponentLoader v-else :message="$t('loading')" />
    </OwnDialogContent>

    <template #footer>
      <OwnDialogFooter>
        <OwnButton :text="$t('actions.done')" primary @click="onClose" />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script>
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { DefaultComponentLoader } from '@/components/loaders'
import { DateFormatter, PhoneFormatter } from '@/format'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnStack,
  OwnType,
} from '@/ui'
import { openInNewTab } from '@/utils/openInNewTab'

export default {
  name: 'ApplicationDetailsDialog',
  components: {
    DefaultComponentLoader,
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnStack,
    OwnType,
  },
  mixins: [notify],
  props: {
    applicationId: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
      item: null,
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
    }),
    createdAt() {
      return this.item ? DateFormatter(this.item?.createdAt) : '—'
    },
    jobs() {
      const jobs = this.item?.jobs
      if (!jobs) {
        return '—'
      }
      if (jobs.length === 0) {
        return this.$t('all-jobs')
      }
      return new Intl.ListFormat().format(jobs.map(({ title }) => title))
    },
    phone() {
      return this.item ? PhoneFormatter(this.item?.phone) : '—'
    },
  },
  async created() {
    this.isLoading = true
    await this.fetchApplication()
    this.isLoading = false
  },
  methods: {
    async fetchApplication() {
      const { activeBrandId, applicationId } = this

      try {
        this.item =
          await ConfiguredClient.dashboard.v1.careers.applications.getApplication(
            {
              params: { applicationId, brandId: activeBrandId },
            }
          )
      } catch (err) {
        logError(err)
        return this.$notify(this.$t('error'), 'error')
      }
    },
    onClose() {
      this.$router.push({
        name: 'location.careers.applications',
      })
    },
    openResume({ attachment }) {
      openInNewTab(attachment)
    },
  },
}
</script>
