<i18n locale="en">
{
  "actions": {
    "add-action": "Add Action"
  },
  "options": {
    "locations": "Locations",
    "profile": "Profile",
    "loyalty": "Loyalty",
    "orders": "Orders"
  }
}
</i18n>

<template>
  <div>
    <OwnOption
      v-if="actionConfig?.params?.action"
      @click="showActionDialog = true"
    >
      <OwnStack el="span" justify="between" align="center" row>
        <OwnType
          :text="$t(`options.${actionConfig.params.action}`)"
          variant="subtitle"
          color="primary"
        />
        <PhPencilSimple class="bluetiful-500" size="24" />
      </OwnStack>
    </OwnOption>

    <OwnButton
      v-else
      class="w-full"
      :text="$t('actions.add-action')"
      @click="showActionDialog = true"
    />

    <AddActionDialog
      v-if="showActionDialog"
      :edit-item="actionConfig"
      @update="$emit('update', $event)"
      @close="showActionDialog = false"
    />
  </div>
</template>

<script>
import { PhPencilSimple } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnButton, OwnOption, OwnStack, OwnType } from '@/ui'

import AddActionDialog from '../../../../common/resource-dialogs/add-action-dialog/AddActionDialog.vue'

export default {
  name: 'ActionForm',
  components: {
    AddActionDialog,
    OwnButton,
    OwnOption,
    OwnStack,
    OwnType,
    PhPencilSimple,
  },
  props: {
    actionConfig: { type: Object, default: null },
  },
  data() {
    return {
      showActionDialog: false,
    }
  },
}
</script>
