<i18n locale="en">
{
  "title": "Publishing...",
  "description": "This will only take a moment"
}
</i18n>

<template>
  <OwnStack
    class="publish-in-progress"
    spacing="3"
    justify="center"
    align="center"
  >
    <OwnProgressSpinner color="bluetiful-500" />
    <OwnStack spacing="1" justify="center" align="center">
      <OwnType :text="$t('title')" variant="title" />
      <OwnType :text="$t('description')" />
    </OwnStack>
  </OwnStack>
</template>

<script>
import { OwnProgressSpinner, OwnStack, OwnType } from '@/ui'

export default {
  name: 'PublishInProgress',
  components: { OwnProgressSpinner, OwnStack, OwnType },
}
</script>

<style lang="scss" scoped>
.publish-in-progress {
  padding: 80px;
}
</style>
