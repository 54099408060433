<template>
  <PreviewSheet class="hero-preview">
    <OwnStack class="hero-preview__content" spacing="4">
      <PreviewTypography
        :text="currentBlockParams?.heading"
        variant="heading2"
      />
      <PreviewTypography
        v-if="currentBlockParams?.subheading"
        :text="currentBlockParams?.subheading"
        variant="paragraphRegular"
      />
    </OwnStack>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { PreviewSheet, PreviewTypography } from '@/lib/builder'
import { OwnStack } from '@/ui'

import type { HeroSection } from '../../../WebsiteBuilder.types'

const props = defineProps<{
  content: HeroSection
}>()

const currentBlockParams = computed(() => props.content?.params)
</script>
<style lang="scss" scoped>
.hero-preview {
  padding: 140px 120px;

  &__content {
    max-width: 700px;
    text-wrap: balance;
  }
}
</style>
