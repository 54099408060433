
{
  "title": "Our free rewards program",
  "labels": {
    "earn": "Earn points",
    "food": "Free food",
    "discount": "Exclusive discounts"
  },
  "cta": "Learn More"
}
