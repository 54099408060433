import { type GetterTree } from 'vuex'

import { type CoreState } from '../types'

import { getActiveBrand } from './brand/getActiveBrand'
import { getActiveBrandLinks } from './brand/getActiveBrandLinks'
import { getActiveBrandTheme } from './brand/getActiveBrandTheme'
import { getActiveDomain } from './brand/getActiveDomain'
import { getActiveId } from './brand/getActiveId'
import { getActiveIntegrations } from './brand/getActiveIntegrations'
import { getActiveLocations } from './brand/getActiveLocations'
import { getActiveLogo } from './brand/getActiveLogo'
import { getActiveMergedTheme } from './brand/getActiveMergedTheme'
import { getActiveName } from './brand/getActiveName'
import { getActivePriceRange } from './brand/getActivePriceRange'
import { getBrandById } from './brand/getBrandById'
import { getBrandLinks } from './brand/getBrandLinks'
import { getLocationsUnderCollections } from './brand/getLocationsUnderCollections'
import { getLocationsWithoutStripeConnected } from './brand/getLocationsWithoutStripeConnected'
import { getLocationsWithoutYextConnected } from './brand/getLocationsWithoutYextConnected'
import { isMultiLocation } from './brand/isMultiLocation'
import { isSingleLocation } from './brand/isSingleLocation'

import { getApplicationScope } from './global/getApplicationScope'

const brandGetters: GetterTree<CoreState, unknown> = {
  // Active brand getters
  'brand/active/*': getActiveBrand,
  'brand/active/assets/logo': getActiveLogo,
  'brand/active/domain': getActiveDomain,
  'brand/active/id': getActiveId,
  'brand/active/integrations/*': getActiveIntegrations,
  'brand/active/isMultiLocation': isMultiLocation,
  'brand/active/isSingleLocation': isSingleLocation,
  'brand/active/links': getActiveBrandLinks,
  'brand/active/locations': getActiveLocations,
  'brand/active/locationsUnderCollections': getLocationsUnderCollections,
  'brand/active/locationsWithoutStripeConnected':
    getLocationsWithoutStripeConnected,
  'brand/active/locationsWithoutYextConnected':
    getLocationsWithoutYextConnected,
  'brand/active/mergedTheme': getActiveMergedTheme,
  'brand/active/name': getActiveName,
  'brand/active/priceRange': getActivePriceRange,
  'brand/active/theme': getActiveBrandTheme,

  // Brand getters
  'brand/byId': getBrandById,
  'brand/links/*': getBrandLinks,

  // Brands
  'brands/*': (state) => state.brands,
}

const globalGetters: GetterTree<CoreState, unknown> = {
  scope: getApplicationScope,
}

const coreGetters = {
  ...brandGetters,
  ...globalGetters,
}

export { coreGetters }
