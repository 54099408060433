import Vue from 'vue'

import {
  type BlockCore,
  type BuilderFetchResult,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'
import { removeObserver } from '@/utils/helpers'

export const initializeDataMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  payload: BuilderFetchResult<TResource, TBlock>
): void => {
  Vue.set(state, '_builder_originalState', removeObserver(payload))
  Vue.set(state, '_builder_currentState', removeObserver(payload))
}
