
{
  "headers": {
    "name": "Name",
    "email": "Email",
    "phone": "Phone Number",
    "id": "Submission ID",
    "created-at": "Date Received",
    "text-field": "Text Field",
    "select": "Choose One",
    "multi-select": "Choose Multiple"
  },
  "error": "Could not load submissions.",
  "no-data": "No submissions for this form.",
  "loading": "Loading submissions...",
  "no-response": "No response given"
}
