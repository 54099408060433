import model from './model'

export default {
  RESET_STATE: (state) => {
    Object.keys(model).forEach((k) => {
      state[k] = model[k]
    })
  },
  SET_LOCATION: (state, location) => {
    state.location = location
  },
}
