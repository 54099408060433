<template>
  <OwnStack
    class="default-empty-state text-color-secondary"
    row
    spacing="3"
    align="center"
  >
    <slot name="icon"></slot>
    <OwnType :text="message" color="secondary" variant="subtitle" />
  </OwnStack>
</template>

<script>
import { OwnStack, OwnType } from '@/ui'

export default {
  name: 'DefaultEmptyState',
  components: {
    OwnStack,
    OwnType,
  },
  props: {
    message: { type: String, required: true },
  },
}
</script>
