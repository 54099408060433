<i18n locale="en">
{
  "descriptions": {
    "non-actionable": "We tried our best, but something went wrong. We couldn’t publish all of your changes.",
    "actionable": "We tried our best, but something went wrong. We found a few issues. Here’s what you need to check:"
  }
}
</i18n>

<template>
  <OwnStack class="website-publish-error-content" spacing="6">
    <OwnType :text="description" />

    <OwnStack spacing="3">
      <OwnStack v-for="error of displayErrors" :key="error.id" spacing="2" row>
        <PhWarning class="status-warning" size="24" />
        <OwnType :text="error.label" />
      </OwnStack>
    </OwnStack>
  </OwnStack>
</template>

<script>
import { isDefined } from '@vueuse/core'
import { PhWarning } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnStack, OwnType } from '@/ui'

export default {
  name: 'PublishErrorContent',
  components: {
    OwnStack,
    OwnType,
    PhWarning,
  },
  props: {
    blockModules: { type: Array, default: () => [] },
    currentBlocks: { type: Array, default: () => [] },
    errors: { type: Array, default: () => [] },
  },
  computed: {
    description() {
      const { displayErrors } = this

      return displayErrors.length > 0
        ? this.$t('descriptions.actionable')
        : this.$t('descriptions.non-actionable')
    },
    displayErrors() {
      const { flatBlockModules, errors, currentBlocks } = this

      return errors
        .map((error) => {
          const block = currentBlocks.find((block) => block.id === error)

          const blockModule = flatBlockModules.find(
            (module) => module.type === block?.type
          )

          if (!blockModule) return

          return {
            id: error,
            label: blockModule?.label,
          }
        })
        .filter(isDefined)
    },
    flatBlockModules() {
      return this.blockModules.flatMap((module) => {
        return module.meta.map((moduleMeta) => ({
          ...moduleMeta,
          type: moduleMeta.key,
        }))
      })
    },
  },
}
</script>
