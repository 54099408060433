<i18n locale="en">
{
  "actions": {
    "update": "Update"
  },
  "errors": {
    "upload": "Unable to upload image"
  }
}
</i18n>
<template>
  <FullPageEditor>
    <template #top-bar>
      <DefaultFullPageTopBar :title="title" @close="$emit('close')">
        <template #right-actions>
          <OwnButton
            class="w-full"
            :processing="uploading"
            :text="$t('actions.update')"
            primary
            @click="updateImage"
          />
        </template>
      </DefaultFullPageTopBar>
    </template>

    <OwnStack class="own-image-cropper">
      <OwnStack
        class="own-image-cropper__container"
        justify="center"
        align="center"
      >
        <slot name="actions" :update="updateImage" :uploading="uploading" />

        <Cropper
          ref="cropper"
          class="own-image-cropper__cropper"
          background-class="own-image-cropper__background"
          :src="localImage"
          :canvas="{
            height: cropHeight,
            width: cropWidth,
          }"
          :default-size="defaultSize"
          :stencil-props="{
            aspectRatio: calcAspectRatio,
            handlersClasses: {
              default: 'own-image-cropper__handle',
            },
          }"
        />
      </OwnStack>
    </OwnStack>
  </FullPageEditor>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper'

import 'vue-advanced-cropper/dist/style.css'

import { ConfiguredClient } from '@/api'
import { DefaultFullPageTopBar, FullPageEditor } from '@/components/layouts'
import notify from '@/mixins/notify'
import { OwnButton, OwnStack } from '@/ui'
import { logError } from '@/utils/logging'

const getBlob = (canvas, fileType) => {
  // `toBlob` defaults to PNG
  let toBlobOptions = []
  if (fileType === 'jpeg') {
    // Export to JPEG at 90% quality
    toBlobOptions = ['image/jpeg', 0.9]
  }
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob === null) {
          reject()
        } else {
          resolve(blob)
        }
      },
      ...toBlobOptions
    )
  })
}

export default {
  name: 'ImageCropper',
  components: {
    Cropper,
    DefaultFullPageTopBar,
    FullPageEditor,
    OwnButton,
    OwnStack,
  },
  mixins: [notify],
  props: {
    /** Aspect Ratio */
    aspectRatio: { type: Number, default: undefined },

    /** Force Crop Height */
    cropHeight: { type: Number, default: undefined },

    /** Force Crop Width */
    cropWidth: { type: Number, default: undefined },

    /**
     * File type to upload. Use `'jpeg'` (the default) for photos and `'png'` for illustrations (e.g. the logo).
     * @default 'jpeg'
     */
    fileType: {
      type: String,
      default: 'jpeg',
      validator: (v) => ['jpeg', 'png'].includes(v),
    },

    /** Image (url or base64 enc string) */
    image: { type: String, default: '' },

    /** Title for topbar */
    title: { type: String, default: undefined },
  },
  data() {
    return {
      localImage: '',
      uploading: false,
    }
  },
  computed: {
    calcAspectRatio() {
      const { aspectRatio, cropWidth, cropHeight } = this

      if (aspectRatio) {
        return aspectRatio
      }

      if (cropWidth && cropHeight) {
        return cropWidth / cropHeight
      }

      return undefined
    },
  },
  watch: {
    image(newImage) {
      this.localImage = newImage
    },
  },
  created() {
    const { image } = this
    this.localImage = image
  },
  methods: {
    defaultSize({ imageSize }) {
      return imageSize
    },
    async updateImage() {
      try {
        const { canvas } = this.$refs.cropper.getResult()

        this.uploading = true

        const blob = await getBlob(canvas, this.fileType)

        const body = new FormData()
        body.append('file', blob)
        const { url } = await ConfiguredClient.uploadImage({
          body,
          query: { collection: 'funnel/images' },
        })

        this.$emit('update', url)
      } catch (err) {
        logError(err)
        this.$notify(this.$t('errors.upload'), 'error')
      } finally {
        this.uploading = false
      }
    },
  },
}
</script>
<style lang="scss">
/* Un-scoped because the class is used verbatim by `Cropper` */
.own-image-cropper__background {
  background: repeating-conic-gradient(#eee 0 90deg, #cdcdcd 0 180deg) 0 0/40px
    40px round;
}

/* Un-scoped because the class is used verbatim by `Cropper` */
.own-image-cropper__handle {
  background-color: #222;
  opacity: 0.8;
  border-radius: 10%;
  border: 1px solid #eee;
}

.vue-advanced-cropper__foreground {
  /* Remove black background color */
  background-color: unset;
}
</style>
<style lang="scss" scoped>
.own-image-cropper {
  height: 100%;
  width: 100%;

  &__container {
    flex-grow: 1;
    max-height: calc(100% - 82px);
    padding: 16px;
  }

  &__cropper {
    height: 100%;
    max-height: 550px;
    width: 100%;
    max-width: 960px;
    background-color: $slate-100;
  }
}
</style>
