import { type Route, type RouteConfig } from 'vue-router'

import { resourceTypes } from '../constants'
import { type ResourceTypes } from '../types'

const buildChildRoute = (resourceType: ResourceTypes): RouteConfig => ({
  children: [
    {
      component: async () => import('../views/MenuNewView.vue'),
      name: `brand.menu.${resourceType}.new`,
      path: 'new',
      props: () => ({
        resourceId: undefined,
        resourceType,
      }),
    },
    {
      component: async () => import('../views/MenuDetailView.vue'),
      name: `brand.menu.${resourceType}.detail`,
      path: ':resourceId',
      props: (route: Route) => ({
        resourceId: route.params.resourceId,
        resourceType,
      }),
    },
  ],
  component: async () => import('../views/MenuMainView.vue'),
  name: `brand.menu.${resourceType}`,
  path: resourceType,
  props: () => ({
    resourceType,
  }),
})

export const menuRouter = [
  {
    children: resourceTypes
      .filter((resourceType) => resourceType !== 'coupon')
      .map((resourceType) => buildChildRoute(resourceType)),
    component: {
      // eslint-disable-next-line xss/no-mixed-html
      template: '<router-view />',
    },
    meta: {
      title: 'restaurant.menu',
    },
    name: 'brand.menu',
    path: 'menu',
    redirect: { name: 'brand.menu.menu' },
  },
  {
    ...buildChildRoute('coupon'),
    meta: {
      title: 'restaurant.coupons',
    },
    name: 'brand.coupon',
    path: 'coupon',
  },
]
