import { chunk } from './helpers'

/**
 * Converts a hex string to an rgba color
 * @param {string} hexColor a hex color
 * @param {number} [alpha] an optional alpha value
 * @returns {string} an rgba string
 */
export const hexToRgba = (hexColor, alpha) => {
  const [r, g, b, a] = chunk(hexColor.slice(1), 2).map((c) => parseInt(c, 16))
  return `rgba(${r},${g},${b},${alpha || Math.round((a / 255) * 100) / 100})`
}

/**
 * Determines whether a color is light or dark
 * @param {string} color A color string
 * @returns {boolean} true for dark
 */
export const isDarkColor = (color) => {
  var r, g, b, hsp
  if (color.match(/^rgb/)) {
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )
    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))
    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  if (hsp > 127.5) return false
  return true
}

/**
 * Converts a hex color to HSLA
 * @param {string} hex a hex color
 * @returns {string} An HSLA string
 */
export const hexToHsl = (hex) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0
  if (hex.length == 4) {
    r = '0x' + hex[1] + hex[1]
    g = '0x' + hex[2] + hex[2]
    b = '0x' + hex[3] + hex[3]
  } else if (hex.length == 7) {
    r = '0x' + hex[1] + hex[2]
    g = '0x' + hex[3] + hex[4]
    b = '0x' + hex[5] + hex[6]
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  const cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin
  let h = 0,
    s = 0,
    l = 0

  if (delta == 0) h = 0
  else if (cmax == r) h = ((g - b) / delta) % 6
  else if (cmax == g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return { h, l, s }
}
