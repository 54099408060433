import { brandsApi } from '@/api'

import { convertToPathObject } from '@/utils/structure'

const singleBrandActions = {
  brand: {
    update: {
      allLinks: async ({ commit }, { brandId, updatedLinks }) => {
        const { id, links } = await brandsApi.updateLinks(brandId, updatedLinks)

        commit('UPDATE_BRAND_LINKS', { id, update: links })
      },
      assets: async ({ commit }, { brandId, updatedAssets }) => {
        const { id, assets } = await brandsApi.updateBrandAssets(
          brandId,
          updatedAssets
        )

        commit('UPDATE_BRAND_ASSETS', { id, update: assets })
      },
      domain: async ({ commit }, { brandId, domain }) => {
        commit('SET_BRAND_DOMAIN', { brandId, domain })
      },
      link: async ({ commit, getters }, { brandId, update }) => {
        const targetLinks = getters['brand/links/*'](brandId)

        if (targetLinks) {
          const updatedLinks = { ...targetLinks, ...update }

          const { id, links } = await brandsApi.updateLinks(
            brandId,
            updatedLinks
          )

          commit('UPDATE_BRAND_LINKS', { id, update: links })
        }
      },
      name: async ({ commit }, { brandId, newName }) => {
        const { id, name } = await brandsApi.updateBrand(brandId, {
          name: newName,
        })

        commit('UPDATE_BRAND_NAME', { id, update: name })
      },
      priceRange: async ({ commit }, { brandId, newPriceRange }) => {
        const { id, priceRange } = await brandsApi.updateBrand(brandId, {
          priceRange: newPriceRange,
        })

        commit('UPDATE_BRAND_PRICE_RANGE', { id, update: priceRange })
      },
    },
  },
}

export default {
  ...convertToPathObject(singleBrandActions),
}
