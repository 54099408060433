<template>
  <div class="full-page-editor">
    <OwnStack class="full-page-editor__body">
      <slot name="top-bar">
        <DefaultFullPageTopBar :title="title" @close="$emit('close')" />
      </slot>

      <OwnScrollContainer class="w-full h-full">
        <slot name="default"></slot>
      </OwnScrollContainer>
    </OwnStack>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { OwnScrollContainer, OwnStack } from '@/ui'

import DefaultFullPageTopBar from './DefaultFullPageTopBar.vue'

const props = withDefaults(
  defineProps<{
    level?: number
    title?: string
  }>(),
  {
    level: 0,
    title: undefined,
  }
)

const topOffset = computed(() => {
  return `${props.level * 8}px`
})
</script>

<style lang="scss" scoped>
.full-page-editor {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 120;
  background-color: rgba(#000000, 50%);

  &__body {
    position: fixed;
    top: v-bind(topOffset);
    left: 0;
    height: calc(100% - v-bind(topOffset));
    width: 100%;

    background-color: $background-primary;
  }
}
</style>
