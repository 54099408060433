
{
  "title": "Submission Details",
  "defaults": {
    "name": "Name",
    "email": "Email",
    "phone": "Phone Number",
    "date": "Date/time",
    "text-field": "Text Field",
    "select": "Choose one",
    "multi-select": "Choose multiple"
  },
  "no-response": "No response given.",
  "date-received": "Date Received",
  "actions": {
    "mark-unread": "Mark Unread",
    "done": "Done"
  },
  "unread-error": "Could not mark unread."
}
