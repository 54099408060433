
{
  "custom": "Custom",
  "pay": "Pay",
  "processing-fee": "Processing Fee",
  "subtotal": "Subtotal",
  "total": "Total",
  "sections": {
    "amount": "Gift Card Amount",
    "from": "From",
    "payment-method": "Payment Method",
    "to": "To",
    "when": "When Should We Send It?"
  },
  "fields": {
    "card-number": "Card Number",
    "delivery-time": "Delivery Time",
    "deliver-now": "Deliver Now",
    "deliver-later": "Deliver Later",
    "email": "Email",
    "expiry-date": "Expiry Date",
    "name": "Name",
    "note": "Note",
    "security-code": "Security Code",
    "send-to-myself": "Send to myself"
  },
  "placeholders": {
    "card-number": "1234 1234 1234 1234",
    "expiry-date": "MM / YY",
    "security-code": "CVC",
    "your-email": "Your Email",
    "your-name": "Your Name"
  }
}
