import { type RouteConfig } from 'vue-router'

export const growthUnauthenticatedRouter: RouteConfig[] = [
  {
    children: [
      {
        component: async () => import('../views/GrowthReportView.vue'),
        meta: {
          displayType: 'grader',
          title: 'growth-report',
        },
        name: 'growth.growth-report',
        path: ':pipelineId',
        props: true,
      },
      {
        component: async () => import('../views/GrowthScanView.vue'),
        meta: {
          displayType: 'grader',
          title: 'growth-scan',
        },
        name: 'growth.growth-scan',
        path: '',
      },
    ],
    component: async () => import('../views/GrowthProductRouterView.vue'),
    path: 'grader',
  },
  {
    children: [
      {
        component: async () => import('../views/GrowthReportView.vue'),
        meta: {
          displayType: 'usfoods',
          title: 'growth-report',
        },
        name: 'growth.growth-report',
        path: ':pipelineId',
        props: true,
      },
      {
        component: async () => import('../views/GrowthScanView.vue'),
        meta: {
          displayType: 'usfoods',
          title: 'growth-scan',
        },
        name: 'growth.growth-scan',
        path: '',
      },
    ],
    component: async () => import('../views/GrowthProductRouterView.vue'),
    path: 'usfoods',
  },
]
