
{
  "options": {
    "America/Anchorage": "Alaska Time (AKST / AKDT)",
    "America/Chicago": "Central Time (CST)",
    "America/New_York": "Eastern Time (EST)",
    "Pacific/Honolulu": "Hawaii Standard Time (HST)",
    "America/Phoenix": "Mountain Standard Time (MST) (no DST)",
    "America/Denver": "Mountain Daylight Time (MDT)",
    "America/Los_Angeles": "Pacific Time (PST)"
  }
}
