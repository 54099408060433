<template>
  <OwnStack class="w-full h-full" justify="center" align="center" row>
    <PhSparkle size="128" color="#dddddd" />
  </OwnStack>
</template>

<script>
import { PhSparkle } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnStack } from '@/ui'

export default {
  name: 'AIEmailPlaceholder',
  components: {
    OwnStack,
    PhSparkle,
  },
}
</script>
