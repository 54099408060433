<i18n locale="en">
{
  "cta": "Order Now",
  "empty-title": "Coupon",
  "subtitles": {
    "item": "To get {amount} off select items, use this code at checkout",
    "ticket": "To get {amount} off, use this code at checkout"
  }
}
</i18n>

<template>
  <PreviewSheet background="primary" radius="0" class="px-2 py-3">
    <PreviewSheet border="divider" :placeholder="!coupon">
      <OwnSkeletonLoader height="100">
        <div
          v-if="!isLoading"
          class="overflow-hidden flex-col coupon-preview__container px-6 py-6"
        >
          <div v-if="coupon" class="h-full">
            <div class="flex-1 h-full flex-col justify-between">
              <div class="flex-col">
                <PreviewTypography
                  :text="coupon.code.toUpperCase()"
                  color="primary"
                  variant="titleSmall"
                />
                <PreviewTypography
                  :text="couponDescription"
                  color="secondary"
                  variant="paragraphSmall"
                />
              </div>
              <PreviewButton
                variant="secondary"
                color="secondary"
                size="xsmall"
                :title="$t('cta')"
              />
            </div>
          </div>
          <PreviewSheet
            v-else
            class="pa-6 flex-col align-center justify-center h-full w-full"
          >
            <PreviewIcon color="secondary" size="24" :icon="emptyIcon" />
            <PreviewTypography
              :text="$t('empty-title')"
              color="secondary"
              variant="paragraphSmall"
            />
          </PreviewSheet>
        </div>
      </OwnSkeletonLoader>
    </PreviewSheet>
  </PreviewSheet>
</template>

<script>
import { isDefined } from '@vueuse/core'
import { PhPercent } from 'phosphor-vue/dist/phosphor-vue.esm'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'

import {
  PreviewButton,
  PreviewIcon,
  PreviewSheet,
  PreviewTypography,
} from '@/lib/builder'
import { logError } from '@/logger'
import { OwnSkeletonLoader } from '@/ui'
import { formatNumberValue } from '@/utils/helpers'

export default {
  name: 'CouponPreview',
  components: {
    OwnSkeletonLoader,
    PreviewButton,
    PreviewIcon,
    PreviewSheet,
    PreviewTypography,
  },
  props: {
    content: { type: Object, required: true },
  },
  data() {
    return {
      coupon: null,
      emptyIcon: PhPercent,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      brand: 'brand/brand',
    }),
    couponCode() {
      const { content } = this

      return content?.data?.params?.code
    },
    couponDescription() {
      const { coupon } = this

      if (!coupon) return

      const couponType = ['item-fixed', 'item-percent'].includes(coupon.type)
        ? 'item'
        : 'ticket'

      const amountFormat = ['item-percent', 'ticket-percent'].includes(
        coupon.type
      )
        ? 'percentage'
        : 'currency'

      const amount = formatNumberValue(coupon.amount, amountFormat)

      return this.$t(`subtitles.${couponType}`, { amount })
    },
    couponId() {
      const { content } = this

      return content?.data?.params?.couponId
    },
    sectionType() {
      const { content } = this

      return content?.data?.type
    },
  },
  watch: {
    code: 'loadCoupon',
    couponId: 'loadCoupon',
  },
  async mounted() {
    await this.loadCoupon()
  },
  methods: {
    async getCouponByCode() {
      try {
        const [coupon] =
          await ConfiguredClient.menu.v1.brands.brand.coupons.getCouponsByCode({
            params: {
              brandId: this.brand.id,
              code: this.couponCode,
            },
          })

        return coupon
      } catch (error) {
        logError(error)
      }
    },
    async getCouponById() {
      try {
        if (isDefined(this.couponId)) {
          return await ConfiguredClient.coupons.coupon.get({
            params: { menuItemId: this.couponId },
          })
        }
      } catch (error) {
        logError(error)
      }
    },
    async loadCoupon() {
      this.isLoading = true

      const { sectionType } = this

      if (!sectionType) return

      let coupon

      if (sectionType === 'id') {
        coupon = await this.getCouponById(this.couponId)
      }

      if (sectionType === 'code') {
        coupon = await this.getCouponByCode(this.couponCode)
      }

      this.coupon = coupon

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.coupon-preview__container {
  height: 166px;
}
</style>
