import { type RouteConfig } from 'vue-router'

/**
 * Creates a list of Reports routes under a specific scope
 * @param {string} scope location or brand
 * @returns {object[]} a list of routes
 */
export const getReportsRoutes = (
  scope: 'brand' | 'location'
): RouteConfig[] => [
  {
    children: [
      {
        component: async () =>
          import('@/views/Common/Analytics/Reports/ReportsMainView.vue'),
        meta: {
          isLocationPage: true,
        },
        name: `${scope}.reports.main`,
        path: '',
        props: {
          scope,
        },
      },
      {
        component: async () =>
          import('@/views/Common/Analytics/Reports/ReportsDetailView.vue'),
        meta: {
          isLocationPage: true,
        },
        name: `${scope}.reports.detail`,
        path: ':reportSlug',
        props: {
          scope,
        },
      },
      {
        path: '**',
        redirect: { name: `${scope}.reports.main` },
      },
    ].filter((route) => !!route),
    component: {
      name: 'ReportsRouterView',
      // eslint-disable-next-line xss/no-mixed-html
      template: '<router-view></router-view>',
    },
    meta: {
      isLocationPage: true,
      title: 'reports.reports',
    },
    name: `${scope}.reports`,
    path: 'reports',
    props: { scope },
    redirect: { name: `${scope}.reports.main` },
  },
]
