import { PhForkKnife, PhStar, PhFile } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type TemplateOption } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import { type EmailSectionCreate, type CampaignResourceCreate } from '../types'

import { blankTemplate } from './blankTemplate'
import { menuItemTemplate } from './menuItemTemplate'
import { offerTemplate } from './offerTemplate'

const templateOptions: Array<
  TemplateOption<CampaignResourceCreate, EmailSectionCreate>
> = [
  {
    fetchData: menuItemTemplate,
    icon: PhForkKnife,
    requiresLocation: true,
    value: 'menu-item',
  },
  {
    fetchData: offerTemplate,
    icon: PhStar,
    requiresLocation: true,
    value: 'offer',
  },
  {
    fetchData: blankTemplate,
    icon: PhFile,
    value: 'blank',
  },
].map((option) => ({
  ...option,
  label: i18n.t(`email-builder.options.${option.value}`),
}))

export default {
  description: i18n.t('email-builder.description'),
  heading: i18n.t('email-builder.heading'),
  options: templateOptions,
  title: i18n.t('email-builder.title'),
}
