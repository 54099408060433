import Vue from 'vue'
import Router from 'vue-router'

import guards from './guards'
import routes from './routes'
import './prototypes'

Vue.use(Router)

export const router = new Router({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes,
})

router.beforeEach(guards)
