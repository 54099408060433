<i18n locale="en">
{
  "actions": {
    "cancel": "Cancel"
  }
}
</i18n>

<template>
  <OwnStack class="default-full-page-top-bar" :row="isDesktop" spacing="4">
    <OwnStack class="flex-1" align="center" :spacing="isDesktop ? 8 : 4" row>
      <slot name="left-actions">
        <OwnButton
          :text="isDesktop ? $t('actions.cancel') : undefined"
          @click="$emit('close')"
        >
          <template #icon-left>
            <PhXCircle size="24" />
          </template>
        </OwnButton>
      </slot>

      <OwnType v-if="title" :text="title" variant="title" />
    </OwnStack>

    <slot name="center"></slot>

    <OwnStack class="flex-1" justify="end" align="center" spacing="6" row>
      <TriggerSupport
        v-if="isDesktop"
        v-slot="{ triggerSupport }"
        class="default-full-page-top-bar__support"
      >
        <button @click="triggerSupport">
          <PhChatTeardropText size="24" />
        </button>
      </TriggerSupport>

      <div :class="!$breakpoints.mdAndUp ? 'w-full' : undefined">
        <slot name="right-actions" />
      </div>
    </OwnStack>
  </OwnStack>
</template>

<script>
import {
  PhChatTeardropText,
  PhXCircle,
} from 'phosphor-vue/dist/phosphor-vue.esm'

import { TriggerSupport } from '@/core/support'
import breakpoints from '@/mixins/breakpoints'
import { OwnButton, OwnStack, OwnType } from '@/ui'

export default {
  name: 'DefaultFullPageTopBar',
  components: {
    OwnButton,
    OwnStack,
    OwnType,
    PhChatTeardropText,
    PhXCircle,
    TriggerSupport,
  },
  mixins: [breakpoints],
  props: {
    title: { type: String, default: undefined },
  },
  computed: {
    isDesktop() {
      return this.$breakpoints.mdAndUp
    },
  },
}
</script>

<style lang="scss" scoped>
.default-full-page-top-bar {
  padding: 16px 24px;
  @include control-shadow();

  &__support {
    flex-shrink: 0;
    height: 24px;
  }
}
</style>
