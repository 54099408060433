/**
 * Sets the browser title for the current page
 * @mixin
 */
export default {
  name: 'PageTitle',
  watch: {
    $route() {
      document.title = `Owner.com | ${this.$t(
        `core.pages.${this.$_pt_getTitle()}.title`
      )}`
    },
  },
  methods: {
    $_pt_getTitle() {
      const matches = this.$route.matched
      for (let i = matches.length - 1; i > 0; i--) {
        const match = matches[i]
        if (match.meta && match.meta.title) return match.meta.title
      }
      return 'fallback'
    },
  },
}
