import { getStoreValue } from '../../utils/getStoreValue'

/* eslint jsdoc/require-param: 0 */
export default {
  /** Returns the current location's address */
  address: (state) => (state.location ? state.location.address : {}),

  /** Returns the current location's analytics object */
  analytics: (state) => (state.location ? state.location.analytics : {}),

  /** Returns the current location's automations */
  automations: (state) =>
    state.location ? state.location.features.automations : {},

  /** Returns the current location's contact object */
  contact: (state) => (state.location ? state.location.contact : {}),

  /** Returns the current location's delivery hours object */
  deliveryHours: (state) =>
    state.location ? state.location.hours.delivery : null,

  /** Active features on the location */
  features: (state) => (state.location ? state.location.features : null),

  /** Funnel on the location */
  funnel: (state) => (state.location ? state.location.funnel : null),

  /** Returns the current location's hours object */
  hours: (state) => (state.location ? state.location.hours : null),

  /** Returns the current location's ID */
  id: (state) => (state.location ? state.location.id : undefined),

  /** Timezone of the location */
  localTimezone: (state) =>
    state.location ? state.location.localTimezone : null,

  /** Returns the current location from the store */
  location: (state) => state.location,

  /** Returns the current location's messaging object */
  messaging: (state) => (state.location ? state.location.messaging : undefined),

  /** Returns the current location's name */
  name: (state) => (state.location ? state.location.name : undefined),

  /** Returns the current location's orderTypes object */
  orderTypes: (state) => (state.location ? state.location.orderTypes : null),

  path:
    (state, _getters, globalState) =>
    ({ locationId, path }) => {
      if (state && state.id === locationId) {
        return getStoreValue(state.location, path.split('.'))
      }

      const brandLevelLocation = globalState.brand.locations.find(
        (location) => location.id === locationId
      )

      if (!brandLevelLocation) {
        throw new Error('Brand Level location not loaded')
      }

      return getStoreValue(brandLevelLocation, path.split('.'))
    },

  /** Returns the current location's payments object */
  payments: (state) => (state.location ? state.location.payments : {}),

  /** Returns the current location's pickup hours object */
  pickupHours: (state) => (state.location ? state.location.hours.pickup : null),

  /** Returns the current location's pos settings */
  pos: (state) =>
    state.location && state.location.pos ? state.location.pos : null,

  /** Returns the current location's orderTypes object */
  preparation: (state) =>
    state.location?.preparation ? state.location.preparation : null,

  /** Returns the processing object on the location */
  processing: (state) => (state.location ? state.location.processing : null),

  /** Returns the URL slug for the location's online ordering site */
  slug: (state) => (state.location ? state.location.slug : null),

  /** Returns the current location's theme object */
  theme: (state) => (state.location ? state.location.theme : {}),
}
