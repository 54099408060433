import { type RouteConfig } from 'vue-router'

export const oauthRouter: RouteConfig[] = [
  {
    children: [
      {
        component: async () => import('@/views/OAuthView.vue'),
        meta: {
          authenticated: true,
          title: 'oauth.stripe',
        },
        name: 'oauth.stripe',
        path: 'stripe',
        props: {
          provider: 'stripe',
        },
      },
      {
        component: async () => import('@/views/OAuthView.vue'),
        meta: {
          authenticated: true,
          title: 'oauth.google',
        },
        name: 'oauth.google',
        path: 'google',
        props: {
          provider: 'google',
        },
      },
    ],
    component: {
      name: 'OAuthRouterView',
      // eslint-disable-next-line xss/no-mixed-html
      template: '<router-view></router-view>',
    },
    name: 'oauth',
    path: 'oauth',
    redirect: '/',
  },
]
