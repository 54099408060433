import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'
import plugins from './plugins'

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  plugins,
  strict: false,
})

export * from './utils/useMappedGetter'
export * from './utils/useMappedAction'
