<i18n locale="en">
{
  "options": {
    "logo-left": "Left",
    "logo-center": "Center"
  },
  "label": "Logo Position"
}
</i18n>

<template>
  <OwnInputContainer :label="$t('label')">
    <OwnSegmentedControl
      :options="options"
      :value="logoPosition"
      @input="onLayoutChange"
    />
  </OwnInputContainer>
</template>

<script>
import set from 'lodash/set'
import { mapActions, mapGetters } from 'vuex'

import { OwnInputContainer, OwnSegmentedControl } from '@/ui'

export default {
  name: 'LogoPositionEditor',
  components: {
    OwnInputContainer,
    OwnSegmentedControl,
  },
  computed: {
    ...mapGetters({
      currentResource: 'websiteBuilder/currentResource',
    }),
    logoPosition() {
      const { currentResource } = this

      return currentResource?.funnel?.navigation?.layout
    },
    options() {
      return [
        {
          value: 'logo-left',
        },
        {
          value: 'logo-center',
        },
      ].map((option) => ({
        ...option,
        label: this.$t(`options.${option.value}`),
      }))
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    onLayoutChange(newPosition) {
      this.updateResource(set({}, 'funnel.navigation.layout', newPosition))
    },
  },
}
</script>
