<i18n locale="en">
{
  "title": "Our free rewards program",
  "labels": {
    "earn": "Earn points",
    "food": "Free food",
    "discount": "Exclusive discounts"
  },
  "cta": "Learn More"
}
</i18n>

<template>
  <PreviewSheet background="secondary">
    <div class="email-loyalty py-12 px-8 w-full flex-row">
      <div spacing="2" class="flex-1">
        <PreviewTypography
          :text="$t('title')"
          variant="titleRegular"
          color="primary"
        />
        <div class="flex-col gap-3 pt-4">
          <div
            v-for="point in points"
            :key="point.label"
            class="flex-row align-center"
            align="center"
            spacing="1"
          >
            <PreviewIcon
              color="secondary"
              size="16"
              class="pr-1"
              :icon="point.icon"
            />
            <PreviewTypography
              :text="point.label"
              variant="paragraphSmall"
              color="secondary"
            />
          </div>
        </div>
      </div>
      <div>
        <PreviewButton
          color="secondary"
          variant="secondary"
          size="xsmall"
          :title="$t('cta')"
        />
      </div>
    </div>
  </PreviewSheet>
</template>

<script>
import {
  PhGift,
  PhCurrencyCircleDollar,
  PhStar,
} from 'phosphor-vue/dist/phosphor-vue.esm'

import {
  PreviewButton,
  PreviewIcon,
  PreviewSheet,
  PreviewTypography,
} from '@/lib/builder'

export default {
  name: 'EmailLoyalty',
  components: {
    PreviewButton,
    PreviewIcon,
    PreviewSheet,
    PreviewTypography,
  },
  data() {
    return {
      points: [
        { icon: PhGift, label: 'earn' },
        { icon: PhCurrencyCircleDollar, label: 'food' },
        { icon: PhStar, label: 'discount' },
      ].map(({ icon, label }) => ({ icon, label: this.$t(`labels.${label}`) })),
    }
  },
}
</script>
