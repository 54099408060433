<i18n locale="en">
{
  "title": "Confirm",
  "message": "Would you really like to take this action?",
  "actions": {
    "confirm": "Confirm",
    "cancel": "Cancel"
  }
}
</i18n>

<template>
  <OwnDialog v-model="isVisible" @cancel="closeDialog">
    <template #heading>
      <OwnDialogHeading :title="title ?? $t('title')" @cancel="closeDialog" />
    </template>
    <template #default>
      <OwnDialogContent scroll>
        <OwnType :text="message ?? $t('message')" />
      </OwnDialogContent>
    </template>
    <template #footer>
      <OwnDialogFooter>
        <OwnButton :text="$t('actions.cancel')" @click="closeDialog" />
        <OwnButton
          :text="confirmLabel ?? $t('actions.confirm')"
          primary
          @click="onConfirm"
        />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>
<script lang="ts" setup>
import { useVModel } from '@vueuse/core'

import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnType,
  OwnButton,
} from '@/ui'

const props = defineProps<{
  confirmLabel: string
  message: string
  title: string
  value: boolean
}>()
const emit = defineEmits<{
  (event: 'confirm'): void
  (event: 'input', value: boolean): void
}>()
const isVisible = useVModel(props, 'value', emit, { eventName: 'input' })

const closeDialog = () => {
  isVisible.value = false
}

const onConfirm = () => {
  emit('confirm')
  closeDialog()
}
</script>
