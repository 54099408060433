<template>
  <div class="edit-panel">
    <slot v-if="isEditing" name="edit"></slot>
    <slot v-else name="preview"></slot>
  </div>
</template>

<script>
export default {
  name: 'EditPanel',
  props: {
    /**
     * Show the edit component
     */
    isEditing: { type: Boolean, required: true },
  },
}
</script>

<style lang="scss" scoped>
.edit-panel {
  height: 100%;
}
</style>
