<i18n locale="en">
{
  "actions": {
    "enable": "Publish Page",
    "update": "Publish Updates"
  }
}
</i18n>

<template>
  <OwnButton :text="$t(`actions.${publishType}`)" primary v-on="$listeners">
    <template #icon-right>
      <PhArrowCircleUp size="24" />
    </template>
  </OwnButton>
</template>

<script>
import { PhArrowCircleUp } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnButton } from '@/ui'

export default {
  name: 'WebsitePublishConfirmActions',
  components: {
    OwnButton,
    PhArrowCircleUp,
  },
  props: {
    /**
     * Is the resource already live?
     */
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    publishType() {
      const { isLive } = this

      return isLive ? 'update' : 'enable'
    },
  },
}
</script>
