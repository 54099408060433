<template>
  <component :is="icon" class="preview-icon" :style="style" :size="size" />
</template>

<script>
import get from 'lodash/get'

import { typographyColors, typographyMapping } from './legacy/typographyMapping'
import { useMergedTheme } from './mixins/useMergedTheme'

export default {
  name: 'PreviewIcon',
  mixins: [useMergedTheme],
  props: {
    /** Color */
    color: {
      type: String,
      default: undefined,
      validator: (v) =>
        [
          'primary',
          'secondary',
          'placeholder',
          'brand',
          'success',
          'info',
          'warning',
          'danger',
          'white',
          'black',
          'inherit',
          undefined,
        ].includes(v),
    },

    /** The Icon component for the preview icon */
    icon: { type: Function, required: true },

    /** The size for the icon */
    size: { type: [String, Number], default: undefined },
  },
  computed: {
    cssColor() {
      const { color, mergedTheme, variant } = this

      if (color === 'white') {
        return '#ffffff'
      }

      if (color === 'black') {
        return '#0D0D0D'
      }

      if (color === 'inherit') {
        return undefined
      }

      if (color) return get(mergedTheme.colors, typographyColors[color])

      return get(
        mergedTheme.colors,
        typographyColors[typographyMapping[variant].defaultColor]
      )
    },
    style() {
      return { color: this.cssColor }
    },
  },
}
</script>

<style lang="scss" scoped src="./legacy/fonts.scss" />
