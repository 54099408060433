import type { RouteConfig } from 'vue-router'

export const setupRouter: RouteConfig[] = [
  {
    children: [
      {
        component: async () => import('../components/SetupStepFallback.vue'),
        name: 'location.setup.flow.fallback',
        path: ':flow/location/fallback',
        props: (route) => ({
          flow: route.params.flow,
        }),
      },
      {
        component: async () => import('../views/SetupFlowView.vue'),
        name: 'location.setup.flow.step',
        path: ':flow/location/:locationId/:step',
        props: (route) => ({
          flow: route.params.flow,
          locationId: route.params.locationId,
          step: route.params.step,
        }),
      },
      {
        component: async () => import('../views/SetupFlowView.vue'),
        name: 'brand.setup.flow.step',
        path: ':flow/:step',
        props: (route) => ({
          flow: route.params.flow,
          step: route.params.step,
        }),
      },
      {
        component: async () => import('../views/SetupFlowView.vue'),
        name: 'brand.setup.flow',
        path: ':flow',
        props: (route) => ({
          flow: route.params.flow,
        }),
      },
      {
        path: '*',
        redirect: { name: 'brand.setup' },
      },
    ],
    component: async () => import('../views/SetupView.vue'),
    name: 'brand.setup',
    path: 'setup',
    props: (route) => ({
      flow: route.params.flow,
      step: route.params.step,
    }),
  },
]
