import Vue from 'vue'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'

export const commitStagedChangesMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): void => {
  const currentCommands = state._builder_commandQueue

  Vue.set(
    state,
    '_builder_commandQueue',
    currentCommands.map((command) => ({ ...command, committed: true }))
  )
}
