
{
  "map": "Google Map",
  "directions": "Get Directions",
  "address": "Address",
  "contacts": "Contacts",
  "order": "Order Now",
  "see-hours": "See hours",
  "open-until": "Open until xx:xx"
}
