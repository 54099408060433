
{
  "labels": {
    "label": "Title",
    "link-to": "Link To",
    "description": "Select a link, page, or action the button opens when clicked."
  },
  "options": {
    "external": "URL",
    "page": "Page",
    "action": "Action"
  }
}
