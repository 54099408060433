import { type Component } from 'vue'

import {
  type BlockCore,
  type BuilderModuleConfig,
  type ResourceCore,
} from '@/lib/builder'

import StandaloneBuilderPreview from '../components/StandaloneBuilderPreview.vue'

export const useBuilderComponents = <
  TBlock extends BlockCore,
  TResourceCreate,
  TResourceUpdate,
  TResource extends ResourceCore,
  TBlockCreate,
  TBlockUpdate,
>(
  config: BuilderModuleConfig<
    TResource,
    TResourceCreate,
    TResourceUpdate,
    TBlock,
    TBlockCreate,
    TBlockUpdate
  >
): { preview: () => Component } => ({
  preview: () => ({
    name: 'StandalonePreviewWrapper',
    props: {
      /**
       * Resource Data
       * @public
       */
      resource: { required: true, type: Object },

      /**
       * Section Data
       * @public
       */
      sections: { required: true, type: Array },
    },
    provide: {
      brandThemePath: undefined,
      previewBackgroundColor: config.options.previewBackgroundColor,
      readonly: true,
      scaling: config.options.scaling,
      storeModule: config.options.storeModule,
    },
    // @ts-expect-error - This is an alternative Vue rendering option
    render(createVNode) {
      const { resource, sections } = this

      const preview = createVNode(StandaloneBuilderPreview, {
        props: {
          blockModules: config.blocks,
          previewFooter: config.components.preview.footer,
          previewHeading: config.components.preview.heading,
          resource,
          sections,
        },
      })

      const previewWrapper = config.components.preview.wrapper

      if (previewWrapper) return createVNode(previewWrapper, {}, [preview])

      return preview
    },
  }),
})
