import { type PreviewStaticModule } from '@/lib/builder'

import NavigationEditor from './editor/NavigationEditor.vue'

import WebsiteBuilderPreviewNavbar from './preview/WebsiteBuilderPreviewNavbar.vue'

export const WebsiteHeadingStaticModule: PreviewStaticModule = {
  editor: NavigationEditor,
  preview: WebsiteBuilderPreviewNavbar,
}
