<i18n locale="en">
{
  "title": "Welcome Back",
  "subtitle": "Continue to Owner",
  "logo-alt": "Owner Logo"
}
</i18n>
<template>
  <FullPageCardBackground class="full-page-card-layout h-full w-full">
    <slot name="background-layer"></slot>

    <OwnStack class="h-full w-full" justify="center" align="center">
      <OwnStack
        class="full-page-card-layout__content-container"
        align="center"
        justify="center"
        spacing="8"
      >
        <img
          v-if="!hideLogo"
          class="full-page-card-layout__logo"
          src="/branding/owner-logo.svg"
          :alt="$t('logo-alt')"
        />

        <FullPageCard
          :title="title"
          :subtitle="subtitle"
          :background-image="backgroundImage"
          :large="cardSize === 'lg'"
          :show-checkbox="showCheckbox"
        >
          <!--
            @slot card-content
          -->
          <slot v-if="$slots['card-content']" name="card-content"></slot>

          <template #review>
            <slot v-if="$slots['review']" name="review"></slot>
          </template>
        </FullPageCard>

        <!--
          @slot after-card - Inject content after the card
        -->
        <slot name="after-card"></slot>
      </OwnStack>
    </OwnStack>
  </FullPageCardBackground>
</template>
<script>
import { OwnStack } from '@/ui'

import FullPageCard from './FullPageCard'
import FullPageCardBackground from './FullPageCardBackground'

export default {
  name: 'FullPageCardLayout',
  components: {
    FullPageCard,
    FullPageCardBackground,
    OwnStack,
  },
  props: {
    /**
     * Should show image on right
     */
    backgroundImage: { type: String, default: undefined },

    /**
     * Card Size
     */
    cardSize: {
      type: String,
      default: 'sm',
      validator: (v) => ['sm', 'lg'].includes(v),
    },

    /**
     * Hide the logo
     */
    hideLogo: { type: Boolean, default: false },

    /**
     * Should show checkbox in header
     */
    showCheckbox: { type: Boolean, default: false },

    /**
     * subtitle
     */
    subtitle: { type: String, default: undefined },

    /**
     * title
     */
    title: { type: String, default: undefined },
  },
}
</script>
<style lang="scss" scoped>
.full-page-card-layout {
  background-color: $bluetiful-100;

  &__content-container {
    padding: 16px;
  }

  &__logo {
    width: 125px;
  }
}
</style>
