import type { ActionContext } from 'vuex'

import { saveToRecentBrands } from '@/core'
import type { CoreState } from '@/core/store/types'

export const setActiveBrand = async (
  { dispatch, commit, getters, rootGetters }: ActionContext<CoreState, unknown>,
  brandId: string
): Promise<void> => {
  if (brandId) {
    const brand = getters['brand/byId'](brandId)
    const locations = rootGetters['core/locations/byBrandId'](brandId)

    if (brand && locations) {
      await dispatch('brand/setBrand', { brand, locations }, { root: true })
    }

    if (brand) saveToRecentBrands(brand)
  }

  commit('SET_ACTIVE_BRAND', brandId)
}
