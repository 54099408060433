
{
  "links": {
    "terms": "Terms of Use",
    "privacy": "Privacy Policy",
    "unsubscribe": "Unsubscribe",
    "feedback": "Share Feedback",
    "owner": "Made with Owner"
  }
}
