import { type RouteConfig } from 'vue-router'

import { applyMetaToChildren } from '@/utils/router'

export const settingsRouterBrand: RouteConfig[] = [
  {
    children: [
      {
        component: async () =>
          import(
            '../components/brand/editors/general/BrandGeneralSettings.vue'
          ),
        name: `brand.settings.general`,
        path: 'general',
      },
      {
        component: async () =>
          import('../components/brand/editors/BrandLinks.vue'),
        name: `brand.settings.links`,
        path: 'links',
      },
      {
        component: async () =>
          import('../components/brand/editors/UserNotifications.vue'),
        name: `brand.settings.notifications`,
        path: 'notifications',
      },
      {
        component: async () =>
          import(
            '../components/brand/editors/sms-marketing/SMSMarketingEditor.vue'
          ),
        name: `brand.settings.sms-marketing`,
        path: 'sms-marketing',
      },
    ].map((route) => ({
      ...route,
      meta: {
        locationRoute: 'location.settings.general',
        redirectToLocation: true,
      },
    })),
    component: async () => import('../views/BrandSettingsView.vue'),
    meta: {
      redirectToLocation: true,
      title: 'settings-brand',
    },
    name: 'brand.settings',
    path: 'settings',
    props: {
      pageName: 'settings',
    },
  },
]

export const settingsRouterLocation2: RouteConfig[] = [
  {
    children: [
      {
        component: async () =>
          import(
            '../components/location/editors/general/LocationGeneralSettingsEditor.vue'
          ),
        name: `location.settings2.general`,
        path: 'general',
      },
      {
        component: async () =>
          import('../components/location/editors/AddressEditor.vue'),
        name: `location.settings2.address`,
        path: 'address',
      },
      {
        component: async () =>
          import('../components/location/editors/LocationLinks.vue'),
        name: `location.settings2.links`,
        path: 'links',
      },
      {
        component: async () =>
          import('../components/location/editors/ContactInfoEditor.vue'),
        name: `location.settings2.contact`,
        path: 'contact',
      },
      {
        component: async () =>
          import('../components/location/editors/FulfillmentEditor.vue'),
        name: `location.settings2.fulfillment`,
        path: 'fulfillment',
      },
      {
        component: async () =>
          import(
            '../components/location/editors/integrations/IntegrationsEditor.vue'
          ),
        name: `location.settings2.integrations`,
        path: 'integrations',
      },
      {
        component: async () =>
          import('../components/location/editors/PaymentsEditor.vue'),
        meta: {
          roles: ['pb:*'],
        },
        name: `location.settings2.payments`,
        path: 'payments',
      },
      {
        component: async () =>
          import('../components/location/editors/FeaturesEditor.vue'),
        meta: {
          roles: ['pb:*'],
        },
        name: `location.settings2.features`,
        path: 'features',
      },
    ].map((route) => ({
      ...route,
      meta: {
        ...route.meta,
        brandRedirect: 'brand.settings.general',
        isLocationPage: true,
      },
    })),
    component: async () => import('../views/LocationSettingsView2.vue'),
    meta: {
      scopes: ['locations:update'],
      title: 'settings',
    },
    name: `location.settings2`,
    path: 'settings2',
  },
]

export const settingsRouterLocation = applyMetaToChildren(
  [
    {
      children: [
        {
          card: 'general',
        },
        {
          card: 'funnel',
        },
        {
          card: 'notifications',
        },
        {
          card: 'address',
        },
        {
          card: 'contact',
        },
        {
          card: 'fulfillment',
        },
        {
          card: 'integrations',
        },
        {
          card: 'sms-marketing',
        },
        {
          acl: {
            roles: ['pb:super'],
          },
          card: 'payments',
        },
        {
          acl: {
            roles: ['pb:super'],
          },
          card: 'subscriptions',
        },
        {
          acl: {
            roles: ['pb:super'],
          },
          card: 'features',
        },
      ].map(({ acl, card }) => ({
        component: async () => import('../views/LocationSettingsView.vue'),
        meta: {
          ...acl,
          brandRedirect: 'brand.settings',
          isLocationPage: true,
          title: 'settings',
        },
        name: `location.settings.${card}`,
        path: card,
      })),
      component: {
        name: 'LocationSettingsRouterView',
        // eslint-disable-next-line xss/no-mixed-html
        template: '<router-view></router-view>',
      },
      name: `location.settings`,
      path: 'settings',
      redirect: { name: 'location.settings.general' },
    },
  ],
  {
    scopes: ['locations:update'],
    title: 'settings',
  }
)
