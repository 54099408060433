import axios from 'axios'

import $store from '@/store'

import { BadRequestError } from './errors/BadRequestError'
import { ConnectionError } from './errors/ConnectionError'
import { GenericError } from './errors/GenericError'
import { InternalServerError } from './errors/InternalServerError'
import { ResourceNotFoundError } from './errors/ResourceNotFoundError'
import { UnauthorizedError } from './errors/UnauthorizedError'

const VITE_OLYMPUS_URL = import.meta.env.VITE_OLYMPUS_URL

const enforceExplicitNull = (params) =>
  Object.entries(params).reduce(
    (acc, [key, value]) =>
      Object.assign(acc, { [key]: value === null ? 'null' : value }),
    {}
  )

class PbClient {
  constructor() {
    this.instance = axios.create({
      baseURL: VITE_OLYMPUS_URL,
      handleErrors: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Pb-Audience': 'hermes',
        'X-Pb-Client': 'web',
      },
      withCredentials: true,
    })
    // Append appropriate headers to every request
    this.instance.interceptors.request.use(
      (config) => {
        const csrfToken = $store.getters['session/csrfToken']
        if (csrfToken) {
          config.headers['X-CSRFToken'] = csrfToken
        }
        return config
      },
      (err) => Promise.reject(err)
    )

    this.instance.interceptors.response.use(
      async (res) => Promise.resolve(res.data),
      async (err) => {
        if (err.response) {
          const { data, status } = err.response

          switch (status) {
            case 400:
              throw new BadRequestError(data)

            case 401:
              await $store.dispatch('session/logout')
              throw new UnauthorizedError(data)

            case 404:
              throw new ResourceNotFoundError(data)

            case 500:
              throw new InternalServerError(data)

            default:
              throw new GenericError(data)
          }
        }
        throw new ConnectionError({
          message: 'There was an issue contacting our servers',
        })
      }
    )
  }

  /**
   * Performs a GET request
   * @param {string} path URI component
   * @param {object} params URL query params
   * @param {object} config custom axios config
   * @returns {Promise} an API request promise
   */
  get(path, params = {}, config = {}) {
    return this.instance.get(path, {
      params: enforceExplicitNull(params),
      ...config,
    })
  }

  /**
   * Performs a DELETE request
   * @param {string} path URI component
   * @param {object} params URL query params
   * @param {object} config custom axios config
   * @returns {Promise} an API request promise
   */
  delete(path, params = {}, config = {}) {
    return this.instance.delete(path, {
      params: enforceExplicitNull(params),
      ...config,
    })
  }

  /**
   * Performs a POST request
   * @param {string} path URI component
   * @param {object|Array} [params] API request body
   * @param {object} [config] custom axios config
   * @returns {Promise} an API request promise
   */
  post(path, params, config) {
    const serializedData =
      params && !(params instanceof FormData) ? JSON.stringify(params) : params

    return this.instance.post(path, serializedData, { ...config })
  }

  /**
   * Performs a PUT request
   * @param {string} path URI component
   * @param {object} params API request body
   * @param {object} [config] custom axios config
   * @returns {Promise} an API request promise
   */
  put(path, params, config) {
    return this.instance.put(path, JSON.stringify(params), { ...config })
  }

  /**
   * Performs a PATCH request
   * @param {string} path URI component
   * @param {object} params API request body
   * @param {object} [config] custom axios config
   * @returns {Promise} an API request promise
   */
  patch(path, params, config) {
    return this.instance.patch(path, { ...params }, { ...config })
  }
}

export default new PbClient()

export const rawClient = axios
