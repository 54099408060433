import type { RouteConfig } from 'vue-router'

import { applyMetaToChildren } from '@/utils/router'

const getTabRoute = (name: string): RouteConfig => ({
  component: async () => await import('@/views/SuperView/OnboardingView.vue'),
  name: `owner-onboarding.${name}`,
  path: name,
  props: {
    activeTab: `owner-onboarding.${name}`,
  },
})

export const adminRouter: RouteConfig[] = [
  {
    children: [
      {
        path: '',
        redirect: 'overview',
      },
      {
        component: async () => import('../views/BrandListView.vue'),
        meta: {
          roles: ['pb:*'],
          title: 'admin.brands',
        },
        name: 'brands',
        path: 'overview',
      },
      {
        component: async () => import('../views/LocationListView.vue'),
        meta: {
          roles: ['pb:*'],
          title: 'admin.locations',
        },
        name: 'locations',
        path: 'locations',
      },
      // LEGACY - Needs update =================================
      {
        component: async () => import('@/views/SuperView/UserView.vue'),
        meta: {
          roles: ['pb:*'],
          title: 'admin.users',
        },
        name: 'users',
        path: 'users',
      },
      ...applyMetaToChildren(
        [
          {
            children: [
              getTabRoute('invite'),
              getTabRoute('brand'),
              getTabRoute('location'),
              getTabRoute('user'),
              getTabRoute('device'),
            ],
            component: {
              name: 'OnboardingRouterView',
              // eslint-disable-next-line xss/no-mixed-html
              template: '<router-view></router-view>',
            },
            meta: {
              title: 'admin.onboarding',
            },
            name: `owner-onboarding`,
            path: 'onboard',
            redirect: { name: 'owner-onboarding.invite' },
          },
        ],
        {
          roles: ['pb:onboarding', 'pb:super'],
        }
      ),
      // LEGACY - Needs update =================================
    ],
    component: async () => import('@/features/admin/views/AdminView.vue'),
    meta: {
      roles: ['pb:*'],
    },
    name: 'super',
    path: '',
  },
]
