<i18n locale="en">
{
  "labels": {
    "showing": "Showing {currentRange} of {total}",
    "previous": "Previous",
    "next": "Next"
  }
}
</i18n>

<template>
  <OwnStack v-if="!loading" align="center" justify="between" row>
    <p
      v-if="numShowingLabel"
      class="table-footer__pagination-range text-paragraph slate-400"
      v-text="numShowingLabel"
    ></p>
    <OwnStack spacing="2" justify="end" row>
      <OwnButton
        icon-left="chevron-left"
        :text="$t('labels.previous')"
        small
        :disabled="!hasPrevPage"
        clamp
        @click="$emit('prev')"
      />
      <OwnButton
        icon-right="chevron-right"
        :text="$t('labels.next')"
        small
        :disabled="!hasNextPage"
        clamp
        @click="$emit('next')"
      />
    </OwnStack>
  </OwnStack>
</template>

<script>
import { OwnButton, OwnStack } from '@/ui'

export default {
  name: 'TableFooter',
  components: {
    OwnButton,
    OwnStack,
  },
  props: {
    /**
     * Item limit per page
     */
    limit: { type: Number, required: true },

    /**
     * Is Loading
     */
    loading: { type: Boolean, default: false },

    /**
     * Current Page
     */
    page: { type: Number, required: true },

    /**
     * Number of Pages
     */
    pageCount: { type: Number, default: undefined },

    /**
     * Total Number of items
     */
    totalItems: { type: Number, default: undefined },
  },
  computed: {
    currentRange() {
      const { page, limit, totalItems } = this

      const startRange = page * limit - (limit - 1)

      let endRange = page * limit

      if (endRange > totalItems) {
        endRange = totalItems
      }

      return startRange === endRange
        ? startRange.toString()
        : `${startRange}-${endRange}`
    },
    hasNextPage() {
      const { page, limit, totalItems } = this

      if (!totalItems) {
        return false
      }

      return page * limit < totalItems
    },
    hasPrevPage() {
      const { page } = this

      return page !== 1
    },
    numShowingLabel() {
      const { currentRange, totalItems } = this

      if (!totalItems || !currentRange) {
        return undefined
      }

      return this.$t('labels.showing', {
        currentRange,
        total: totalItems,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-footer {
  &__pagination-range {
    flex-shrink: 0;
  }
}
</style>
