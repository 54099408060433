<template>
  <OwnOption v-on="$listeners">
    <OwnStack class="w-full" el="span" align="center" spacing="3">
      <PhPlusCircle size="24" />
      <OwnType :text="text" el="span" color="primary" variant="subtitle" />
    </OwnStack>
  </OwnOption>
</template>

<script>
import { PhPlusCircle } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnOption, OwnStack, OwnType } from '@/ui'

export default {
  name: 'AddNavItemButton',
  components: { OwnOption, OwnStack, OwnType, PhPlusCircle },
  props: {
    text: { type: String, required: true },
  },
}
</script>
