
{
  "occasion": {
    "label": "Occasion",
    "description": "E.g. Father’s Day, Trivia Night, different opening hours, special offer, etc.",
    "placeholder": "Aa"
  },
  "tone": {
    "options": {
      "elegant": "🥂 Elegant",
      "friendly": "😊 Friendly",
      "playful": "🎉 Playful",
      "informative": "📚 Informative",
      "persuasive": "💥 Persuasive",
      "casual": "🍔 Casual",
      "yoda": "✨ Yoda",
      "null": "👀 Select"
    },
    "label": "Tone of Voice"
  },
  "item": {
    "label": "Menu Item",
    "add": "Select Menu Item"
  },
  "coupon": {
    "label": "Coupon",
    "add": "Select Coupon"
  },
  "generate": "Generate",
  "regenerate": "Re-Generate",
  "generate-error": "Could not generate a template."
}
