import { PhTextAlignLeft } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type CampaignResource,
  type TextSection,
  type TextSectionCreate,
  type TextSectionUpdate,
} from '../../types'
import { emailBlockMethods } from '../common/methods'

import TextEditor from './editor/TextEditor.vue'
import { createTextSection } from './methods/createTextData'
import TextPreview from './preview/TextPreview.vue'

export const TextBlockModule: BuilderBlockModule<
  TextSection,
  TextSectionCreate,
  TextSectionUpdate,
  CampaignResource
> = {
  createData: createTextSection,
  editor: TextEditor,
  meta: [
    {
      icon: PhTextAlignLeft,
      key: 'heading',
      label: i18n.t('email-builder.blocks.heading'),
    },
    {
      icon: PhTextAlignLeft,
      key: 'subheading',
      label: i18n.t('email-builder.blocks.subheading'),
    },
    {
      icon: PhTextAlignLeft,
      key: 'paragraph',
      label: i18n.t('email-builder.blocks.paragraph'),
    },
  ],
  methods: emailBlockMethods,
  preview: TextPreview,
}
