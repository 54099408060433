<i18n locale="en">
{
  "label": "Search",
  "placeholders": {
    "search": "Search"
  }
}
</i18n>

<template>
  <OwnInputContainer :label="$t('label')" sr-only>
    <OwnInput
      v-model="searchTerm"
      class="search-input"
      :placeholder="placeholder ?? $t('placeholders.search')"
      :borderless="borderless"
      :rounded="!borderless && !standard"
      font="subtitle"
      small
    >
      <template #prepend>
        <PhMagnifyingGlass size="24" />
      </template>
    </OwnInput>
  </OwnInputContainer>
</template>

<script>
import debounce from 'lodash/debounce'
import { PhMagnifyingGlass } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnInput } from '@/ui'
import OwnInputContainer from '@/ui/lib/OwnInputContainer/OwnInputContainer.vue'
import { mapModel } from '@/utils/computed'

export default {
  name: 'SearchInput',
  components: {
    OwnInput,
    OwnInputContainer,
    PhMagnifyingGlass,
  },
  props: {
    /** Renders the search input without a border */
    borderless: { type: Boolean, default: false },

    /**
     * Optional placeholder for the text field
     */
    placeholder: { type: String, default: undefined },

    /**
     * Use the default styling for the underlying field. Workaround to continue supporting current default of `rounded`.
     */
    standard: { type: Boolean, default: false },

    /**
     * Search term
     * @model
     */
    value: { type: String, default: '' },
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  computed: {
    ...mapModel('inputValue'),
  },
  watch: {
    searchTerm() {
      this.setModelValue()
    },
  },
  methods: {
    setModelValue: debounce(function () {
      const { searchTerm } = this

      this.inputValue = searchTerm
    }, 300),
  },
}
</script>

<style lang="scss" scoped>
.search-input {
  background-color: $white;
}
</style>
