<i18n locale="en">
{
  "actions": {
    "save": "Save"
  }
}
</i18n>

<template>
  <OwnButton :text="$t('actions.save')" primary @click="$emit('publish')">
    <template #icon-right>
      <PhCheckCircle size="24" />
    </template>
  </OwnButton>
</template>

<script>
import { PhCheckCircle } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnButton } from '@/ui'

export default {
  name: 'StyleRightActions',
  components: {
    OwnButton,
    PhCheckCircle,
  },
}
</script>
