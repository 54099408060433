import { adminRouter } from '@/features/admin/router/admin.router'
import { authRouter } from '@/features/auth/router/auth.router'
import { growthUnauthenticatedRouter } from '@/features/growth/router/growth.router'

import { brandRoutes } from './brandRoutes'
import { oauthRouter } from './oauth.router'

export default [
  {
    children: [
      ...brandRoutes,
      ...adminRouter,
      ...oauthRouter,
      ...authRouter,
      ...growthUnauthenticatedRouter,
    ],
    component: {
      name: 'AppRouterView',
      // eslint-disable-next-line xss/no-mixed-html
      template: '<router-view></router-view>',
    },
    name: 'app',
    path: '/',
  },
  {
    name: 'not-found',
    path: '*',
    redirect: '/',
  },
]
