import Vue from 'vue'

import defaultState from './state'

export default {
  RESET_STATE: (state) => {
    Vue.set(state, 'action', defaultState.action)
    Vue.set(state, 'filters', defaultState.filters)
    Vue.set(state, 'query', defaultState.query)
    Vue.set(state, 'view', defaultState.view)
  },
  SET_ACTION: (state, newAction) => {
    state.action = newAction
  },
  SET_QUERY_END: (state, end) => {
    Vue.set(state.query, 'end', end)
  },
  SET_QUERY_SEARCH: (state, searchTerm) => {
    Vue.set(state.query, 'query', searchTerm)
  },
  SET_QUERY_SORT: (state, newSort) => {
    Vue.set(state.query, 'sort', newSort)
  },
  SET_QUERY_START: (state, start) => {
    Vue.set(state.query, 'start', start)
  },
  SET_VIEW: (state, newView) => {
    state.view = newView
  },
  UNSET_QUERY_END: (state) => {
    if (state.query.end) {
      Vue.delete(state.query, 'end')
    }
  },
  UNSET_QUERY_SEARCH: (state) => {
    if (state.query.query) {
      Vue.delete(state.query, 'query')
    }
  },
  UNSET_QUERY_START: (state) => {
    if (state.query.start) {
      Vue.delete(state.query, 'start')
    }
  },
  UPDATE_PAGE: (state, currentPage) => {
    state.query.page = currentPage
  },
}
