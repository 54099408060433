import { useBuilderComponents, type BuilderModuleConfig } from '@/lib/builder'
import i18n from '@/plugins/i18n'
import { locationAnalytics } from '@/utils'

import CampaignStatsRightAction from '../../../components/otc/CampaignStatsRightAction.vue'
import OTCBuilderRightActions from '../../../components/otc/OTCBuilderRightActions.vue'
import { TextBuilderBlocks } from '../blocks'
import CreateTextDialog from '../components/CreateTextDialog.vue'
import TextBuilderPreviewWrapper from '../components/preview/TextBuilderPreviewWrapper.vue'
import PublishDialog from '../components/PublishDialog.vue'
import TemplateSidebar from '../components/template/TemplateSidebar.vue'
import ViewCampaignSidebar from '../components/ViewCampaignSidebar.vue'
import { methods } from '../methods'
import textBuilderTemplates from '../templates'
import {
  type CampaignResource,
  type CampaignResourceCreate,
  type CampaignResourceUpdate,
  type SmsSection,
  type SmsSectionCreate,
  type SmsSectionUpdate,
} from '../types'

export const textBuilderConfig: BuilderModuleConfig<
  CampaignResource,
  CampaignResourceCreate,
  CampaignResourceUpdate,
  SmsSection,
  SmsSectionCreate,
  SmsSectionUpdate
> = {
  blocks: TextBuilderBlocks,
  components: {
    preview: {
      wrapper: TextBuilderPreviewWrapper,
    },
    publishDialog: PublishDialog,
    rightActions: OTCBuilderRightActions,
  },
  customPages: [
    {
      analyticsEvents: [],
      components: {
        rightActions: CampaignStatsRightAction,
        sidebar: ViewCampaignSidebar,
      },
      perResource: true,
      slug: 'view',
    },
  ],
  methods,
  options: {
    analyticsEvents: [
      {
        handler: (_resource, brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Text Edit Started',
        type: 'start',
      },
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
          id: resource.id,
        }),
        label: 'Text Edit Successful',
        type: 'update',
      },
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
          id: resource.id,
        }),
        label: 'Text Edit Failed',
        type: 'failed',
      },
      {
        handler: (resource, brand) => {
          return {
            ...locationAnalytics(brand),
            id: resource.id,
          }
        },
        label: 'New Text Successful',
        type: 'create',
      },
    ],
    createAnalyticsEvents: [
      {
        handler: (brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Text Creation Started',
        type: 'start',
      },
      {
        handler: (brand, resource, type) => {
          return {
            ...locationAnalytics(brand),
            id: resource?.id,
            type: type ? 'manual' : 'generated',
            ...(type
              ? {
                  manualTemplate: type,
                }
              : {}),
          }
        },
        label: 'Text Template Selected',
        type: 'template-select',
      },
      {
        handler: (brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Text Creation Failed',
        type: 'failed',
      },
    ],
    home: {
      name: 'brand.marketing.otc.overview',
    },
    resourceName: 'texts',
    resourceTitle: {
      key: 'name',
      placeholder: i18n.t('text-builder.resource-title.placeholder'),
    },
    root: 'brand.marketing',
    storeModule: 'textBuilder',
    templates: {
      ...textBuilderTemplates,
      components: {
        dialog: CreateTextDialog,
        sidebar: TemplateSidebar,
      },
    },
  },
}

export const textBuilderComponents = useBuilderComponents(textBuilderConfig)
