<i18n locale="en">
{
  "dates": {
    "today": "Today",
    "tomorrow": "Tomorrow"
  },
  "labels": {
    "not-set": "Not set"
  }
}
</i18n>
<template>
  <VueDatePicker
    v-model="selectedDate"
    class="day-range"
    clearable
    :disabled-date="getDateDisabled"
  >
    <template #input>
      <button class="day-range__input">
        <OwnType el="span" :text="displayDate" color="primary" />
      </button>
    </template>
  </VueDatePicker>
</template>

<script>
import { add, format, isPast, parse, endOfDay } from 'date-fns'

import VueDatePicker from 'vue2-datepicker'

import { OwnType } from '@/ui'

const VALUE_FORMAT = 'yyyy-MM-dd'
const DISPLAY_FORMAT = 'MMM dd, yyyy'

export default {
  name: 'DatePicker',
  components: {
    OwnType,
    VueDatePicker,
  },
  props: {
    /** Prevent past dates */
    future: { type: Boolean, default: false },

    /**
     * Placeholder Value
     */
    placeholder: { type: String, default: undefined },

    /*
     * @model
     */
    value: { type: String, default: undefined },
  },
  data() {
    return {
      reservedDates: {
        [format(new Date(), VALUE_FORMAT)]: 'today',
        [format(add(new Date(), { days: 1 }), VALUE_FORMAT)]: 'tomorrow',
      },
    }
  },
  computed: {
    displayDate() {
      const { selectedDate, placeholder } = this

      if (selectedDate) {
        const reservedDate = this.reservedDates[selectedDate]

        return reservedDate
          ? this.$t(`dates.${reservedDate}`)
          : format(
              parse(selectedDate, VALUE_FORMAT, new Date()),
              DISPLAY_FORMAT
            )
      }

      return placeholder || this.$t('labels.not-set')
    },
    selectedDate: {
      get() {
        return this.value
      },
      set(newDate) {
        const formattedDate = newDate ? format(newDate, VALUE_FORMAT) : null

        this.$emit('input', formattedDate)
      },
    },
  },
  methods: {
    getDateDisabled(date) {
      return this.future && isPast(endOfDay(date))
    },
  },
}
</script>

<style lang="scss">
$default-color: $text-color-primary;
$primary-color: $misc-brand;

$today-color: $background-selected;

$calendar-hover-color: $background-selected;

$calendar-active-color: $background-primary;
$calendar-active-background-color: $background-selected;

$calendar-hover-background-color: $background-secondary;

$calendar-in-range-background-color: rgba($background-selected, 20%);

@import 'vue2-datepicker/scss/index';

.mx-icon-calendar {
  display: none;
}
</style>

<style lang="scss" scoped>
.day-range {
  width: 100%;

  &__input {
    width: 100%;

    // Matches input.  This is being messed with by the Date picker input itself.
    // TODO(TUCKER) - Figure out why the generated height is not equal to this height.
    height: 46.33px;
    background-color: $background-primary;

    padding: 12px 16px;

    border-radius: 8px;
    border: 1px solid $background-divider;

    @include control-shadow();

    &:hover,
    &:active,
    &:focus {
      background-color: $background-highlight;
      border-color: rgba($misc-brand, 0.05);
    }
  }
}
</style>
