<i18n locale="en">
{
  "alt": "Brand Logo"
}
</i18n>

<template>
  <div class="preview-logo">
    <img
      v-if="brandLogo"
      class="preview-logo__img"
      :class="[`preview-logo__img--${logoSize}`]"
      :src="brandLogo"
      :alt="$t('alt')"
    />
    <div
      v-else
      class="preview-logo__no-logo text--truncate"
      :class="[!disablePalette && `preview-logo__no-logo--${currentPalette}`]"
    >
      <PreviewTypographyLegacy
        :text="brandName"
        :color="
          currentPalette === 'light' && !disablePalette ? 'white' : 'black'
        "
        variant="buttonSmall"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import PreviewTypographyLegacy from './legacy/PreviewTypographyLegacy'
import { useMergedTheme } from './mixins/useMergedTheme'

export default {
  name: 'PreviewLogo',
  components: {
    PreviewTypographyLegacy,
  },
  mixins: [useMergedTheme],
  props: {
    /** Disables dark mode support */
    disablePalette: { type: Boolean, default: false },

    /**
     * Size of logo to render
     */
    logoSize: { type: String, default: 'sm' },
  },
  computed: {
    ...mapGetters({
      brandLogo: 'core/brand/active/assets/logo',
      brandName: 'core/brand/active/name',
    }),
    currentPalette() {
      const { mergedTheme } = this

      return mergedTheme.palette
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-logo {
  object-fit: contain;
  flex-shrink: 0;

  &__img {
    height: 48px;

    &--xs {
      height: 40px;
    }

    &--md {
      height: 64px;
    }

    &--lg {
      height: 80px;
    }
  }

  &__no-logo {
    padding: 2px 0;
    max-width: 232px;

    &--light {
      background-color: black;
    }

    &--dark {
      background-color: white;
    }
  }
}
</style>
