import { type ActionContext } from 'vuex'

import { ConfiguredClient } from '@/api'
import { type CoreState } from '@/core/store/types'

export const loadBrand = async (
  { commit, dispatch }: ActionContext<CoreState, unknown>,
  brandId: string
): Promise<void> => {
  const { brand, locations, theme } =
    await ConfiguredClient.dashboard.v2.brands.brand.core({
      params: {
        brandId,
      },
    })

  // Updates legacy brand system
  await dispatch('brand/setBrand', { brand, locations }, { root: true })
  await dispatch('locations/setAll', locations)

  commit('SET_BRANDS', [brand])
  commit('SET_THEME', theme)
}
