
{
  "export-applications": "Export Applications",
  "export-error": "Could not export applications",
  "new-job": "New Role",
  "views": {
    "applications": "Applications",
    "jobs": "Open Roles"
  }
}
