
{
  "actions": {
    "fix": "Update payment method"
  },
  "labels": {
    "title": "Payment failed for {locationName}",
    "body": "We haven't been able to process payment for this location.  Please update your payment method."
  }
}
