<i18n locale="en">
{
  "labels": {
    "more": "More"
  }
}
</i18n>

<template>
  <OwnStack class="navbar-menu-link-preview" align="center" spacing="6" row>
    <PreviewNavLink
      v-for="link of navLinkDisplay"
      :key="navItemKey(link)"
      :label="
        ['page', 'external'].includes(link.type)
          ? link?.params?.label
          : link?.params?.title
      "
    />
    <NavbarDropdownPreview
      v-if="navLinkDisplay.length !== navLinks.length"
      :label="$t('labels.more')"
    />
  </OwnStack>
</template>

<script>
import { PreviewNavLink } from '@/lib/builder'
import { OwnStack } from '@/ui'

import { navItemKey } from '../common/navItemKey'

import NavbarDropdownPreview from './NavbarDropdownPreview'

export default {
  name: 'NavbarMenuLinkPreview',
  components: {
    NavbarDropdownPreview,
    OwnStack,
    PreviewNavLink,
  },
  props: {
    /**
     * Active page to show
     */
    activePage: { type: String, default: undefined },
    navLinks: { type: Array, default: () => [] },
  },
  computed: {
    navLinkDisplay() {
      const { navLinks } = this

      return navLinks.slice(0, 4)
    },
  },
  methods: {
    navItemKey,
  },
}
</script>
