
{
  "labels": {
    "description": "This will make this page available for customers to visit on your website. You need to set the URL where people will find your page."
  },
  "errors": {
    "page-url-exists": "Page URL already exists"
  },
  "title": {
    "label": "Page Title"
  },
  "slug": {
    "label": "Page URL",
    "input-prefix": "https://{domain}/page/"
  }
}
