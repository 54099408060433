<template>
  <VApp v-bind="computedProps">
    <!--
      @slot use this to insert app content
      -->
    <slot></slot>
  </VApp>
</template>
<script>
import debounce from 'lodash/debounce'
import { VApp } from 'vuetify/lib'

import { FeatureFlags } from '@/plugins/featureFlags'
import globalStore from '@/store'

export default {
  name: 'AppObserver',
  components: { VApp },
  props: {
    /** The ID for the application */
    id: { type: String, required: true },
  },
  data() {
    return {
      resizeWatcher: null,
      windowHeight: null,
    }
  },
  computed: {
    computedProps() {
      const { id, windowHeight } = this

      const style = {
        '--window-height': windowHeight ? `${windowHeight}px` : '100%',
      }

      return {
        id,
        style,
      }
    },
  },
  created() {
    this.setMaxHeight()
    this.startResizeListener()
    this.updateFeatureFlagCtx()
  },
  destroyed() {
    window.removeEventListener('resize', this.debounceResizeListener)
  },
  methods: {
    // All of this should be removed when vuetify is gone.
    // Vuetify forces 100vh on the app which is the outerHeight not the innerHeight for some
    // mobile browsers
    debounceResizeListener: debounce(function () {
      this.setMaxHeight()
    }, 100),
    setMaxHeight() {
      this.windowHeight = window.innerHeight
      document.body.style.height = window.innerHeight + 'px'
    },
    startResizeListener() {
      window.addEventListener('resize', this.debounceResizeListener)
    },
    updateFeatureFlagCtx() {
      if (globalStore.getters['session/user']) {
        FeatureFlags.setContextField(
          'userId',
          globalStore.getters['session/user'].email
        )
      }
    },
  },
}
</script>

<!-- Following suppresses check for webstorm -->
<!--suppress CssUnresolvedCustomProperty -->
<style>
/* This is hacky but the only way to force vuetify... */

.v-application,
.v-application--wrap {
  min-height: var(--window-height) !important;
  height: var(--window-height) !important;
  max-height: var(--window-height) !important;
  overflow: hidden !important;
}
</style>
