
{
  "titles" : {
    "add": "Add Existing Page",
    "update": "Update Page"
  },
  "actions": {
    "update": "Update",
    "add": "Add"
  },
  "errors": {
    "load-pages": "Unable to load pages"
  }
}
