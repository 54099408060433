
{
  "done": "Done",
  "labels": {
    "description": "Description",
    "icon": "Icon",
    "title": "Title"
  },
  "placeholders": {
    "description": "Aa",
    "title": "Aa"
  }
}
