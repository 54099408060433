import { isAfter, parseISO } from 'date-fns'
import { mapGetters, mapActions } from 'vuex'

/**
 * Manages the current user's session and expiration
 * @mixin
 */
export default {
  name: 'SessionManager',
  data() {
    return {
      $_sm_interval: null,
    }
  },
  computed: {
    ...mapGetters({
      $_sm_expiresAt: 'session/expiresAt',
      $_sm_isAuthenticated: 'session/isAuthenticated',
    }),
  },
  watch: {
    $_sm_isAuthenticated(isAuthenticated) {
      if (!isAuthenticated) {
        this.$intercom.shutdown()
        this.$router.push({ name: 'auth.login' })
      }
    },
  },
  created() {
    if (this.$_sm_interval) clearInterval(this.$_sm_interval)
    this.$_sm_interval = setInterval(() => {
      const date = new Date()
      const { $_sm_expiresAt } = this
      if ($_sm_expiresAt && isAfter(date, parseISO($_sm_expiresAt)))
        this.$_sm_logout()
    }, 30 * 1000)
  },
  beforeDestroy() {
    if (this.$_sm_interval) clearInterval(this.$_sm_interval)
  },
  methods: {
    ...mapActions({
      $_sm_logout: 'session/logout',
    }),
  },
}
