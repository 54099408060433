<i18n locale="en">
{
  "applications":  "0 applications | {count} application | {count} applications",
  "delete-confirm": {
    "delete": "Delete Job",
    "go-back": "Go Back",
    "message": "Are you sure you want to delete this job?"
  },
  "errors": {
    "load": "Unable to load jobs"
  },
  "headers" : {
    "applications": "Applications",
    "delete": "Delete",
    "salary": "Salary Range",
    "title": "Title"
  },
  "loading": "Getting jobs...",
  "no-data": "No jobs",
  "no-query-results": "No jobs match that search",
  "salary": {
    "max": "Up to {amount}",
    "min": "Starting at {amount}"
  }
}
</i18n>

<template>
  <div>
    <OwnStack spacing="2">
      <OwnTable
        :columns="columns"
        :items="items"
        :query="requestParams.query"
        item-key="id"
        v-on="$listeners"
        @click="editJob($event.id)"
      >
        <template #loading>
          <DefaultComponentLoader v-if="isLoading" :message="$t('loading')" />
        </template>
        <template #card="{ item }">
          <OwnStack spacing="6" align="center" justify="between" row>
            <OwnStack spacing="2">
              <OwnType :text="item.title" color="primary" variant="subtitle" />
              <OwnType
                :text="$tc('applications', item.applications)"
                variant="paragraph-small"
              />
            </OwnStack>
            <button class="status-danger" @click.stop="onDelete(item.id)">
              <PhTrash size="24" />
            </button>
          </OwnStack>
        </template>
        <template #no-data>
          <DefaultEmptyState :message="$t('no-data')">
            <template #icon>
              <PhUsersFour size="24" />
            </template>
          </DefaultEmptyState>
        </template>
        <template #no-query-results>
          <DefaultEmptyState :message="$t('no-query-results')">
            <template #icon>
              <PhUsersFour size="24" />
            </template>
          </DefaultEmptyState>
        </template>

        <template #heading.title>
          <SearchInput v-model="requestParams.query" borderless />
        </template>

        <template #item.delete="{ item }">
          <OwnStack align="center" row class="h-full" justify="end">
            <button class="status-danger" @click.stop="onDelete(item.id)">
              <PhTrash size="24" />
            </button>
          </OwnStack>
        </template>
      </OwnTable>
      <TableFooter
        v-if="items.length"
        :page="requestParams.page"
        :limit="requestParams.limit"
        :page-count="requestParams.pageCount"
        :total-items="requestParams.totalItems"
        :loading="isLoading"
        @prev="requestParams.page = requestParams.page - 1"
        @next="requestParams.page = requestParams.page + 1"
      />
    </OwnStack>
    <DeleteConfirmationDialog
      v-model="showDeleteConfirmation"
      :delete-action-label="$t('delete-confirm.delete')"
      :message="$t('delete-confirm.message')"
      :title="$t('delete-confirm.delete')"
      @cancel="idToDelete = null"
      @delete="onDeleteConfirm"
    />
    <router-view />
  </div>
</template>

<script>
import { PhUsersFour, PhTrash } from 'phosphor-vue/dist/phosphor-vue.esm'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { DeleteConfirmationDialog } from '@/components/dialogs'
import { DefaultEmptyState } from '@/components/empty'
import { SearchInput } from '@/components/inputs'
import { DefaultComponentLoader } from '@/components/loaders'
import TableFooter from '@/components/table/TableFooter.vue'

import { CurrencyFormatter } from '@/format'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OwnStack, OwnTable, OwnType } from '@/ui'

export default {
  name: 'JobsTable',
  components: {
    DefaultComponentLoader,
    DefaultEmptyState,
    DeleteConfirmationDialog,
    OwnStack,
    OwnTable,
    OwnType,
    PhTrash,
    PhUsersFour,
    SearchInput,
    TableFooter,
  },
  mixins: [notify],
  data() {
    return {
      idToDelete: null,
      isLoading: false,
      items: [],
      requestParams: {
        limit: 7,
        page: 1,
        pageCount: undefined,
        query: '',
        totalItems: undefined,
      },
      showDeleteConfirmation: false,
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      activeLocationId: 'core/location/active/id',
    }),
    columns() {
      return [
        {
          body: {
            color: 'primary',
            font: 'subtitle',
          },
          key: 'title',
          minWidth: 150,
        },
        {
          body: {
            format: (salary) => {
              if (salary.min && !salary.max) {
                return this.$t('salary.min', {
                  amount: CurrencyFormatter(salary.min),
                })
              } else if (salary.max && !salary.min) {
                return this.$t('salary.max', {
                  amount: CurrencyFormatter(salary.max),
                })
              }
              return `${CurrencyFormatter(salary.min)} – ${CurrencyFormatter(
                salary.max
              )}`
            },
          },
          key: 'salary',
          width: 150,
        },
        {
          key: 'applications',
          width: 100,
        },
        {
          key: 'delete',
          width: 60,
        },
      ].map((header) => ({
        ...header,
        label: this.$t(`headers.${header.key}`),
      }))
    },
  },
  watch: {
    '$route.path': 'fetchJobs',
    async activeLocationId() {
      await this.fetchJobs()
    },
    requestParams: {
      deep: true,
      async handler() {
        await this.fetchJobs()
      },
    },
  },
  async created() {
    this.isLoading = true
    await this.fetchJobs()
    this.isLoading = false
  },
  methods: {
    editJob(jobId) {
      this.$router.push({
        name: 'location.careers.jobs.edit',
        params: { jobId },
      })
    },
    async fetchJobs() {
      const { activeBrandId, activeLocationId, requestParams } = this

      try {
        const response =
          await ConfiguredClient.dashboard.v1.brands.brand.careers.getJobs({
            params: {
              brandId: activeBrandId,
            },
            query: {
              limit: requestParams.limit,
              locationId: activeLocationId,
              page: requestParams.page,
              query:
                requestParams.query !== '' ? requestParams.query : undefined,
            },
          })

        this.items = response.results
        this.requestParams.pageCount = response.pageCount
        this.requestParams.totalItems = response.total
      } catch (err) {
        logError(err)
        return this.$notify(this.$t('errors.load'), 'error')
      }
    },
    onDelete(id) {
      this.idToDelete = id
      this.showDeleteConfirmation = true
    },
    async onDeleteConfirm() {
      const { idToDelete } = this

      await ConfiguredClient.careers.v1.jobs.job.deleteJob({
        params: { jobId: idToDelete },
      })
      await this.fetchJobs()
    },
  },
}
</script>
