<template>
  <OwnCard
    class="full-page-card"
    :class="[
      large && 'full-page-card--large',
      !!backgroundImage && 'full-page-card--has-img',
    ]"
    radius="16px"
    shadow
  >
    <OwnStack row>
      <OwnStack
        class="full-page-card__form-container"
        :class="[
          !!backgroundImage && 'full-page-card__form-container--has-img',
        ]"
        spacing="6"
      >
        <div>
          <PhCheckCircle
            v-if="showCheckbox"
            class="full-page-card__complete-icon"
            size="36"
          />
          <h2
            v-if="title"
            class="full-page-card__title text-heading-2"
            v-text="title"
          ></h2>
          <p
            v-if="subtitle"
            class="full-page-card__subtitle text-paragraph"
            v-text="subtitle"
          ></p>
        </div>

        <div v-if="$slots.default" class="full-page-card__form">
          <!--
          @slot default
        -->
          <slot></slot>
        </div>
      </OwnStack>
      <OwnStack
        v-if="backgroundImage"
        class="full-page-card__image-container"
        :style="{
          backgroundImage: `url('${backgroundImage}')`,
        }"
        justify="end"
      >
        <slot name="review"></slot>
      </OwnStack>
    </OwnStack>
  </OwnCard>
</template>
<script>
import { PhCheckCircle } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnCard, OwnStack } from '@/ui'

export default {
  name: 'FullPageCard',
  components: {
    OwnCard,
    OwnStack,
    PhCheckCircle,
  },
  props: {
    /**
     * Should show image on right
     */
    backgroundImage: { type: String, default: undefined },

    /**
     * Show the card in a `large` size
     */
    large: { type: Boolean, default: false },

    /**
     * Should show checkbox in header
     */
    showCheckbox: { type: Boolean, default: false },

    /**
     * subtitle
     */
    subtitle: { type: String, default: undefined },

    /**
     * title
     */
    title: { type: String, default: undefined },
  },
}
</script>
<style lang="scss" scoped>
.full-page-card {
  background-color: $background-primary;
  width: 372px;

  &--large {
    width: 512px;
  }

  &--has-img {
    width: 744px;

    @include respond-to('sm-and-down') {
      width: 100%;
    }
  }

  &__form-container {
    padding: 32px;

    border: 1px solid $bluetiful-100;
    border-radius: 16px;

    width: 100%;

    &--has-img {
      width: 50%;

      @include respond-to('sm-and-down') {
        width: 100%;
      }
    }
  }

  &__complete-icon {
    color: $mallard-400;
    margin-bottom: 12px;
  }

  &__image-container {
    width: 372px;
    padding: 32px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    @include respond-to('sm-and-down') {
      display: none;
    }
  }

  &__title {
    color: $text-color-primary;
  }

  &__subtitle {
    color: $slate-400;
  }
}
</style>
