import { PhBriefcase } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type WebsiteResource,
  type CareersSectionCreate,
  type CareersSection,
  type CareersSectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import CareersEditor from './editor/CareersEditor.vue'
import { createCareersData } from './methods/createCareersData'
import CareersPreview from './preview/CareersPreview.vue'

export const CareersBlockModule: BuilderBlockModule<
  CareersSection,
  CareersSectionCreate,
  CareersSectionUpdate,
  WebsiteResource
> = {
  canAdd: false,
  canDelete: false,
  canReorderDown: false,
  canReorderUp: false,
  createData: createCareersData,
  editor: CareersEditor,
  meta: [
    {
      icon: PhBriefcase,
      key: 'careers',
      label: i18n.t('website-builder.blocks.careers'),
    },
  ],
  methods: websiteBlockMethods,
  preview: CareersPreview,
}
