import { PbError } from '@owner/errors'
import { type RawLocation } from 'vue-router'
import { type ActionTree } from 'vuex'

import { router } from '@/router/router'
import { generateToken } from '@/utils'

import { type StripeState, type OAuthState } from './model'

const STRIPE_CLIENT_ID = import.meta.env.VITE_STRIPE_CLIENT_ID
const SQUARE_URL = import.meta.env.VITE_SQUARE_URL
const SQUARE_CLIENT_ID = import.meta.env.VITE_SQUARE_CLIENT_ID
const CLOVER_URL = import.meta.env.VITE_CLOVER_URL
const CLOVER_CLIENT_ID = import.meta.env.VITE_CLOVER_CLIENT_ID

const STRIPE_CONNECT_URL = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write`

export const actions: ActionTree<OAuthState, unknown> = {
  resetStripe: ({ commit }): void => {
    commit('RESET_STRIPE_STATE')
  },

  startCloverConnect: ({ rootGetters }): void => {
    const locationId = rootGetters['location/id']

    const cloverAuthorizationUrl = `${CLOVER_URL}/oauth/v2/authorize?client_id=${CLOVER_CLIENT_ID}&state=${locationId}`

    // eslint-disable-next-line xss/no-location-href-assign
    window.location.href = cloverAuthorizationUrl
  },

  startSquareConnect: ({ rootGetters }): void => {
    const locationId = rootGetters['location/id']
    const squareAuthorizationUrl = `${SQUARE_URL}/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=ORDERS_READ+ORDERS_WRITE+PAYMENTS_READ+PAYMENTS_WRITE&session=false&state=${locationId}`

    // eslint-disable-next-line xss/no-location-href-assign
    window.location.href = squareAuthorizationUrl
  },

  startStripeConnect: async (
    { rootGetters, commit },
    {
      locationId: givenLocationId,
      returnTo,
    }: { returnTo?: RawLocation; locationId?: string } = {}
  ): Promise<void> => {
    const brandId = rootGetters['core/brand/active/id']
    // We are allowing to pass the locationId because this action is also called
    // from the Payments onboarding flow, where the location can be selected from within the
    // onboarding flow, and is not set in the store.
    const locationId = givenLocationId ?? rootGetters['core/location/active/id']

    if (!(brandId && locationId))
      throw new PbError(
        'You cannot connect stripe from outside the location router'
      )

    const { href } = router.resolve({
      name: 'oauth.stripe',
    })

    const redirect = window.location.origin + href

    const state = generateToken()

    const stripeState: StripeState = {
      brandId,
      locationId,
      returnTo: null,
      state,
    }

    if (returnTo) {
      stripeState.returnTo = returnTo
    }

    commit('SET_STRIPE_STATE', stripeState)

    const stripeAuthorizationUrl = `${STRIPE_CONNECT_URL}&redirect_uri=${redirect}&client_id=${STRIPE_CLIENT_ID}&state=${state}`

    // eslint-disable-next-line xss/no-location-href-assign
    window.location.href = stripeAuthorizationUrl
  },
}
