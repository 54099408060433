
{
  "labels": {
    "title": "Title",
    "options": "Options",
    "required": "Required",
    "placeholder": "Placeholder",
    "multiline": "Allow Multiple Lines",
    "number": "Number"
  }
}
