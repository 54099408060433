
{
  "dates": {
    "today": "Today",
    "tomorrow": "Tomorrow"
  },
  "labels": {
    "not-set": "Not set"
  }
}
