import { type RouteConfig } from 'vue-router'

export const deliveryRouter: RouteConfig[] = [
  {
    component: async () => import('../views/DeliveryConfigurationView.vue'),
    meta: {
      title: 'restaurant.delivery',
    },
    name: 'brand.delivery',
    path: 'delivery',
  },
]
