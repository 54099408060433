<template>
  <OwnStack spacing="3" align="center" row>
    <OwnProgressSpinner size="24" />
    <OwnType :text="message" variant="subtitle" />
  </OwnStack>
</template>

<script>
import { OwnProgressSpinner, OwnStack, OwnType } from '@/ui'

export default {
  name: 'DefaultComponentLoader',
  components: {
    OwnProgressSpinner,
    OwnStack,
    OwnType,
  },
  props: {
    message: { type: String, required: true },
  },
}
</script>
