<i18n locale="en">
  {
    "header": "What our customers are saying",
    "subtitle": "Check out our most recent reviews!"
  }
</i18n>
<template>
  <div class="reviews-preview">
    <PreviewSheet
      class="reviews-preview__callout"
      background="secondary"
      radius="large"
    >
      <OwnStack justify="center" align="center" spacing="14">
        <OwnStack
          class="reviews-preview__headline"
          justify="center"
          align="center"
          spacing="4"
        >
          <PreviewTypography
            :text="$t('header')"
            align="center"
            variant="heading2"
          />
          <PreviewTypography
            :text="$t('subtitle')"
            align="center"
            color="secondary"
            variant="paragraphRegular"
          />
        </OwnStack>
        <div
          :class="`reviews-preview__reviews-grid reviews-preview__cols-${columns.length}`"
        >
          <OwnStack
            v-for="(reviewColumn, columnIndex) in columns"
            :key="`review-col-${columnIndex}`"
            spacing="4"
          >
            <ReviewCard
              v-for="(review, index) in reviewColumn"
              :key="`review-${index}`"
              :review="review"
            />
          </OwnStack>
        </div>
      </OwnStack>
    </PreviewSheet>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

import { PreviewSheet, PreviewTypography } from '@/lib/builder'
import { OwnStack } from '@/ui'

import type { ReviewsSection } from '../../../WebsiteBuilder.types'

import ReviewCard from './ReviewCard.vue'

type RenderedReview = ReviewsSection['params']['reviews'][number]

const props = defineProps<{
  content: ReviewsSection
}>()

const columns = computed(() => {
  const reviews = props.content.params.reviews
  const numberOfColumns = reviews.length > 3 ? 4 : 3
  // Initialize columns array with empty arrays
  const columns: RenderedReview[][] = new Array(numberOfColumns)
    .fill(null)
    .map(() => [])

  // If no reviews, return the empty columns
  if (reviews.length === 0) {
    return columns
  }

  let index = 0
  reviews.forEach((review) => {
    columns[index]?.push(review)
    index += 1
    if (index >= columns.length) {
      index = 0
    }
  })

  return columns
})
</script>
<style lang="scss" scoped>
.reviews-preview {
  padding: 8px;

  &__callout {
    padding: 72px 112px;
  }

  &__headline {
    max-width: 672px;
    text-align: center;
  }

  &__reviews-grid {
    display: grid;
    gap: 16px;
  }

  &__cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &__cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
</style>
