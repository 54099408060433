<i18n locale="en">
{
  "actions": {
    "delete": "Delete",
    "edit": "Edit"
  },
  "add-label": "Add Review",
  "reviews-limit-reached": "Reviews limit reached. Delete a review before you can add another."
}
</i18n>

<template>
  <EditPanel
    :is-editing="
      selectedIndex !== null &&
      currentBlockParams.reviews[selectedIndex] !== undefined
    "
  >
    <template #preview>
      <EditorLayout>
        <OwnStack class="reviews-editor" spacing="6">
          <Reorderable
            v-slot="{ item, index }"
            v-model="currentBlockParams.reviews"
            item-key="comment"
            class="space-y-3"
            @reorder="onReorder"
          >
            <OwnOption @click="onEdit(index)">
              <OwnStack spacing="4" row>
                <DragHandle />
                <OwnType
                  class="flex-1"
                  color="primary"
                  variant="subtitle"
                  :text="item.name"
                />
                <OwnActionMenu
                  :actions="actions"
                  @action="onAction($event, index)"
                >
                  <template #trigger>
                    <PhDotsThreeCircle class="text-color-secondary" size="24" />
                  </template>
                </OwnActionMenu>
              </OwnStack>
            </OwnOption>
          </Reorderable>

          <OwnStack spacing="2">
            <OwnButton
              :disabled="currentBlockParams.reviews.length >= maxReviews"
              :text="$t('add-label')"
              @click="onNew"
            />
            <p
              v-if="currentBlockParams.reviews.length >= maxReviews"
              class="text-small text-color-secondary"
              v-text="$t('reviews-limit-reached')"
            ></p>
          </OwnStack>
        </OwnStack>

        <template #actions>
          <slot></slot>
        </template>
      </EditorLayout>
    </template>
    <template #edit>
      <ReviewDetailEditor
        class="reviews-editor__child"
        :review="currentBlockParams.reviews[selectedIndex]"
        @back="onBack"
        @update="onUpdate"
      />
    </template>
  </EditPanel>
</template>

<script>
import {
  PhDotsThreeCircle,
  PhPencilSimpleLine,
  PhTrash,
} from 'phosphor-vue/dist/phosphor-vue.esm'
import { mapGetters } from 'vuex'

import { EditPanel, EditorLayout } from '@/lib/builder'
import {
  OwnActionMenu,
  OwnButton,
  OwnStack,
  OwnOption,
  OwnType,
  DragHandle,
  Reorderable,
} from '@/ui'

import { ReviewsCommands } from '../commands/ReviewsCommands'

import ReviewDetailEditor from './ReviewDetailEditor'

export default {
  name: 'ReviewsEditor',
  components: {
    DragHandle,
    EditPanel,
    EditorLayout,
    OwnActionMenu,
    OwnButton,
    OwnOption,
    OwnStack,
    OwnType,
    PhDotsThreeCircle,
    Reorderable,
    ReviewDetailEditor,
  },
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  data() {
    return {
      selectedIndex: null,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    actions() {
      return [
        {
          icon: PhPencilSimpleLine,
          value: 'edit',
        },
        {
          color: 'danger',
          icon: PhTrash,
          value: 'delete',
        },
      ].map((action) => ({
        ...action,
        label: this.$t(`actions.${action.value}`),
      }))
    },
    currentBlock() {
      const { id } = this

      return this.blockById(id)
    },
    currentBlockParams() {
      const { currentBlock } = this

      return currentBlock?.params
    },
    maxReviews() {
      return 12
    },
  },
  methods: {
    async onAction(action, index) {
      switch (action) {
        case 'delete':
          await ReviewsCommands.deleteReview(this.currentBlock, index)
          break
        case 'edit':
          this.onEdit(index)
          break
      }
    },
    onBack() {
      this.selectedIndex = null
    },
    onEdit(index) {
      this.selectedIndex = index
    },
    async onNew() {
      this.selectedIndex = await ReviewsCommands.createReview(this.currentBlock)
    },
    async onReorder({ newIndex, oldIndex }) {
      await ReviewsCommands.reorderReview(this.currentBlock, oldIndex, newIndex)
    },
    async onUpdate(updatedReview) {
      await ReviewsCommands.updateReview(
        this.currentBlock,
        this.selectedIndex,
        updatedReview
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.reviews-editor {
  &__child {
    width: calc(100% - 8px);

    @include flyout-shadow();
  }
}
</style>
