<template>
  <OwnStack class="form-preview" spacing="4">
    <OwnStack class="form-preview__header">
      <FormHeaderPreview v-if="form" :populated-data="form" />
    </OwnStack>
    <OwnStack v-if="sections" class="form-preview__sections">
      <SectionPreview
        v-for="section in sections"
        :key="section.id"
        :section="section"
      />
      <FormFooterPreview :populated-data="form" />
    </OwnStack>
  </OwnStack>
</template>

<script>
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'

import { OwnStack } from '@/ui'

import FormFooterPreview from '../../../builder/components/preview/footer/preview/FormFooterPreview.vue'
import FormHeaderPreview from '../../../builder/components/preview/heading/preview/FormHeaderPreview.vue'

import SectionPreview from './SectionPreview.vue'

export default {
  name: 'FormPreview',
  components: {
    FormFooterPreview,
    FormHeaderPreview,
    OwnStack,
    SectionPreview,
  },
  props: {
    /** Section */
    id: { type: String, required: true },
  },
  data() {
    return {
      form: null,
      loadingForm: false,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    formId() {
      const { id } = this

      return this.blockById(id)?.params?.formId
    },
    sections() {
      const { form } = this

      return form ? form.sections : null
    },
  },
  watch: {
    formId() {
      this.loadForm()
    },
  },
  created() {
    this.loadForm()
  },
  methods: {
    async loadForm() {
      const { form, formId, loadingForm } = this

      if (!loadingForm && formId && (!form || form.id !== formId)) {
        this.loadingForm = true
        this.form = await ConfiguredClient.forms.v1.forms.form.getPopulatedForm(
          {
            params: { formId },
          }
        )
        this.loadingForm = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.form-preview {
  padding: 160px 120px;

  @include respond-to('md-and-up') {
    flex-direction: row;

    &__header {
      width: 50%;
    }
    &__sections {
      width: 50%;
    }
  }
}
</style>
