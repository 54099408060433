<template>
  <OwnStack
    class="page-header"
    :spacing="isDesktop ? 14 : 6"
    justify="between"
    :row="isDesktop"
  >
    <OwnStack spacing="2">
      <OwnType v-if="title" :text="title" variant="heading" />
      <slot name="subtitle">
        <OwnType v-if="subtitle" :text="subtitle" variant="paragraph" />
      </slot>
    </OwnStack>

    <div v-if="$slots['actions']" class="page-header__actions">
      <OwnStack spacing="4" :row="isDesktop">
        <slot name="actions"></slot>
      </OwnStack>
    </div>
  </OwnStack>
</template>

<script>
import breakpoints from '@/mixins/breakpoints'
import { OwnStack, OwnType } from '@/ui'

export default {
  name: 'PageHeader',
  components: {
    OwnStack,
    OwnType,
  },
  mixins: [breakpoints],
  props: {
    /**
     * Subtitle under main title
     */
    subtitle: { type: String, default: undefined },

    /**
     * Main title
     */
    title: { type: String, default: undefined },
  },
  computed: {
    isDesktop() {
      return this.$breakpoints.mdAndUp
    },
  },
}
</script>

<style lang="scss" scoped>
.page-header {
  &__actions {
    flex-shrink: 0;
  }
}
</style>
