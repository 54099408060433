export const setupEn = {
  labels: {
    remaining: 'Complete | {n} minute remaining | {n} minutes remaining',
  },
  steps: {
    'add-locations': 'Add Locations',
    address: 'Address',
    confirm: 'Confirmation',
    connect: 'Connect',
    import: 'Import',
    introduction: 'Introduction',
    'match-csv': 'Match Customer Data',
    name: 'Name',
    phone: 'Phone',
    scan: 'Current Listings',
    subscribe: 'Subscribe',
    summary: 'Summary',
    undefined: 'Introduction',
    'upload-csv': 'Upload CSV',
    'verify-csv': 'Verify CSV',
  },
  tasks: {
    'customer-import': 'Import Customers',
    domain: 'Connect Domain',
    listings: 'Setup Listings',
    menu: 'Set Up Menu',
    'new-location': 'Setup Locations',
    payments: 'Payouts',
    undefined: 'Unknown task',
  },
}
