const getStoreValue = (state, pathArray) => {
  if (pathArray.length === 1) {
    return state[pathArray[0]]
  } else {
    if (!state[pathArray[0]]) {
      throw new Error(`Key ${pathArray[0]} not found on store`)
    }

    return getStoreValue(state[pathArray[0]], pathArray.slice(1))
  }
}

export { getStoreValue }
