
{
  "confirm-send": "Are you sure you want to send this campaign now?",
  "titles": {
    "schedule": "Change Schedule",
    "subject": "Update Subject",
    "send": "Send"
  },
  "errors": {
    "update": "There was a problem updating the campaign",
    "schedule": "There was a problem scheduling this campaign"
  }
}
