import { type RouteConfig } from 'vue-router'

export const hoursRouter: RouteConfig[] = [
  {
    component: async () => import('../views/HoursHomeView.vue'),
    meta: {
      title: 'restaurant.hours',
    },
    name: 'brand.hours',
    path: 'hours',
  },
]
