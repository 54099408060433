import { PhTag } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type CampaignResource,
  type CouponSection,
  type CouponSectionCreate,
  type CouponSectionUpdate,
} from '../../types'
import { emailBlockMethods } from '../common/methods'

import CouponEditor from './editor/CouponEditor.vue'
import { createCouponData } from './methods/createCouponData'
import CouponPreview from './preview/CouponPreview.vue'

export const CouponBlockModule: BuilderBlockModule<
  CouponSection,
  CouponSectionCreate,
  CouponSectionUpdate,
  CampaignResource
> = {
  canAdd: ({ resource: { locationId } }) => !!locationId,
  createData: createCouponData,
  editor: CouponEditor,
  meta: [
    {
      icon: PhTag,
      key: 'coupon',
      label: i18n.t('email-builder.blocks.coupon'),
    },
  ],
  methods: emailBlockMethods,
  preview: CouponPreview,
}
