
{
  "titles" : {
    "add": "Add External Link",
    "update": "Update Link"
  },
  "actions": {
    "update": "Update",
    "add": "Add"
  },
  "label": {
    "label": "Link Title",
    "placeholder": "Aa"
  },
  "url": {
    "label": "Link URL",
    "placeholder": "https://example.com"
  }
}
