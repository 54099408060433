<i18n locale="en">
{
  "labels": {
    "confirm": "Publish",
    "publishing": "Publishing",
    "success": "Form Published",
    "error": "Uh oh..."
  },
  "content": {
    "confirm": "This will update this form everywhere it is used.",
    "success": "Congrats! Your form is live and ready to add to your website."
  },
  "actions": {
    "end-edit": "End Editing",
    "publish": "Publish",
    "to-website": "Add to Website",
    "check": "Go back and check"
  }
}
</i18n>

<template>
  <OwnDialog :value="show" @cancel="$emit('close-dialog')">
    <template #full-dialog>
      <PublishInProgress v-if="publishState === 'publishing'" />
    </template>

    <template #heading>
      <OwnDialogHeading
        :title="$t(`labels.${publishState}`)"
        @cancel="$emit('close-dialog')"
      >
        <template #icon-left>
          <PhCheckCircle
            v-if="publishState === 'success'"
            class="status-success"
            size="32"
          />
          <PhWarning
            v-if="publishState === 'error'"
            class="status-warning"
            size="32"
          />
        </template>
      </OwnDialogHeading>
    </template>

    <OwnDialogContent scroll>
      <OwnType
        v-if="publishState === 'confirm'"
        :text="$t('content.confirm')"
      />

      <OwnType
        v-if="publishState === 'success'"
        :text="$t('content.success')"
      />

      <PublishErrorContent
        v-if="publishState === 'error'"
        :errors="errors"
        :block-modules="blockModules"
        :current-blocks="currentBlocks"
      />
    </OwnDialogContent>

    <template #footer>
      <OwnDialogFooter>
        <template v-if="publishState === 'confirm'">
          <OwnButton
            :text="$t('actions.publish')"
            primary
            @click="$emit('confirm-publish')"
          >
            <template #icon-right>
              <PhArrowCircleUp size="24" />
            </template>
          </OwnButton>
        </template>

        <template v-if="publishState === 'success'">
          <OwnButton
            :text="$t('actions.end-edit')"
            @click="$emit('close-page')"
          />
          <OwnButton
            :text="$t('actions.to-website')"
            primary
            @click="onToWebsite"
          />
        </template>

        <template v-if="publishState === 'error'">
          <OwnButton
            :text="$t('actions.check')"
            primary
            @click="$emit('close-dialog')"
          />
        </template>
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script>
import {
  PhArrowCircleUp,
  PhCheckCircle,
  PhWarning,
} from 'phosphor-vue/dist/phosphor-vue.esm'

import { PublishErrorContent, PublishInProgress } from '@/lib/builder'
import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnType,
} from '@/ui'

export default {
  name: 'FormPublishDialog',
  components: {
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnType,
    PhArrowCircleUp,
    PhCheckCircle,
    PhWarning,
    PublishErrorContent,
    PublishInProgress,
  },
  props: {
    blockModules: { type: Array, default: () => [] },
    currentBlocks: { type: Array, default: () => [] },
    currentResource: { type: Object, default: undefined },
    errors: { type: Array, default: () => [] },
    publishState: { type: String, required: true },
    show: { type: Boolean, default: false },
  },
  methods: {
    onToWebsite() {
      this.$router.push({
        name: 'brand.website.pages.home',
      })
    },
  },
}
</script>
