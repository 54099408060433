<i18n locale="en">
{
  "close": "Okay",
"content": "This can take a little while. Keep an eye on your inbox, we will email you when your approval status changes.",
  "heading": "Setting Up Text Message Marketing"
}
</i18n>

<template>
  <OwnDialog v-model="isVisible">
    <template #heading="{ close }">
      <OwnDialogHeading :title="$t('heading')" @cancel="close">
        <template #icon-left>
          <PhHourglassHigh size="24" />
        </template>
      </OwnDialogHeading>
    </template>

    <OwnDialogContent scroll>
      <OwnType color="primary" variant="paragraph" :text="$t('content')" />
    </OwnDialogContent>

    <template #footer="{ close }">
      <OwnDialogFooter>
        <OwnButton :text="$t('close')" primary @click="close" />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { PhHourglassHigh } from 'phosphor-vue/dist/phosphor-vue.esm'

import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnType,
} from '@/ui'

const props = defineProps<{
  value: boolean
}>()
const isVisible = useVModel(props, 'value', undefined, { eventName: 'input' })
</script>
