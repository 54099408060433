import merge from 'lodash/merge'

import { type TemplateData } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type FormCreate,
  type FormSectionCreate,
} from '../../types/FormBuilder.types'
import { FormattedInputBlockModule } from '../blocks/formatted-input'
import { TextInputBlockModule } from '../blocks/text-input'

export const contactTemplate = async (
  brandId: string
): Promise<TemplateData<FormCreate, FormSectionCreate>> => ({
  blocks: [
    FormattedInputBlockModule.createData({ brandId, type: 'name' }),
    FormattedInputBlockModule.createData({ brandId, type: 'email' }),
    FormattedInputBlockModule.createData({ brandId, type: 'phone' }),
    merge(TextInputBlockModule.createData({ brandId, type: 'text-field' }), {
      params: {
        label: i18n.t('form-builder.templates.contact.message'),
        placeholder: null,
        required: true,
        variant: 'multiline',
      },
    }),
  ],
  resource: {
    brandId,
    description: i18n.t('form-builder.templates.contact.description'),
    submit: null,
    title: i18n.t('form-builder.templates.contact.title'),
    type: 'contact',
  },
})
