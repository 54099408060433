<i18n locale="en">
{
  "heading": {
    "title": "Subject"
  },
  "footer": {
    "titles": {
      "scheduled": "Scheduled by You",
      "sent": "Sent by You"
    },
    "subtitle": "This campaign was designed by you."
  }
}
</i18n>

<template>
  <div v-if="!!campaign">
    <OwnStack spacing="6" class="otc-sidebar pa-10">
      <!-- Controls Section -->
      <template v-if="canEdit">
        <CampaignControls
          v-model="action"
          :execute-at="executeAt"
          :campaign="campaign.id"
          :transport="campaign.transport ?? undefined"
          @show-dialog="showDialog = true"
        />
        <OwnRule type="dashed" />
      </template>

      <!-- Subject Section-->
      <template v-if="campaign.transport === 'email'">
        <TitleBlock
          :title="$t('heading.title')"
          :subtitle="campaign.name"
          subtitle-color="primary"
        />
        <OwnRule type="dashed" />
      </template>

      <!-- Analytics Section-->
      <template v-if="!canEdit">
        <CampaignAnalytics
          :campaign="campaign.id"
          :transport="campaign.transport ?? undefined"
        />
        <OwnRule type="dashed" />
      </template>

      <TitleBlock
        :title="$t(`footer.titles.${!canEdit ? 'sent' : 'scheduled'}`)"
        :subtitle="$t('footer.subtitle')"
        subtitle-color="primary"
      />
    </OwnStack>

    <component
      :is="dialog"
      :action="action"
      :methods="methods"
      :show="showDialog"
      :store-module="storeModule"
      @close-dialog="showDialog = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { type Component } from 'vue'

import { TitleBlock } from '@/components/typography'
import { BuilderMethods } from '@/lib/builder'
import { useMappedGetter } from '@/store'
import { OwnRule, OwnStack } from '@/ui'

import {
  BaseCampaignResource,
  CampaignBlock,
  MarketingStoreModule,
} from '../../../types'

import CampaignAnalytics from './CampaignAnalytics.vue'
import CampaignControls from './CampaignControls.vue'

interface OTCSidebarProps {
  /**
   * Dialog component for carrying out some of the actions available in this component (e.g. editing subject, editing schedule).
   */
  dialog: Component
  storeModule: MarketingStoreModule
  methods: BuilderMethods<BaseCampaignResource, unknown, unknown, CampaignBlock>
}

const props = defineProps<OTCSidebarProps>()

const action = ref(undefined)
const showDialog = ref(false)

const campaign = useMappedGetter<BaseCampaignResource>(
  `${props.storeModule}/currentResource`
)

const canEdit = computed(
  () => !['active', 'queued', 'completed'].includes(campaign.value.status)
)
const executeAt = computed(() =>
  campaign.value.executeAt ? new Date(campaign.value.executeAt) : undefined
)
</script>
