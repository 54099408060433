<i18n locale="en">
{
  "options": {
    "sm": "Small",
    "md": "Medium",
    "lg": "Large"
  },
  "label": "Logo Size"
}
</i18n>

<template>
  <OwnInputContainer :label="$t('label')">
    <OwnSegmentedControl
      :options="options"
      :value="logoSize"
      @input="onSizeChange"
    />
  </OwnInputContainer>
</template>

<script>
import set from 'lodash/set'
import { mapActions, mapGetters } from 'vuex'

import { OwnInputContainer, OwnSegmentedControl } from '@/ui'

export default {
  name: 'LogoSizeEditor',
  components: { OwnInputContainer, OwnSegmentedControl },
  computed: {
    ...mapGetters({
      currentResource: 'websiteBuilder/currentResource',
    }),
    logoSize() {
      const { currentResource } = this

      return currentResource?.funnel?.navigation?.logoSize
    },
    options() {
      return [
        {
          value: 'sm',
        },
        {
          value: 'md',
        },
        {
          value: 'lg',
        },
      ].map((option) => ({
        ...option,
        label: this.$t(`options.${option.value}`),
      }))
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    onSizeChange(newSize) {
      this.updateResource(set({}, 'funnel.navigation.logoSize', newSize))
    },
  },
}
</script>
