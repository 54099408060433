import { ConfiguredClient } from '@/api'

import { type CampaignResourceCreate, type CampaignResource } from '../types'

export const createResourceHandler = async ({
  brandId,
  locationId,
  name,
  subject,
  sections,
}: CampaignResourceCreate): Promise<CampaignResource> => {
  const campaign = await ConfiguredClient.marketing.v1.campaigns.createCampaign(
    {
      body: {
        brandId,
        locationId,
        name,
        transport: 'email',
        type: 'one-time',
      },
    }
  )

  const { id: emailTemplateId } =
    await ConfiguredClient.notifications.v1.templates.emails.createEmailTemplate(
      {
        body: {
          brandId,
          locationId,
          name,
          subject,
        },
      }
    )

  await ConfiguredClient.notifications.v1.templates.emails.email.updateEmailTemplate(
    {
      body: { sections },
      params: { emailTemplateId },
    }
  )

  await ConfiguredClient.marketing.v1.campaigns.campaign.createBlock({
    body: {
      name: null,
      params: {
        expiresAt: null,
        next: null,
        params: {
          templateId: emailTemplateId,
        },
        type: 'send-email',
      },
      type: 'action',
    },
    params: { campaignId: campaign.id },
  })

  return {
    ...campaign,
    emailTemplateId,
    executeAt: campaign.executeAt ? new Date(campaign.executeAt) : null,
    sections,
    subject,
  }
}
