import { isDefined } from '@vueuse/core'
import { type RawLocation, type Route } from 'vue-router'

export const toRawLocation = (route: Route): RawLocation => {
  const { name, params, query } = route

  if (!isDefined(name))
    throw new Error('unable to transform into unknown location')

  return {
    name,
    params,
    query,
  }
}
