<i18n locale="en">
  {
    "labels": {
      "label": "Title",
      "link-to": "Link To",
      "description": "Select a link, page, or action the button opens when clicked."
    },
    "options": {
      "external": "URL",
      "page": "Page",
      "action": "Action"
    }
  }
  </i18n>

<template>
  <OwnStack spacing="4">
    <OwnInputContainer
      :label="$t('labels.label')"
      :errors="label.status.errors"
    >
      <OwnInput v-model="label.value" />
    </OwnInputContainer>

    <OwnRule type="dashed" />

    <OwnType :text="$t('labels.description')" />

    <OwnInputContainer :label="$t('labels.link-to')">
      <OwnSegmentedControl v-model="selectedValue" :options="options" />
    </OwnInputContainer>

    <UrlForm
      v-if="selectedValue === 'external'"
      :action-config="value"
      @update="
        onFormControlUpdate($event.controlName, $event.value, $event.status)
      "
    />

    <PageForm
      v-if="selectedValue === 'page'"
      :action-config="value"
      @update="onActionUpdate"
    />

    <ActionForm
      v-if="selectedValue === 'action'"
      :action-config="value"
      @update="onActionUpdate"
    />
  </OwnStack>
</template>

<script>
import merge from 'lodash/merge'

import { FormBuilderModelSync, Validators } from '@/forms'
import {
  OwnInput,
  OwnInputContainer,
  OwnRule,
  OwnSegmentedControl,
  OwnStack,
  OwnType,
} from '@/ui'

import ActionForm from './action-forms/ActionForm.vue'
import PageForm from './action-forms/PageForm.vue'
import UrlForm from './action-forms/UrlForm.vue'

export default {
  name: 'ContentActionEditor',
  components: {
    ActionForm,
    OwnInput,
    OwnInputContainer,
    OwnRule,
    OwnSegmentedControl,
    OwnStack,
    OwnType,
    PageForm,
    UrlForm,
  },
  mixins: [
    FormBuilderModelSync({
      label: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required, Validators.maxLength(50)],
      },
    }),
  ],
  data() {
    return {
      options: ['external', 'page', 'action'].map((value) => ({
        label: this.$t(`options.${value}`),
        value,
      })),
      selectedValue: 'external',
    }
  },
  created() {
    const { value } = this
    this.selectedValue = value.type ?? 'external'

    this.setInitialFormValues({
      url: value.params.label,
    })
  },
  methods: {
    onActionUpdate(update) {
      const { formValues } = this

      const mergedUpdate = merge(update, {
        params: { label: formValues.label },
      })

      this.$emit('update', mergedUpdate)
    },
    onFormControlUpdate(controlName, newValue, status) {
      const { value } = this

      if (controlName === 'label' && status.valid) {
        const mergedUpdate = merge(value, {
          params: { label: newValue },
        })

        this.$emit('update', mergedUpdate)
      }

      if (controlName === 'url' && status.valid) {
        this.onActionUpdate({
          params: { url: newValue },
          type: 'external',
        })
      }
    },
  },
}
</script>
