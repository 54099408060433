<template>
  <div class="email-builder-preview-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EmailBuilderPreviewWrapper',
}
</script>

<style lang="scss" scoped>
.email-builder-preview-wrapper {
  margin: 0 auto;
  max-width: 600px;
}
</style>
