import Vue from 'vue'

import { updateMutationFactory } from '../../utils/updateMutationFactory'

const updateBrandFactory = updateMutationFactory('brands')

export default {
  SET_BRAND: (state, targetBrand) => {
    if (state.brands.length === 0) {
      Vue.set(state, 'brands', [targetBrand])
    } else {
      const targetIndex = state.brands.findIndex(
        (brand) => brand.id === targetBrand.id
      )

      if (targetIndex === -1) {
        Vue.set(state.brands, state.brands.length, targetBrand)
      } else {
        Vue.set(state.brands, targetIndex, targetBrand)
      }
    }
  },
  SET_BRAND_DOMAIN: (state, { brandId, domain }) => {
    const targetIndex = state.brands.findIndex((brand) => brand.id === brandId)

    if (targetIndex === -1) {
      throw new Error('Attempting to set domain for brand not in store.')
    } else {
      Vue.set(state.brands[targetIndex], 'domain', domain)
    }
  },
  SET_DEFAULTS: (state, defaultState) => {
    for (const key of Object.keys(defaultState)) {
      state[key] = defaultState[key]
    }
  },
  UPDATE_BRAND_ASSETS: updateBrandFactory('assets'),
  UPDATE_BRAND_LINKS: updateBrandFactory('links'),
  UPDATE_BRAND_NAME: updateBrandFactory('name'),
  UPDATE_BRAND_PRICE_RANGE: updateBrandFactory('priceRange'),
}
