
{
  "actions": {
    "replace": "Replace",
    "remove": "Remove",
    "load": "Upload an image",
    "select": "Select Image"
  },
  "context": {
    "editing": "Editing {label}"
  },
  "labels": {
    "preview": "Image Preview"
  }
}
