import { type PreviewStaticModule } from '@/lib/builder'

import FormHeaderEditor from './editor/FormHeaderEditor.vue'

import FormBuilderPreview from './preview/FormHeaderPreview.vue'

export const FormHeadingStaticModule: PreviewStaticModule = {
  editor: FormHeaderEditor,
  preview: FormBuilderPreview,
}
