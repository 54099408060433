<i18n locale="en">
{
  "text": {
    "label": "Message",
    "placeholder": "Aa"
  }
}
</i18n>

<template>
  <OwnStack spacing="8" class="text-editor">
    <OwnInputContainer
      :label="$t('text.label')"
      :errors="text.status.errors"
      :max-chars="MAX_TEXT_LENGTH"
      :value="text.value"
    >
      <OwnTextarea
        v-model="text.value"
        :placeholder="$t('text.placeholder')"
        rows="9"
      />
    </OwnInputContainer>
  </OwnStack>
</template>

<script>
import { mapGetters } from 'vuex'

import { FormBuilder, Validators } from '@/forms'
import { OwnInputContainer, OwnStack, OwnTextarea } from '@/ui'

import { TextCommands } from '../commands/TextCommands'

const MAX_TEXT_LENGTH = 300

export default {
  name: 'TextEditor',
  components: {
    OwnInputContainer,
    OwnStack,
    OwnTextarea,
  },
  mixins: [
    FormBuilder({
      text: {
        debounce: 500,
        validateOnBlur: true,
        validators: [
          Validators.required,
          Validators.maxLength(MAX_TEXT_LENGTH),
        ],
      },
    }),
  ],
  props: {
    /** ID of the block */
    id: { type: String, required: true },
  },
  data() {
    return {
      MAX_TEXT_LENGTH,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'textBuilder/blockById',
    }),
    currentBlock() {
      const { id } = this

      return this.blockById(id)
    },
  },
  created() {
    const { currentBlock } = this

    this.text.value = currentBlock?.data?.text ?? ''
  },
  methods: {
    async onFormControlUpdate(controlName, update) {
      if (!this.isFormValid) return

      return await TextCommands.updateBlock({
        targetId: this.id,
        update: { data: { [controlName]: update } },
      })
    },
  },
}
</script>
