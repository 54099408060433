import { type ErrorObject } from '@vuelidate/core'
import { type Ref } from 'vue'

export const mapErrorMessage = (
  vuelidateErrors: ErrorObject[] | undefined
): Array<string | Ref<string>> => {
  if (!vuelidateErrors) return []

  return vuelidateErrors.map((err) => err.$message)
}
