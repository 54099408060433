
{
  "actions": {
    "add-existing-job": "Add Existing Job",
    "add-new-job": "Add New Job"
  },
  "errors": {
    "load": "Unable to load job titles"
  },
  "labels": {
    "heading": "Title",
    "jobs": "Jobs",
    "subheading": "Description"
  },
  "placeholders": {
    "heading": "Aa",
    "subheading": "Aa"
  }
}
