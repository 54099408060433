<template>
  <div class="website-builder-preview-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WebsiteBuilderPreviewWrapper',
}
</script>

<style lang="scss" scoped>
.website-builder-preview-wrapper {
  margin: 0 auto;
  max-width: 900px;
}
</style>
