import { type RouteConfig } from 'vue-router'

export const homeRouter: RouteConfig[] = [
  {
    component: async () => import('../views/HomeView.vue'),
    meta: {
      title: 'home',
    },
    name: 'brand.home',
    path: 'home',
  },
]
