import { type BuilderModuleConfig } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import { locationAnalytics } from '@/utils'

import { WebsiteBuilderBlocks } from '../blocks'
import AnnouncementEditor from '../components/announcements/AnnouncementEditor.vue'
import AnnouncementRightActions from '../components/announcements/AnnouncementRightActions.vue'
import { AnnouncementsHeader } from '../components/announcements/preview'
import CreateTemplateDialog from '../components/create-template-dialog/CreateTemplateDialog.vue'
import { WebsiteFooterStaticModule } from '../components/preview/footer'
import { WebsiteHeadingStaticModule } from '../components/preview/heading'
import StyleRightActions from '../components/style/right-action/StyleRightActions.vue'
import StyleEditor from '../components/style/sidebar/StyleEditor.vue'
import WebsitePublishDialog from '../components/website-publish-dialog/WebsitePublishDialog.vue'
import WebsiteBuilderPreviewWrapper from '../components/WebsiteBuilderPreviewWrapper.vue'
import { announcementFetchDataHandler } from '../methods/announcementFetchDataHandler'

import { createResourceHandler } from '../methods/createResourceHandler'
import { fetchPageData } from '../methods/fetchPageData'
import { publishStyleChanges } from '../methods/publishStyleChanges'
import { styleFetchDataHandler } from '../methods/styleFetchDataHandler'
import { updateResourceHandler } from '../methods/updateResourceHandler'

import websiteBuilderTemplates from '../templates'
import {
  type WebsiteResource,
  type WebsiteResourceCreate,
  type WebsiteResourceUpdate,
  type WebsiteSection,
  type WebsiteSectionCreate,
  type WebsiteSectionUpdate,
} from '../WebsiteBuilder.types'

export const websiteBuilderConfig: BuilderModuleConfig<
  WebsiteResource,
  WebsiteResourceCreate,
  WebsiteResourceUpdate,
  WebsiteSection,
  WebsiteSectionCreate,
  WebsiteSectionUpdate
> = {
  blocks: WebsiteBuilderBlocks,
  components: {
    preview: {
      footer: WebsiteFooterStaticModule,
      heading: WebsiteHeadingStaticModule,
      wrapper: WebsiteBuilderPreviewWrapper,
    },
    publishDialog: WebsitePublishDialog,
  },
  customPages: [
    {
      analyticsEvents: [
        {
          handler: (_resource, brand) => ({
            ...locationAnalytics(brand),
          }),
          label: 'Change Website Style Started',
          type: 'start',
        },
        {
          handler: (_resource, brand) => ({
            ...locationAnalytics(brand),
          }),
          label: 'Change Website Style Successful',
          type: 'update',
        },
        {
          handler: (_resource, brand) => ({
            ...locationAnalytics(brand),
          }),
          label: 'Change Website Style Failed',
          type: 'failed',
        },
      ],
      asRoot: true,
      brandThemePath: 'theme',
      components: {
        rightActions: StyleRightActions,
        sidebar: StyleEditor,
      },
      methods: {
        fetchDataHandler: styleFetchDataHandler,
        publish: publishStyleChanges,
      },
      pageTitle: i18n.t('website-builder.page-title.style'),
      perResource: false,
      slug: 'style',
    },
    {
      analyticsEvents: [
        {
          handler: (resource, brand) => ({
            ...locationAnalytics(brand),
            id: resource.id,
          }),
          label: 'Announcement Edit Started',
          type: 'start',
        },
        {
          handler: (resource, brand) => ({
            ...locationAnalytics(brand),
            id: resource.id,
          }),
          label: 'Announcement Edit Successful',
          type: 'update',
        },
        {
          handler: (resource, brand) => ({
            ...locationAnalytics(brand),
            id: resource.id,
          }),
          label: 'Announcement Edit Failed',
          type: 'failed',
        },
      ],
      asRoot: true,
      components: {
        rightActions: AnnouncementRightActions,
        sidebar: AnnouncementEditor,
      },
      methods: {
        fetchDataHandler: announcementFetchDataHandler,
        publish: publishStyleChanges,
      },
      pageTitle: i18n.t('website-builder.page-title.announcements'),
      perResource: true,
      preview: {
        heading: AnnouncementsHeader,
      },
      slug: 'announcements',
    },
  ],
  methods: {
    createResource: createResourceHandler,
    fetchDataHandler: fetchPageData,
    updateResource: updateResourceHandler,
  },
  options: {
    analyticsEvents: [
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
          id: resource.id,
          title: resource.title,
        }),
        label: 'Page Edit Started',
        type: 'start',
      },
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
          id: resource.id,
          title: resource.title,
        }),
        label: 'Page Edit Successful',
        type: 'update',
      },
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
          id: resource.id,
          title: resource.title,
        }),
        label: 'Page Edit Failed',
        type: 'failed',
      },
    ],
    createAnalyticsEvents: [
      {
        handler: (brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Page Creation Started',
        type: 'start',
      },
      {
        handler: (brand, resource, type) => ({
          ...locationAnalytics(brand),
          id: resource?.id,
          type,
        }),
        label: 'Page Template Selected',
        type: 'template-select',
      },
      {
        handler: (brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Page Creation Failed',
        type: 'failed',
      },
    ],
    home: {
      name: 'brand.website.pages.home',
    },
    resourceName: 'pages',
    resourceTitle: {
      key: 'title',
      placeholder: i18n.t('website-builder.resource-title.placeholder'),
    },
    root: 'brand.website',
    scaling: {
      initialScale: 0.625,
      previewBuildSize: 1440,
      scaleMap: [
        [900, 0.625],
        [400, 0.27777777],
      ],
    },
    storeModule: 'websiteBuilder',
    templates: {
      ...websiteBuilderTemplates,
      components: {
        dialog: CreateTemplateDialog,
      },
    },
  },
}
