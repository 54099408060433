import {
  FullStory,
  init as fullStoryInit,
  isInitialized,
} from '@fullstory/browser'
import mixpanel from 'mixpanel-browser'

import { fullstoryOrgKey, isFullstoryEnabled } from './integrations/fullstory'
import { isMixpanelEnabled, mixpanelProjectId } from './integrations/mixpanel'

const VITE_DEBUG_ANALYTICS = import.meta.env.VITE_DEBUG_ANALYTICS

class PbAnalytics {
  static instance = undefined

  /**
   * Singleton getter
   * @returns {object} PbAnalytics instance
   */
  static getInstance() {
    if (PbAnalytics.instance) {
      return PbAnalytics.instance
    }

    PbAnalytics.instance = new PbAnalytics()

    return PbAnalytics.instance
  }

  /**
   * Configure the analytics module
   *
   */
  configure() {
    const debug =
      import.meta.env.MODE !== 'production' && VITE_DEBUG_ANALYTICS === 'true'

    if (isMixpanelEnabled) {
      mixpanel.init(mixpanelProjectId, {
        debug,
      })
      mixpanel.register({
        application: 'hermes',
      })
    }

    if (isFullstoryEnabled) {
      fullStoryInit({
        debug,
        orgId: fullstoryOrgKey,
      })
    }
  }

  /**
   * Track an analytics event
   * @param {string} eventName Name of event to track
   * @param {object} [args] Extra Args to pass
   */
  track(eventName, args) {
    if (isMixpanelEnabled) mixpanel.track(eventName, args)
    if (isFullstoryEnabled && isInitialized())
      FullStory('trackEvent', { name: eventName, properties: args })
  }

  /**
   * Sets specific data about user
   * @param {object} data Data to set
   * @param {boolean} override Option to override data if already exists
   */
  setUserData(data, override) {
    if (override) {
      if (isMixpanelEnabled)
        mixpanel.people.set({
          ...data,
          environment: import.meta.env.MODE,
        })

      if (isFullstoryEnabled && isInitialized())
        FullStory('setProperties', { properties: data, type: 'user' })
    } else {
      if (isMixpanelEnabled)
        mixpanel.people.set_once({
          ...data,
          environment: import.meta.env.MODE,
        })

      if (isFullstoryEnabled && isInitialized())
        FullStory('setProperties', { properties: data, type: 'user' })
    }
  }

  /**
   * Identify User
   * @param {string} userId Id Of User
   */
  identify(userId) {
    if (isMixpanelEnabled) mixpanel.identify(userId)
    if (isFullstoryEnabled && isInitialized())
      FullStory('setIdentity', { uid: userId })
  }

  /**
   * Reset user id (logout)
   */
  reset() {
    if (isMixpanelEnabled) mixpanel.reset()
    if (isFullstoryEnabled && isInitialized())
      FullStory('setIdentity', { anonymous: true })
  }
}

export default PbAnalytics
