<i18n locale="en">
  {
    "missing-location": "Pleae select a location for the menu import.",
    "woflow-not-initialized": "Failed to initialize the menu import tool. Please contact support."
  }
  </i18n>
<template>
  <span>
    <slot :open-woflow-link="openWoflowLink" />
  </span>
</template>
<script lang="ts" setup>
import { WoflowLink } from '@woflowinc/link'

import { ConfiguredClient } from '@/api'
import { logError } from '@/logger'
import { useAnalytics, useI18n, useNotify } from '@/utils'

const emit = defineEmits(['onImportInitiated'])

const analytics = useAnalytics()
const i18n = useI18n()
const notify = useNotify()

const openWoflowLink = async (locationId: string | undefined) => {
  if (!locationId) {
    notify(i18n.$t('missing-location'), 'info')
    return
  }

  let woflowLink
  try {
    const result =
      await ConfiguredClient.dashboard.v1.locations.location.woflow.generateWoflowToken(
        {
          params: { locationId },
        }
      )

    woflowLink = new WoflowLink({
      jwt: result.token,
      linkConfigurationId: import.meta.env.VITE_WOFLOW_LINK_CONFIG_ID,
      publicKey: import.meta.env.VITE_WOFLOW_LINK_PUBLIC_KEY,
      theme: {
        width: 600,
      },
    })

    if (!woflowLink) {
      throw new Error(
        'User attempted to import a menu without Woflow initialized'
      )
    }
  } catch (error) {
    logError(error)
    notify(i18n.$t('woflow-not-initialized'), 'error')
    return
  }

  woflowLink.openIframe({
    onClose: () =>
      analytics.track('Woflow Closed', {
        locationId,
      }),
    onData: async (data: {
      id: string
      catalogSources: string[]
      /**
       * - `in_progress`: User has submitted the job. For "Other" imports (PDF, restaurant website), this is the
       *   terminal status from Woflow Link.
       * - `export_in_progress`: For third-party platform imports only; when user has approved the job.
       */
      state: 'in_progress' | 'export_in_progress'
    }) => {
      analytics.track('Woflow Update', {
        locationId,
        state: data.state,
      })

      if (data.state === 'in_progress' || data.state === 'export_in_progress') {
        emit('onImportInitiated')
        await ConfiguredClient.dashboard.v1.locations.location.woflow.createWoflowMenuSync(
          {
            body: { jobId: data.id },
            params: { locationId },
          }
        )
      }
    },
  })
}
</script>
