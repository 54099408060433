import { PhTextAlignLeft } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'

import i18n from '@/plugins/i18n'

import {
  type ContentSection,
  type ContentSectionCreate,
  type ContentSectionUpdate,
  type WebsiteResource,
} from '../../WebsiteBuilder.types'

import { websiteBlockMethods } from '../common/methods'

import ContentEditor from './editor/ContentEditor.vue'
import { createContentData } from './methods/createContentData'
import ContentPreview from './preview/ContentPreview.vue'

export const ContentBlockModule: BuilderBlockModule<
  ContentSection,
  ContentSectionCreate,
  ContentSectionUpdate,
  WebsiteResource
> = {
  createData: createContentData,
  editor: ContentEditor,
  meta: [
    {
      icon: PhTextAlignLeft,
      key: 'content',
      label: i18n.t('website-builder.blocks.content'),
      sort: 0,
    },
  ],
  methods: websiteBlockMethods,
  preview: ContentPreview,
}
