import Vue from 'vue'
import VueI18n from 'vue-i18n'

import locales from '@/locales'

/**
 * Tries to guess the user's preferred locale based on the Browser's language
 * @returns {string} Guessed locale
 */
const getBrowserLocale = () => {
  const browserLanguages = [
    navigator.language.split('-')[0],
    ...navigator.languages.map((lang) => lang.split('-')[0]),
  ]
  for (const i in browserLanguages) {
    const lang = browserLanguages[i]
    if (locales.find(({ name }) => name === lang)) return lang
  }
  return 'en'
}

Vue.use(VueI18n)

export default new VueI18n({
  fallbackLocale: 'en',
  // Sets the user's default locale based on
  locale: getBrowserLocale(),
  messages: locales.reduce(
    (acc, { name, messages }) => Object.assign(acc, { [name]: messages }),
    {}
  ),
  // Do not warn when falling back from component-level messages to global messages
  silentFallbackWarn: true,
})
