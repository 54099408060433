import { type Olympus } from '@owner/olympus-client'
import Vue from 'vue'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'

export const blockAddMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  payload: Olympus.Funnel.Section
): void => {
  Vue.set(state._builder_currentState, 'blocks', [
    ...state._builder_currentState.blocks,
    payload,
  ])
}
