<i18n locale="en">
  {
    "title": "Frequently asked questions",
    "questions": {
      "cities": "What cities do you serve?",
      "keywords": "What are you known for?"
    }
  }
</i18n>

<template>
  <PreviewSheet
    class="faq-preview w-full h-full"
    background="primary"
    radius="0"
  >
    <OwnStack justify="center" spacing="8">
      <PreviewTypography :text="$t('title')" variant="heading2" />
      <OwnStack spacing="0" col class="w-full">
        <OwnStack v-for="question in questions" :key="question">
          <OwnStack class="w-full faq-preview__title" row justify="between">
            <PreviewTypography
              :text="$t(`questions.${question}`)"
              align="left"
              variant="titleRegular"
            />
            <PreviewIcon :icon="PhCaretDown" color="secondary" size="16" />
          </OwnStack>
        </OwnStack>
      </OwnStack>
    </OwnStack>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { PhCaretDown } from 'phosphor-vue/dist/phosphor-vue.esm'

import { PreviewIcon, PreviewSheet, PreviewTypography } from '@/lib/builder'
import { OwnStack } from '@/ui'

import type { FaqSection } from '../../../WebsiteBuilder.types'

defineProps<{
  content: FaqSection
}>()

const questions = ['cities', 'keywords']
</script>

<style lang="scss" scoped>
.faq-preview {
  padding: 120px;

  &__title {
    padding: 24px 0;
    border-bottom: 1px solid $background-divider;
  }

  &__collapse-caret {
    width: 16px;
    height: 16px;
    color: $text-color-secondary;
  }
}
</style>
