
{
  "title": {
    "label": "Name",
    "placeholder": "Aa"
  },
  "toggle": {
    "label": "Display until turned off"
  },
  "validAt": {
    "label": "From"
  },
  "expiresAt": {
    "label": "To"
  },
  "link": {
    "label": "Link",
    "description": "Select where you want the announcement to link to when clicked.",
    "placeholder": "https://example.com"
  },
  "success": "Saved announcement",
  "errors": {
    "save": "Unable to save announcement"
  }
}
