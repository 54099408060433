<i18n locale="en">
{
  "delete": "Delete"
}
</i18n>

<template>
  <OwnStack spacing="8">
    <slot></slot>

    <OwnButton
      v-if="$listeners.delete"
      :text="deleteLabel ?? $t('delete')"
      danger
      @click="$emit('delete')"
    >
      <template #icon-right>
        <PhTrash size="24" />
      </template>
    </OwnButton>
  </OwnStack>
</template>

<script>
import { PhTrash } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnButton, OwnStack } from '@/ui'

export default {
  name: 'DetailEditWrapper',
  components: {
    OwnButton,
    OwnStack,
    PhTrash,
  },
  props: {
    /**
     * Label for delete button.
     */
    deleteLabel: { type: String, default: null },
  },
}
</script>
