import { type RawLocation } from 'vue-router'

export interface StripeState {
  brandId: string | null
  locationId: string | null
  state: string | null
  returnTo: RawLocation | null
}

export interface OAuthState {
  stripe: StripeState
}

const oAuthState: OAuthState = {
  stripe: {
    brandId: null,
    locationId: null,
    returnTo: null,
    state: null,
  },
}

export default oAuthState
