<i18n locale="en">
{
  "coming-soon": "Coming soon",
  "method-options": {
    "ai": "Make it with AI",
    "custom": "Make it yourself"
  }
}
</i18n>

<template>
  <OwnStack class="template-sidebar" spacing="8">
    <OwnTouchRadioGroup v-model="selectedMethod" :options="methodOptions" />
    <OwnOption class="template-sidebar__ai">
      <OwnStack justify="between" align="center" spacing="4" row>
        <OwnStack justify="start" align="center" spacing="4" row>
          <PhSparkle size="24" />

          <OwnStack spacing="1">
            <OwnType
              :text="$t('method-options.ai')"
              variant="subtitle"
              color="primary"
            />
            <OwnType
              :text="$t('coming-soon')"
              variant="paragraph-small"
              color="secondary"
            />
          </OwnStack>
        </OwnStack>
      </OwnStack>
    </OwnOption>
  </OwnStack>
</template>

<script>
import { PhSparkle, PhPaintBrushHousehold } from 'phosphor-vue'

import { OwnOption, OwnStack, OwnTouchRadioGroup, OwnType } from '@/ui'
import { mapModel } from '@/utils/computed'

export default {
  name: 'TemplateSidebar',
  components: {
    OwnOption,
    OwnStack,
    OwnTouchRadioGroup,
    OwnType,
    PhSparkle,
  },
  props: {
    previewLoaded: { type: Boolean, required: true },
    value: { type: String, default: 'blank' },
  },
  data() {
    return {
      methodOptions: [
        {
          icon: PhPaintBrushHousehold,
          label: this.$t('method-options.custom'),
          value: 'custom',
        },
      ],
      selectedMethod: 'custom',
    }
  },
  computed: {
    ...mapModel('selectedOption'),
  },
}
</script>
<style lang="scss" scoped>
.template-sidebar {
  &__ai {
    margin-top: 12px;
    opacity: 0.5;
    box-shadow: none;
  }
}
</style>
