import type Vue from 'vue'
import { getCurrentInstance } from 'vue'

import type VueI18n from 'vue-i18n'
import { type IVueI18n } from 'vue-i18n'

export const useI18n = (): {
  $t: (key: VueI18n.Path, values?: VueI18n.Values) => string
  $tc: IVueI18n['tc']
  $te: IVueI18n['te']
} => {
  /**
   * Note: `getCurrentInstance` is an internal Vue method and not
   * recommended.  However, we are using it in a controlled
   * way here.  We are only accessing the proxy to use $t and $tc.
   * This is enforced by TS and will provides us a bridge until we
   * get to Vue 3 land.
   */
  const vm = getCurrentInstance() as { proxy: Vue }

  return vm.proxy
}
