import { computed, type ComputedRef } from 'vue'
import { useRoute } from 'vue-router/composables'

import { useI18n } from '@/utils'

export const usePageDescription = (): ComputedRef<string | undefined> => {
  const route = useRoute()
  const i18n = useI18n()

  return computed(() => {
    for (let i = route.matched.length - 1; i > 0; i--) {
      const match = route.matched[i]
      if (match?.meta?.title) {
        const key = `core.pages.${match.meta.title}.description`

        return i18n.$te(key) ? i18n.$t(key) : undefined
      }
    }

    return undefined
  })
}
