
{
  "title": "Some of the sections you’ve added haven’t been completed:",
  "warnings": {
    "item": "Menu Item",
    "coupon": "Coupon",
    "heading": "Text (heading)",
    "subheading": "Text (subheading)",
    "paragraph": "Text (paragraph)",
    "image": "Image",
    "text": "Text message body"
  }
}
