
{
  "applications":  "0 applications | {count} application | {count} applications",
  "delete-confirm": {
    "delete": "Delete Job",
    "go-back": "Go Back",
    "message": "Are you sure you want to delete this job?"
  },
  "errors": {
    "load": "Unable to load jobs"
  },
  "headers" : {
    "applications": "Applications",
    "delete": "Delete",
    "salary": "Salary Range",
    "title": "Title"
  },
  "loading": "Getting jobs...",
  "no-data": "No jobs",
  "no-query-results": "No jobs match that search",
  "salary": {
    "max": "Up to {amount}",
    "min": "Starting at {amount}"
  }
}
