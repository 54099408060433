
{
  "actions": {
    "done": "Done"
  },
  "errors": {
    "load": "Failed to load job",
    "update": "Failed to update job"
  },
  "loading": "Getting job...",
  "options": "Options",
  "role": "Role",
  "success": "Successfully updated job",
  "title": "Edit Role"
}
