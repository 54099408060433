import { getStoreValue } from '../../utils/getStoreValue'

export default {
  /**
   * Returns the current brand's assets object
   * @param {object} state Vuex state
   * @returns {object} The brand's assets object
   */
  assets: (state) => (state.brand ? state.brand.assets : {}),

  /**
   * Returns the current user's brand
   * @param {object} state Vuex state
   * @returns {object} User's brand
   */
  brand: (state) => state.brand,

  /**
   * Returns the brand's ID
   * @param {object} state Vuex state
   * @returns {string} The brand's ID
   */
  id: (state) => (state.brand ? state.brand.id : undefined),

  /**
   * Returns the current brand's links
   * @param {object} state Vuex state
   * @returns {boolean} The brand's links
   */
  links: (state) => (state.brand ? state.brand.links : {}),

  /**
   * Returns location from location list.
   * Returns `undefined` if location is not on location list.
   * @param {object} state Vuex state
   * @returns {object} Location
   */
  locationById: (state) => (locationId) => {
    const filteredLocations = state.locations.filter(
      (location) => location.id === locationId
    )

    return filteredLocations.length ? filteredLocations[0] : undefined
  },

  /**
   * Returns the location ids for current brand
   * @param {object} state Vuex state
   * @returns {string[]} Location ids for this brand
   */
  locationIds: (state) => state.locations.map((location) => location.id),

  /**
   * Returns the locations for this brand
   * @param {object} state Vuex state
   * @returns {object[]} Locations for this brand
   */
  locations: (state) => state.locations,

  /**
   * Returns the current user's brand
   * @param {object} state Vuex state
   * @returns {string} The brand's logo
   */
  logo: (state) => (state.brand ? state.brand.assets.logo : undefined),

  /**
   * Returns the current user's brand
   * @param {object} state Vuex state
   * @returns {string} The brand's name
   */
  name: (state) => (state.brand ? state.brand.name : undefined),

  path:
    (state) =>
    ({ brandId, path }) => {
      if (state.brand.id !== brandId) {
        throw new Error(
          'Setup Guide::Requested BrandId does not match loaded BrandId'
        )
      }

      // special handler for sms path
      if (path.startsWith('sms')) {
        return getStoreValue(state.sms, path.split('.').slice(1))
      }

      return getStoreValue(state.brand, path.split('.'))
    },

  /**
   * Returns the current brand's theme object
   * @param {object} state Vuex state
   * @returns {object} The brand's theme object
   */
  theme: (state) => (state.brand ? state.brand.theme : {}),
}
