<i18n locale="en">
{
  "labels": {
    "order": "Order Now"
  }
}
</i18n>

<template>
  <PreviewSheet background="primary" radius="0" class="py-3 pl-6">
    <div class="w-full px-3 py-4">
      <PreviewButton
        color="primary"
        size="xsmall"
        :title="$t('labels.order')"
      />
    </div>
  </PreviewSheet>
</template>

<script>
import { PreviewSheet, PreviewButton } from '@/lib/builder'

export default {
  name: 'CTAButtonPreview',
  components: {
    PreviewButton,
    PreviewSheet,
  },
}
</script>
