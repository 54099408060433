<i18n locale="en">
{
  "title": "Logo"
}
</i18n>

<template>
  <OwnInputContainer :label="$t('title')">
    <ImageInput :value="brandLogo" file-type="png" @input="onImageUpdate" />
  </OwnInputContainer>
</template>

<script>
import set from 'lodash/set'
import { mapActions, mapGetters } from 'vuex'

import { ImageInput } from '@/components/inputs/ImageInput'
import { OwnInputContainer } from '@/ui'

export default {
  name: 'LogoEditor',
  components: {
    ImageInput,
    OwnInputContainer,
  },
  computed: {
    ...mapGetters({
      currentResource: 'websiteBuilder/currentResource',
    }),
    brandLogo() {
      const { currentResource } = this

      return currentResource?.brand?.assets?.logo
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    async onImageUpdate(newImage) {
      const update = set({}, 'brand.assets.logo', newImage)

      await this.updateResource(update)
    },
  },
}
</script>
