<template>
  <OwnInputContainer
    :label="$t('labels.url')"
    :errors="url.status.errors"
    sr-only
  >
    <OwnInput v-model="url.value" />
  </OwnInputContainer>
</template>

<script>
import { FormBuilderModelSync, Validators } from '@/forms'
import { OwnInput, OwnInputContainer } from '@/ui'

export default {
  name: 'UrlForm',
  components: { OwnInput, OwnInputContainer },
  mixins: [
    FormBuilderModelSync({
      url: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required, Validators.url],
      },
    }),
  ],
  props: {
    actionConfig: { type: Object, default: null },
  },
  created() {
    const { actionConfig } = this

    this.setInitialFormValues({
      url: actionConfig.params.url,
    })
  },
  methods: {
    onFormControlUpdate(controlName, value, status) {
      this.$emit('update', {
        controlName,
        status,
        value,
      })
    },
  },
}
</script>
