<i18n locale="en">
  {
    "max": {
      "label": "Max",
      "placeholder": "Maximum"
    },
    "min": {
      "label": "Min",
      "placeholder": "Minimum"
    },
    "salary": "Per hour salary range"
  }
  </i18n>
<template>
  <ToggleCard
    :value="min.value !== null || max.value !== null"
    :label="$t('salary')"
    @input="toggleSalary"
  >
    <OwnInputContainer :label="$t('min.label')" :errors="min.status.errors">
      <OwnInput
        v-model="min.value"
        :placeholder="$t('min.placeholder')"
        start-text="$"
        type="number"
      />
    </OwnInputContainer>
    <OwnInputContainer :label="$t('max.label')" :errors="max.status.errors">
      <OwnInput
        v-model="max.value"
        :placeholder="$t('max.placeholder')"
        start-text="$"
        type="number"
      />
    </OwnInputContainer>
  </ToggleCard>
</template>
<script>
import { ToggleCard } from '@/components/cards'
import { FormBuilderModelSync } from '@/forms'
import { OwnInput, OwnInputContainer } from '@/ui'

export default {
  name: 'SalaryInput',
  components: {
    OwnInput,
    OwnInputContainer,
    ToggleCard,
  },
  mixins: [
    FormBuilderModelSync({
      max: {
        debounce: 250,
        validateOnInit: true,
        validators: [],
      },
      min: {
        debounce: 250,
        validateOnInit: true,
        validators: [],
      },
    }),
  ],
  methods: {
    onFormValuesUpdate(formValues, isValid) {
      if (!isValid) {
        return
      }

      this.$emit('input', formValues)
    },
    toggleSalary(enabled) {
      if (enabled === true) {
        this.max.value = '20'
        this.min.value = '0'
      } else {
        this.max.value = null
        this.min.value = null
      }
    },
  },
}
</script>
