import client from '../client'

/**
 * Returns a paginated list of locations
 * @param {object} query location filtering options
 * @returns {Promise} resolves a paginated list of location objects
 */
export const getLocations = (query) => client.get('/locations', query)

/**
 * Returns a location object
 * @param {string} locationId Profitboss Location Id
 * @returns {Promise} resolves a location object
 */
export const getLocation = (locationId) =>
  client.get(`/locations/${locationId}`)

/**
 * Updates a location's attributes
 * @param {string} locationId Profitboss Location ID
 * @param {object} updatedSettings a set of updated location settings
 * @returns {Promise} resolves an updated location object
 */
export const updateLocation = (locationId, updatedSettings) =>
  client.patch(`/locations/${locationId}`, updatedSettings)

/**
 * A new Profitboss location
 * @param {object} location a Profitboss location object
 * @returns {Promise} resolves a location object
 */
export const createLocation = (location) => client.post('/locations', location)

/**
 * Links the Stripe account for the location
 * @param {string} locationId Profitboss location ID
 * @param {string} code Authorization code returned from Stripe
 * @returns {Promise} Resolves a location object
 */
export const linkStripeAccount = (locationId, code) =>
  client.post(`/locations/${locationId}/link-stripe`, { code })

/**
 * Update Funnel Links
 * @param {string} locationId location ID
 * @param {string} links Funnel link object
 */
export const updateFunnelLinks = (locationId, links) =>
  client.put(`/locations/${locationId}/funnel/links`, links)

/**
 * Set LocationId for square
 * @param {string} locationId Profitboss location ID
 * @param {string} squareLocationId Square Location Id
 */
export const updateSquareLocation = (locationId, squareLocationId) =>
  client.put(`/locations/${locationId}/square-location`, { squareLocationId })

/**
 * Set LocationId for otter
 * @param {string} locationId Profitboss location ID
 * @param {string} otterStoreId Otter Location Id
 */
export const updateOtterPosIntegration = (locationId, otterStoreId) =>
  client.put(`/locations/${locationId}/otter-store-id`, { otterStoreId })

/**
 * Remove POS integration
 * @param {string} locationId Profitboss location ID
 */
export const removePosIntegration = (locationId) =>
  client.delete(`/locations/${locationId}/pos`)

/**
 * Gets payouts for a location's Stripe account
 * @param {string} locationId Owner location ID
 * @param {string|null} startingAfter ID of the first payout to return
 */
export const getPayouts = (locationId, startingAfter = undefined) =>
  client.get(`/locations/${locationId}/payouts`, { startingAfter })

/**
 * Gets a specific payout from a location's stripe account
 * @param {string} locationId Owner location ID
 * @param {string} payoutId Payout ID
 */
export const getPayout = (locationId, payoutId) =>
  client.get(`/locations/${locationId}/payouts/${payoutId}`)
