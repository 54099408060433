<template>
  <OwnCard class="toggle-card" control>
    <OwnToggle
      v-model="modelVal"
      class="toggle-card__toggle"
      :class="[modelVal && 'toggle-card__toggle--enabled']"
      :label="props.label"
      :description="description"
    />

    <OwnStack
      v-if="modelVal"
      class="toggle-card__content"
      :class="[modelVal && 'toggle-card__content--enabled']"
      spacing="4"
    >
      <OwnRule type="dashed" />

      <slot></slot>
    </OwnStack>
  </OwnCard>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { WritableComputedRef } from 'vue'

import { OwnCard, OwnToggle, OwnRule, OwnStack } from '@/ui'

interface ToggleCardProps {
  description?: string
  label: string
  value?: boolean
}

const props = withDefaults(defineProps<ToggleCardProps>(), {
  description: undefined,
  value: false,
})

const modelVal = useVModel(props) as WritableComputedRef<boolean>
</script>

<style lang="scss" scoped>
$closed-padding: 12px 16px;
$open-padding: 24px;
$transition: padding 150ms ease-in;

.toggle-card {
  &__toggle {
    transition: $transition;
    padding: $closed-padding;

    &--enabled {
      padding: $open-padding;
    }
  }

  &__content {
    transition: $transition;
    padding: 0 $closed-padding $closed-padding $closed-padding;

    &--enabled {
      padding: 0 $open-padding $open-padding $open-padding;
    }
  }
}
</style>
