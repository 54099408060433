import type Vue from 'vue'
import { getCurrentInstance } from 'vue'

import { type User } from '@/core'
import { useMappedGetter } from '@/store'

export const useAnalytics = (): {
  track: (eventName: string, args?: Record<string, unknown>) => void
} => {
  /**
   * Note: `getCurrentInstance` is an internal Vue method and not
   * recommended.  However, we are using it in a controlled
   * way here.  We are only accessing the proxy to use track.
   * This is enforced by TS and will provide us a bridge until we
   * get to Vue 3 land.
   */
  const vm = getCurrentInstance() as { proxy: Vue }

  const track = (eventName: string, args?: Record<string, unknown>): void => {
    const user = useMappedGetter<User | undefined>('session/user')

    const activeBrandId = useMappedGetter<string | undefined>(
      'core/brand/active/id'
    )

    const activeLocationId = useMappedGetter<string | undefined>(
      'core/location/active/id'
    )

    // @ts-expect-error This does exist on the vm via plugin
    vm.proxy.$analytics.track(eventName, {
      ...(args ?? {}),
      brandId: activeBrandId.value,
      locationId: activeLocationId.value,
      user: {
        brands: user.value?.brands,
        createdAt: user.value?.createdAt,
        email: user.value?.email,
        firstName: user.value?.firstName,
        lastName: user.value?.lastName,
        locations: user.value?.locations,
        role: user.value?.role,
      },
    })
  }

  return {
    track,
  }
}
