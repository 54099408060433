<i18n locale="en">
{
  "action": "Save"
}
</i18n>

<template>
  <OwnStack row justify="end" align="center" spacing="6">
    <OwnButton
      :disabled="disabled"
      :text="$t('action')"
      :processing="saving"
      primary
      @click="update"
    />
  </OwnStack>
</template>

<script>
import { OwnButton, OwnStack } from '@/ui'

export default {
  name: 'UpdateOTCFooter',
  components: { OwnButton, OwnStack },
  props: {
    /** Disables scheduling */
    disabled: { type: Boolean, required: true },

    /** Current loading state */
    saving: { type: Boolean, default: false },
  },
  methods: {
    async update() {
      this.$emit('update', { subject })
    },
  },
}
</script>
