<i18n locale="en">
{
  "alignment": {
    "left": "Left",
    "right": "Right"
  },
  "labels": {
    "header": "Title",
    "body": "Subtitle",
    "action": "Button",
    "media": "Media",
    "alignment": "Alignment",
    "layout": "Background Photo"
  },
  "media": {
    "image": "Photo",
    "none": "None",
    "video": "Video"
  }
}
</i18n>

<template>
  <OwnStack spacing="8" class="content-editor">
    <OwnInputContainer :label="$t('labels.media')">
      <OwnSegmentedControl
        :options="mediaOptions"
        :value="currentBlockParams.media?.type ?? 'none'"
        @input="changeMediaType"
      />
    </OwnInputContainer>
    <OwnOption v-if="currentBlockParams.media?.type === 'image'" el="div">
      <OwnToggle
        :value="currentBlockParams.layout === 'cover'"
        :label="$t('labels.layout')"
        @input="toggleLayout"
      />
    </OwnOption>
    <ContentImageEditor
      v-if="currentBlockParams.media?.type === 'image'"
      :layout="currentBlockParams.layout"
      :value="currentBlockParams.media?.params"
      @update="
        onFormControlUpdate($event.controlName, $event.value, $event.status)
      "
    />
    <ContentVideoEditor
      v-if="currentBlockParams.media?.type === 'video'"
      :value="{ video: currentBlockParams.media?.params?.params?.id }"
      @update="
        onFormControlUpdate($event.controlName, $event.value, $event.status)
      "
    />
    <OwnInputContainer
      v-if="currentBlockParams.media !== null"
      :label="$t('labels.alignment')"
    >
      <OwnSegmentedControl
        :options="alignmentOptions"
        :value="currentBlockParams.alignment"
        @input="applyContentUpdate({ alignment: $event })"
      />
    </OwnInputContainer>
    <OwnInputContainer
      :label="$t('labels.header')"
      :errors="header.status.errors"
      :max-chars="50"
      :value="header.value"
    >
      <OwnInput v-model="header.value" />
    </OwnInputContainer>
    <OwnInputContainer
      :label="$t('labels.body')"
      :errors="body.status.errors"
      :max-chars="500"
      :value="body.value"
    >
      <OwnTextarea v-model="body.value" />
    </OwnInputContainer>
    <ToggleCard
      :label="$t(`labels.action`)"
      :value="currentBlockParams.action !== null"
      @input="toggleAction"
    >
      <ContentActionEditor
        v-if="currentBlockParams.action !== null"
        class="content-editor__action-inputs"
        :value="currentBlockParams.action"
        @update="applyActionOverride"
      />
    </ToggleCard>
  </OwnStack>
</template>

<script>
import { mapGetters } from 'vuex'

import ToggleCard from '@/components/cards/ToggleCard.vue'
import { FormBuilder, Validators } from '@/forms'
import {
  OwnInput,
  OwnInputContainer,
  OwnOption,
  OwnSegmentedControl,
  OwnStack,
  OwnTextarea,
  OwnToggle,
} from '@/ui'

import { ContentCommands } from '../commands/ContentCommands'

import ContentActionEditor from './ContentActionEditor.vue'
import ContentImageEditor from './ContentImageEditor.vue'
import ContentVideoEditor from './ContentVideoEditor.vue'

export default {
  name: 'ContentEditor',
  components: {
    ContentActionEditor,
    ContentImageEditor,
    ContentVideoEditor,
    OwnInput,
    OwnInputContainer,
    OwnOption,
    OwnSegmentedControl,
    OwnStack,
    OwnTextarea,
    OwnToggle,
    ToggleCard,
  },
  mixins: [
    FormBuilder({
      body: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required, Validators.maxLength(500)],
      },
      header: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required, Validators.maxLength(50)],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  data() {
    return {
      alignmentOptions: ['left', 'right'].map((alignmentOption) => ({
        label: this.$t(`alignment.${alignmentOption}`),
        value: alignmentOption,
      })),
      mediaOptions: ['none', 'image', 'video'].map((mediaType) => ({
        label: this.$t(`media.${mediaType}`),
        value: mediaType,
      })),
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    currentBlockParams() {
      const { id } = this

      return this.blockById(id)?.params
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues({
      body: currentBlockParams.body,
      header: currentBlockParams.header,
    })
  },
  methods: {
    async applyActionOverride(newValue) {
      const { id } = this

      const overrideAction =
        ContentCommands.overrideBlockContent('params.action')

      await overrideAction({
        targetId: id,
        update: newValue,
      })
    },
    async applyContentUpdate(newValue) {
      const { id } = this

      await ContentCommands.updateBlock({
        targetId: id,
        update: {
          params: {
            ...newValue,
          },
        },
      })
    },
    async changeMediaType(mediaType) {
      if (mediaType === 'image') {
        await this.applyContentUpdate({
          alignment: 'left',
          media: { params: { image: '' }, type: 'image' },
        })
      } else if (mediaType === 'video') {
        await this.applyContentUpdate({
          alignment: 'left',
          media: {
            params: {
              params: {
                id: '',
              },
              type: 'youtube',
            },
            type: 'video',
          },
        })
      } else {
        await this.applyContentUpdate({ alignment: 'center', media: null })
      }
    },
    async onFormControlUpdate(controlName, value, status) {
      if (!status.valid) {
        return
      }

      let update
      if (controlName === 'image') {
        update = {
          media: {
            params: {
              image: value,
            },
            type: 'image',
          },
        }
      } else if (controlName === 'video') {
        update = {
          media: {
            params: {
              params: {
                id: value,
              },
              type: 'youtube',
            },
            type: 'video',
          },
        }
      } else {
        update = { [controlName]: value }
      }
      await this.applyContentUpdate(update)
    },
    async toggleAction(enabled) {
      if (enabled === true) {
        await this.applyContentUpdate({
          action: {
            params: { label: 'Enter title', url: '' },
            type: 'external',
          },
        })
      } else {
        await this.applyContentUpdate({ action: null })
      }
    },
    async toggleLayout(isCover) {
      await this.applyContentUpdate({
        layout: isCover === true ? 'cover' : 'generic',
        media: {
          params: {
            image: '',
          },
          type: 'image',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content-editor {
  &__action-inputs {
    margin-top: 24px;
  }
}
</style>
