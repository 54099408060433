
{
  "actions": {
    "replace": "Replace",
    "remove": "Remove"
  },
  "labels": {
    "upload": "Upload a photo",
    "upload-limit-reached": "{limit}/{limit} photo limit reached. Delete a photo before you can upload another.",
    "upload-with-limit": "Upload a photo {currentTotal}/{limit}"
  }
}
