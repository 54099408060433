
{
  "title": "Add Locations",
  "actions": {
    "add": "Add Location | Add Location | Add Locations"
  },
  "labels": {
    "empty": "No Locations Found"
  }
}
