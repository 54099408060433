<i18n locale="en">
  {
    "cta": "Add Item"
  }
</i18n>
<template>
  <PreviewSheet class="menu-item-card" radius="medium">
    <div
      class="menu-item-card__background"
      :style="{
        backgroundImage: `url(${item.image})`,
        borderRadius: cardBorderRadius,
      }"
    ></div>
    <OwnStack
      spacing="4"
      class="menu-item-card__content"
      :style="{
        borderRadius: cardBorderRadius,
      }"
    >
      <div
        class="menu-item-card__image"
        :style="{
          backgroundImage: `url(${item.image})`,
          borderRadius: imageBorderRadius,
        }"
      ></div>
      <OwnStack spacing="4" class="menu-item-card__footer" justify="between">
        <OwnStack spacing="1">
          <PreviewTypography
            :text="item.displayName ?? item.name"
            variant="paragraphRegular"
            color="white"
            class="menu-item-card__title-text"
          />
          <PreviewTypography
            :text="item.description ?? ''"
            color="inherit"
            variant="paragraphSmall"
            class="menu-item-card__description-text"
          />
        </OwnStack>
        <div
          class="menu-item-card__button"
          :style="{
            borderRadius: controlBorderRadius,
          }"
        >
          <PreviewTypography
            color="white"
            variant="buttonSmall"
            :text="$t('cta')"
          />
        </div>
      </OwnStack>
    </OwnStack>
  </PreviewSheet>
</template>
<script lang="ts" setup>
import { type Olympus } from '@owner/olympus-client'
import { computed } from 'vue'

import {
  PreviewSheet,
  PreviewTypography,
  useMergedThemeComp,
} from '@/lib/builder'
import { OwnStack } from '@/ui'

const mergedTheme = useMergedThemeComp()

const imageBorderRadius = computed(() => {
  return mergedTheme.value != null
    ? `${mergedTheme.value.borderRadius.small}rem`
    : 0
})

const cardBorderRadius = computed(() => {
  return mergedTheme.value != null
    ? `${mergedTheme.value.borderRadius.medium}rem`
    : 0
})

const controlBorderRadius = computed(() => {
  return mergedTheme.value != null
    ? `${mergedTheme.value.borderRadius.control}rem`
    : 0
})

defineProps<{
  item: Olympus.Menu.Item
}>()
</script>
<style lang="scss" scoped>
.menu-item-card {
  position: relative;
  width: 296px;

  &__background {
    position: absolute;
    margin-left: 1px;
    margin-top: 1px;
    width: 98%;
    height: 98%;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__content {
    background-color: rgba(9, 10, 11, 0.5);
    backdrop-filter: blur(32px);
    height: 100%;
    padding: 8px 8px 16px 8px;
    position: relative;
    overflow: hidden;
  }

  &__image {
    width: 280px;
    height: 280px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__footer {
    flex-grow: 1;
    padding: 0 8px;
  }

  &__title-text {
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &__description-text {
    color: #c4c4c4;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &__button {
    padding: 8px 0;
    background-color: rgba(255, 255, 255, 0.2);
    text-align: center;
  }
}
</style>
