import client from '../client'

/**
 * Get checkout settings for a location
 * @param {string} locationId Owner location id
 * @returns {Array} Checkout settings
 */
export const getCheckoutSettings = (locationId) =>
  client.get(`/locations/${locationId}/checkout-settings`)

/**
 * Update existing checkout setttings
 * @param {string} locationId Owner location id
 * @param {object} settingsUpdate Checkout settings changes
 */
export const updateCheckoutSettings = (locationId, settingsUpdate) =>
  client.patch(`/locations/${locationId}/checkout-settings`, settingsUpdate)
