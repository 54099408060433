import { mapGetters } from 'vuex'

import bus from '@/mixins/bus'

/**
 * Manages the Intercom popup and exposes a global event listener to toggle the popup
 * @mixin
 */
export default {
  name: 'IntercomManager',
  mixins: [bus],
  data() {
    return {
      $_im_isBooted: false,
    }
  },
  computed: {
    ...mapGetters({
      $_im_isAuthenticated: 'session/isAuthenticated',
      $_im_user: 'session/user',
    }),
  },
  watch: {
    $_im_isAuthenticated(isAuthenticated) {
      if (!isAuthenticated) return this.$_im_shutdown()
      this.$_im_boot()
    },
  },
  mounted() {
    this.$bus.on('show-help', this.$_im_show)
    if (this.$_im_isAuthenticated) this.$_im_boot()
  },
  methods: {
    $_im_boot() {
      const { email, firstName, lastName, id } = this.$_im_user
      this.$intercom.boot({
        alignment: 'right',
        email,
        hide_default_launcher: true,
        horizontal_padding: 8,
        name: `${firstName} ${lastName}`,
        user_id: id,
        vertical_padding: 8,
      })
      this.$data.$_im_isBooted = true
    },

    /** Show the intercom popup via the global `show-help` event */
    $_im_show() {
      this.$_im_boot()
      this.$intercom.show()
    },

    $_im_shutdown() {
      this.$intercom.shutdown()
      this.$_im_isBooted = false
    },
  },
}
