
{
  "actions": {
    "immediate": "Send Now",
    "scheduled": "Schedule"
  },
  "errors": {
    "customer-count": "There was a problem on our end and we can’t send this right now"
  },
  "locations": {
    "all": "across all locations",
    "specific": "from {location}"
  },
  "messages": {
    "loading": "Calculating number of customers for campaign...",
    "immediate": "No customers are targeted for this campaign | You’re about to send this to 1 customer {location} | You’re about to send this to {count} of your customers {location}",
    "scheduled": "No customers are targeted for this campaign | You’re about to schedule this for 1 customer {location} | You’re about to schedule this for {count} of your customers {location}"
  }
}
