import { brandsApi, locationsApi } from '@/api'

import { convertPathToObject } from '../../utils/convertPathToObject'

export default {
  /**
   * Clears the brand store
   * @param {object} Vuex ignore
   * @param {Function} Vuex.commit ignore
   */
  clearBrand: async ({ commit }) => {
    commit('RESET_STATE')
  },

  /**
   * Loads a brand + its locations into the store
   * @param {object} Vuex ignore
   * @param {Function} Vuex.commit ignore
   * @param {string} brandId Profitboss Brand ID
   */
  loadBrand: async ({ commit }, brandId) => {
    const [brand, locations] = await Promise.all([
      brandsApi.getBrand(brandId),
      locationsApi.getLocations({ brandId, limit: 100 }),
    ])

    commit('SET_BRAND', brand)
    commit('SET_LOCATIONS', locations.results)
  },

  setBrand({ commit }, { brand, locations }) {
    commit('SET_BRAND', brand)
    commit('SET_LOCATIONS', locations)
  },

  /**
   * Updates the user's brand attributes
   * @param {object} Vuex ignore
   * @param {Function} Vuex.commit ignore
   * @param {object} Vuex.state ignore
   * @param {object} updatedSettings updated brand settings
   */
  updateBrand: async ({ commit, state }, updatedSettings) => {
    const brand = await brandsApi.updateBrand(state.brand.id, updatedSettings)
    commit('SET_BRAND', brand)
  },

  updateBrandLocation: ({ commit }, newLocation) => {
    commit('SET_LOCATION', newLocation)
  },

  /**
   * Determines which settings to update
   * @param {object} Vuex ignore
   * @param {Function} Vuex.dispatch ignore
   * @param {object} body Request Body
   * @param {object} body.links Links to update
   * @param {number} body.priceRange Price range to update
   */
  updateGeneralSettings: async ({ dispatch }, { links, priceRange }) => {
    if (links) await dispatch('updateLinks', links)

    if (priceRange >= 0) {
      await dispatch('updateBrand', { priceRange })
    }
  },

  /**
   * Updates the hours for a brand's location
   * @param {object} Vuex ignore
   * @param {Function} Vuex.commit ignore
   * @param {object} body Request Body
   * @param {string} body.locationId Owner location id
   * @param {object} body.hours Hours query for update
   */
  updateHours: async ({ commit }, { hours, locationId }) => {
    await locationsApi.updateLocation(locationId, { hours })

    commit('SET_LOCATION_HOURS', { hours, locationId })
  },

  /**
   * Updates the brand links
   * @param {object} Vuex ignore
   * @param {Function} Vuex.dispatch ignore
   * @param {object} Vuex.state ignore
   * @param {object} body Request Body
   * @param {boolean} body.olo External OLO link
   * @param {object} body.reservations External Reservations Link
   * @param {object} body.facebook Facebook Link
   * @param {object} body.instagram Instagram Link
   */
  updateLinks: async ({ dispatch, state }, body) => {
    const brandId = state.brand.id

    await brandsApi.updateLinks(brandId, body)

    await dispatch('loadBrand', brandId)
  },

  updateViaPath: async ({ commit, state }, { brandId, path, value }) => {
    if (path.startsWith('sms')) {
      const smsSubpath = path.split('.').slice(1).join('.')
      const changeObj = convertPathToObject(smsSubpath, value)

      const newSMS = await brandsApi.updateBrandSMSMarketing(brandId, changeObj)

      commit('SET_SMS_MARKETING_SETTINGS', newSMS)
      return
    }

    const change = convertPathToObject(path, value)

    const brand = await brandsApi.updateBrand(brandId, change)

    if (brandId === state.brand.id) {
      commit('SET_BRAND', brand)
    }
  },

  updateWoflowLocationIntegration: async (
    { commit },
    { locationId, woflowIntegration }
  ) => {
    commit('UPDATE_LOCATION_WOFLOW', { locationId, woflowIntegration })
  },
}
