<i18n locale="en">
{
  "actions": {
    "fix": "Update payment method"
  },
  "labels": {
    "title": "Payment failed for {locationName}",
    "body": "We haven't been able to process payment for this location.  Please update your payment method."
  }
}
</i18n>

<template>
  <OwnCallout
    v-if="hasLocationsUnderCollections"
    :title="title"
    :body="$t('labels.body')"
    type="danger"
  >
    <template #right-action>
      <OwnButton
        v-if="!isOnCollectionsPage"
        :text="$t('actions.fix')"
        @click="onFixClick"
      />
    </template>
  </OwnCallout>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useRoute, useRouter } from 'vue-router/composables'

import { useMappedGetter } from '@/store'
import { OwnButton, OwnCallout } from '@/ui'
import { useI18n } from '@/utils'

const router = useRouter()
const route = useRoute()
const i18n = useI18n()

const locationsUnderCollection = useMappedGetter<
  Array<{ id: string; name: string }> | undefined
>('core/brand/active/locationsUnderCollections')

const hasLocationsUnderCollections = computed(() => {
  if (locationsUnderCollection.value) {
    return locationsUnderCollection.value.length > 0
  }

  return false
})

const title = computed(() => {
  if (
    locationsUnderCollection.value &&
    locationsUnderCollection.value?.length
  ) {
    return i18n.$t('labels.title', {
      locationName: locationsUnderCollection.value[0]?.name,
    })
  }

  return undefined
})

const isOnCollectionsPage = computed(() => {
  return route?.name === 'brand.billing.methods'
})

const onFixClick = () => {
  router.push({
    name: 'brand.billing.methods',
  })
}
</script>
