import { deliveryApi } from '@/api'

export default {
  async loadDeliveryConfigurations({ commit, dispatch, rootGetters }) {
    const locationIds = rootGetters['core/locations/ids']

    await dispatch('sanitizeConfigurations')

    const locationConfigurations = await Promise.all(
      locationIds.map(async (locationId) => {
        try {
          return [
            locationId,
            await deliveryApi.getDeliveryConfiguration(locationId),
          ]
        } catch (err) {
          return null
        }
      })
    )

    const configurations = Object.fromEntries(
      locationConfigurations.filter((configPair) => !!configPair)
    )

    commit('ADD_CONFIGURATIONS', { configurations })
  },
  sanitizeConfigurations({ commit }) {
    commit('SANITIZE_CONFIGURATIONS')
  },
  async saveDraftConfiguration({ commit, getters }) {
    commit('SET_SAVING', true)
    const { enabled, locationId, zones } = getters.draftConfiguration

    const updatedConfig = await deliveryApi.updateDeliveryConfiguration(
      locationId,
      { enabled, zones }
    )
    commit('SET_SAVING', false)

    commit('UPDATE_CONFIGURATION', {
      configurationUpdate: updatedConfig,
      locationId,
    })

    commit('SET_DRAFT_CONFIGURATION', updatedConfig)
  },
  setDraftConfiguration({ commit }, configuration) {
    commit('SET_DRAFT_CONFIGURATION', configuration)
  },
}
