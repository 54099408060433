/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable vue/sort-keys */

export const colors = {
  blue: {
    50: '#ebf2fe',
    100: '#bad3fb',
    200: '#a2c3fa',
    300: '#72a4f7',
    400: '#4285f4',
    500: '#2a75f3',
    600: '#1266f1',
    700: '#0b51c5',
    800: '#0a47ac',
    900: '#07337c',
  },
  gray: {
    50: '#F0F4F8',
    100: '#D9E2EC',
    200: '#BCCCDC',
    300: '#9FB3C8',
    400: '#829AB1',
    500: '#627D98',
    600: '#486581',
    700: '#334E68',
    800: '#243B53',
    900: '#102A43',
  },
  purple: {
    50: '#E6E6FF',
    100: '#C4C6FF',
    200: '#A2A5FC',
    300: '#8888FC',
    400: '#7069FA',
    500: '#5D55FA',
    600: '#4D3DF7',
    700: '#3525E6',
    800: '#1D0EBE',
    900: '#0C008C',
  },
  teal: {
    50: '#EEFDF6',
    100: '#C3FAE2',
    200: '#96F3CE',
    300: '#62EEBB',
    400: '#31CA97',
    500: '#1CB687',
    600: '#109D77',
    700: '#0D8468',
    800: '#06715C',
    900: '#004E41',
  },
  red: {
    50: '#FFE3E3',
    100: '#FFBDBD',
    200: '#FF9B9B',
    300: '#F86A6A',
    400: '#EF4E4E',
    500: '#E12D39',
    600: '#CF1124',
    700: '#AB091E',
    800: '#8A041A',
    900: '#610316',
  },
  orange: {
    50: '#FFEFE6',
    100: '#FFD3BA',
    200: '#FAB38B',
    300: '#EF8E58',
    400: '#E67635',
    500: '#C65D21',
    600: '#AB4E19',
    700: '#8C3D10',
    800: '#77340D',
    900: '#572508',
  },
}

export const semanticColors = {
  primary: '#2e2e36',
  secondary: colors.blue[600],
  tertiary: '#102252',
  accent: '#006dff',
  text: '#1d253c',
  'text-secondary': '#777d89',
  'text-tertiary': '#B7BCCB',
  'text-light': '#e8ecf2',
  bg: '#52525c',
  'bg-light': '#f5f6f8',
  'bg-dark': '#414148',
  'dark-gray': '#626569',
  gray: '#d8d9dc',
  'light-gray': '#e8ecf2',
  white: '#ffffff',
  disabled: '#a1a4a8',
  error: '#e32323',
  'error-dark': '#af0000',
  'error-light': '#ff604d',
  info: '#33b5e5',
  'info-dark': '#0099cc',
  'info-light': '#75e7ff',
  warning: '#ffbb33',
  'warning-dark': '#ff8800',
  success: '#00c851',
  'success-dark': '#007e33',
  overlay: 'rgba(46, 46, 54, 0.8)',
}
/* eslint-enable sort-keys-fix/sort-keys-fix */
/* eslint-enable vue/sort-keys */

/**
 * @see @/styles/settings/_colors.scss
 * Normalizes a color string against the theme
 * @param {string} color a color string
 * @returns {string} color a hex color
 */
export const getThemeColor = (color) => {
  // Check for a semantic color name
  const semanticColor = semanticColors[color]
  if (semanticColor) return semanticColor

  // Check for a color from our own palette
  const parts = color && color.split('-')
  if (parts && parts.length == 2 && colors[parts[0]][parts[1]]) {
    return colors[parts[0]][parts[1]]
  }

  // Take the color as-is as either a raw color string or Vuetify color
  return color
}

/**
 * Returns a border radius based on its size identifier
 * @param {string} name The border radius identifier
 * @returns {number} The radius value
 */
export const getBorderRadius = (name) => {
  switch (name) {
    case 'lg':
      return '32px'
    case 'xl':
      return `84px` // 14 * 6
    case 'pill':
      return '9999px'
    case 'circle':
      return '50%'
    default:
      return '14px'
  }
}

/**
 * Returns a breakpoint size in pixels (upper limit)
 * @param {string} name The name of the breakpoint size
 * @returns {number} The breakpoint in pixels
 */
export const getBreakpoint = (name) => {
  switch (name) {
    case 'lg':
      return 1920
    case 'md':
      return 1280
    case 'sm':
      return 960
    case 'xs':
      return 600
  }
}
