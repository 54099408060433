<i18n locale="en">
{
  "actions": {
    "add": "Add Locations"
  },
  "empty": "No locations selected"
}
</i18n>

<template>
  <div>
    <div class="flex-col gap-3">
      <OwnCardList
        v-if="selectedLocations.length"
        :value="selectedLocations"
        control
      >
        <template #item="{ item }">
          <div class="flex-row gap-3 justify-between">
            <OwnType
              class="text--truncate"
              :text="item.name"
              :title="item.name"
              variant="subtitle"
              color="primary"
            />

            <button class="flex-shrink-0 text-color-secondary">
              <PhXCircle size="24" @click="removeSelectedLocation(item.id)" />
            </button>
          </div>
        </template>
      </OwnCardList>

      <OwnOption v-else el="div">
        <DefaultEmptyState :message="$t('empty')">
          <template #icon>
            <PhBookBookmark size="24" />
          </template>
        </DefaultEmptyState>
      </OwnOption>

      <OwnButton
        :text="$t('actions.add')"
        @click="showAddLocationDialog = true"
      />
    </div>

    <LocationSelectorDialog
      v-if="showAddLocationDialog"
      :brand-id="brandId"
      @select="onLocationSelect"
      @close="closeDialog"
    />
  </div>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { PhBookBookmark, PhXCircle } from 'phosphor-vue/dist/phosphor-vue.esm'

import { onMounted, ref, watch } from 'vue'

import { DefaultEmptyState } from '@/components/empty'
import { OwnButton, OwnCardList, OwnType, OwnOption } from '@/ui'

import { Location } from '../../types'

import LocationSelectorDialog from './LocationSelectorDialog.vue'
import { useLocationCache } from './useLocationCache'

const props = defineProps<{
  /**
   * Brand ID is required due to our wild acl system
   */
  brandId: string
  value: string[]
}>()

const selectedLocationIds = useVModel(props, 'value')

const showAddLocationDialog = ref(false)

const { addToCache, loadLocations, selectedLocations } =
  useLocationCache(selectedLocationIds)

const removeSelectedLocation = (removalId: string) => {
  const indexToRemove = selectedLocationIds.value.findIndex(
    (selectedId) => selectedId === removalId
  )
  selectedLocationIds.value.splice(indexToRemove, 1)
}

const closeDialog = () => {
  showAddLocationDialog.value = false
}

const onLocationSelect = (locations: Location[]) => {
  addToCache(locations)

  for (const { id } of locations) {
    selectedLocationIds.value.push(id)
  }

  closeDialog()
}

watch(selectedLocationIds, async (value) => {
  await loadLocations(value)
})

onMounted(async () => {
  await loadLocations(selectedLocationIds.value)
})
</script>
