<template>
  <OwnStack
    class="w-full h-full"
    :class="`bg-background-${background}`"
    justify="center"
    align="center"
  >
    <OwnProgressSpinner />
  </OwnStack>
</template>

<script>
import { OwnStack, OwnProgressSpinner } from '@/ui'

export default {
  name: 'FullScreenLoader',
  components: {
    OwnProgressSpinner,
    OwnStack,
  },
  props: {
    background: {
      type: String,
      default: 'primary',
      validator: (v) => ['primary', 'secondary'].includes(v),
    },
  },
}
</script>
