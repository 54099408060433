<template>
  <OwnMenu
    align-y="bottom"
    align-x="left"
    :content-width="contentWidth"
    :slide="['x']"
  >
    <template #trigger="{ on }">
      <OwnStatusBadge
        class="action-chip no-select"
        :color="isActive ? 'bluetiful' : 'slate'"
        :label="chipData.displayName"
        clamp
        @mouseenter="isHovering = true"
        @mouseleave="isHovering = false"
        v-on="on"
      >
        <template #right-action>
          <button
            v-if="isHovering"
            class="action-chip__more-icon"
            v-on="on"
            @click.stop="$emit('actionOpen')"
          >
            <OwnIcon icon="more" />
          </button>
        </template>
      </OwnStatusBadge>
    </template>
    <template #content="{ close }">
      <OwnCard class="action-chip__menu-content" border-color="slate-200">
        <button
          v-for="action of actions"
          :key="action.label"
          class="action-chip__action-text text-paragraph no-select"
          :class="[action.color]"
          @click="actionClicked(action, close)"
          v-text="action.label"
        ></button>
      </OwnCard>
    </template>
  </OwnMenu>
</template>

<script>
import { OwnCard, OwnIcon, OwnMenu, OwnStatusBadge } from '@/ui'

export default {
  name: 'ActionChip',
  components: {
    OwnCard,
    OwnIcon,
    OwnMenu,
    OwnStatusBadge,
  },
  props: {
    /** Actions To Render */
    actions: {
      type: Array,
      default: () => [],
    },

    /**
     * Data defining a chip
     * {
     *   id: string
     *   displayName: string
     * }
     */
    chipData: { type: Object, required: true },

    /**
     * Content Width
     */
    contentWidth: {
      type: String,
      default: '225',
    },

    /**
     * is chip showing in active state
     */
    isActive: { type: Boolean, default: false },
  },
  data() {
    return {
      isHovering: false,
      isHoveringRemove: false,
    }
  },
  methods: {
    actionClicked({ value, preventClose }, close) {
      const { chipData } = this

      this.$emit('action', { action: value, ...chipData })

      if (!preventClose) {
        close()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.action-chip {
  cursor: pointer;

  &__more-icon {
    margin-left: 4px;
  }

  &__menu-content {
    position: relative;
  }

  &__action-text {
    width: 100%;
    padding: 8px 12px;
    cursor: pointer;
    text-align: left;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: $bluetiful-200;
    }
  }
}
</style>
