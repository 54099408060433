<i18n locale="en">
{
  "actions": {
    "support": "Contact Support"
  }
}
</i18n>

<template>
  <TriggerSupport v-slot="{ triggerSupport }">
    <InfoPopover v-if="iconOnly">
      <template #trigger>
        <OwnButton small @click="triggerSupport">
          <template #icon-left>
            <PhChatCircleText class="text-color-secondary" size="24" />
          </template>
        </OwnButton>
      </template>
      <template #content>
        <span
          class="text-subtitle-2 text-color-secondary"
          v-text="$t('actions.support')"
        ></span>
      </template>
    </InfoPopover>

    <OwnButton
      v-else
      :text="$t('actions.support')"
      small
      large-font
      @click="triggerSupport"
    >
      <template #icon-left>
        <PhChatCircleText class="text-color-secondary" size="24" />
      </template>
    </OwnButton>
  </TriggerSupport>
</template>
<script>
import { PhChatCircleText } from 'phosphor-vue/dist/phosphor-vue.esm'

import { InfoPopover } from '@/components/popovers'
import { OwnButton } from '@/ui'

import TriggerSupport from './TriggerSupport'

export default {
  name: 'SupportNavButton',
  components: {
    InfoPopover,
    OwnButton,
    PhChatCircleText,
    TriggerSupport,
  },
  props: {
    /**
     * Only show icon
     */
    iconOnly: { type: Boolean, default: false },
  },
}
</script>
