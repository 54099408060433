
{
  "actions": {
    "done": "Done"
  },
  "all-jobs": "All",
  "error": "Unable to load application",
  "headings": {
    "attachment": "Resume",
    "comment": "Message",
    "createdAt": "Date Received",
    "jobs": "Applying For",
    "email": "Email",
    "name": "Name",
    "phone": "Phone"
  },
  "loading": "Getting application...",
  "title": "Application Details",
  "view-resume": "View"
}
