<template>
  <OwnCard class="toast" control>
    <OwnStack spacing="4" align="center" row>
      <OwnType
        class="flex-1"
        :text="message"
        variant="subtitle"
        color="primary"
      />
      <component
        :is="icon"
        size="24"
        class="flex-shrink-0"
        :class="iconColor"
      />
    </OwnStack>
  </OwnCard>
</template>

<script>
import {
  PhCheckCircle,
  PhXCircle,
  PhWarningOctagon,
  PhInfo,
} from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnCard, OwnType, OwnStack } from '@/ui'

export default {
  name: 'Toast',
  components: { OwnCard, OwnStack, OwnType },
  props: {
    message: { type: String, required: true },
    timeout: { type: Number, default: 5000 },
    variant: {
      type: String,
      default: 'info',
      validator: (v) => ['info', 'success', 'error', 'warning'].includes(v),
    },
  },
  computed: {
    icon() {
      const { variant } = this

      switch (variant) {
        case 'success':
          return PhCheckCircle
        case 'error':
          return PhXCircle
        case 'warning':
          return PhWarningOctagon
        default:
          return PhInfo
      }
    },
    iconColor() {
      const { variant } = this

      switch (variant) {
        case 'success':
          return 'status-success'
        case 'error':
          return 'status-danger'
        case 'warning':
          return 'status-warning'
        default:
          return 'text-color-secondary'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.toast {
  padding: 12px 16px;
  width: 236px;
}
</style>
