
{
  "actions": {
    "archive": "Archive"
  },
  "errors": {
    "archive": "Unable to archive campaign"
  },
  "status": {
    "draft": "Draft",
    "error": "Error",
    "active": "Sending",
    "paused": "Paused",
    "archived": "Archived",
    "completed": "Sent",
    "populating": "Sending",
    "processing": "Sending",
    "queued": "Sending",
    "scheduled": "Scheduled"
  }
}
