<i18n locale="en">
{
  "label": "Brand Color"
}
</i18n>

<template>
  <OwnInputContainer :label="$t('label')" :errors="inputStatus?.errors">
    <OwnColorPicker :value="themeBrandColor" @input="onUpdate" />
  </OwnInputContainer>
</template>

<script>
import debounce from 'lodash/debounce'
import set from 'lodash/set'

import { mapActions } from 'vuex'

import { Validators } from '@/forms'
import { useMergedTheme } from '@/lib/builder'
import { OwnColorPicker, OwnInputContainer } from '@/ui'

export default {
  name: 'BrandColorEditor',
  components: { OwnColorPicker, OwnInputContainer },
  mixins: [useMergedTheme],
  data() {
    return {
      inputStatus: undefined,
    }
  },
  computed: {
    themeBrandColor() {
      const { mergedTheme } = this

      return mergedTheme?.colors?.brand?.primary
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    async enqueueBrandColorUpdate(newValue) {
      const update = set({}, 'theme.overrides.colors.brand.primary', newValue)

      await this.updateResource(update)
    },
    onUpdate: debounce(function (newValue) {
      const validate = Validators.compose([
        Validators.required,
        Validators.color,
      ])

      const status = validate(newValue)

      this.inputStatus = status

      if (status.valid) {
        this.enqueueBrandColorUpdate(newValue)
      }
    }, 150),
  },
}
</script>
