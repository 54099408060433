<i18n locale="en">
{
  "defaults": {
    "headline": "Heading",
    "subheading": "Subheading"
  }
}
</i18n>

<template>
  <PreviewSheet class="headline-preview" border="primary" radius="0">
    <OwnStack>
      <PreviewTypographyLegacy
        class="headline-preview__text"
        :text="text"
        :variant="variant"
        :color="color"
      />
    </OwnStack>
  </PreviewSheet>
</template>

<script>
import { PreviewSheet, PreviewTypographyLegacy } from '@/lib/builder'

import { OwnStack } from '@/ui'

export default {
  name: 'HeadlinePreview',
  components: {
    OwnStack,
    PreviewSheet,
    PreviewTypographyLegacy,
  },
  props: {
    content: { type: Object, required: true },

    /**
     * Override data for out of module rendering contexts
     */
    populatedData: { type: Object, default: undefined },
  },
  computed: {
    color() {
      const { currentBlock } = this

      return currentBlock?.params?.text ? 'primary' : 'placeholder'
    },
    currentBlock() {
      const { content, populatedData } = this

      return populatedData ?? content
    },
    text() {
      const { currentBlock } = this

      return (
        currentBlock?.params?.text || this.$t(`defaults.${currentBlock?.type}`)
      )
    },
    variant() {
      const { currentBlock } = this

      return currentBlock?.type === 'subheading'
        ? 'paragraphRegular'
        : 'heading2'
    },
  },
}
</script>

<style lang="scss" scoped>
.headline-preview {
  padding: 12px 40px;

  &__text {
    white-space: pre-wrap;
  }
}
</style>
