<template>
  <div class="full-page-card-background h-full w-full">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FullPageCardBackground',
}
</script>

<style lang="scss" scoped>
.full-page-card-background {
  background: url('/static/backgrounds/FullPageCardBackground.svg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
