<i18n locale="en">
{
  "days": {
    "future": "on {date}",
    "today": "Today",
    "tomorrow": "Tomorrow"
  },
  "actions": {
    "schedule": "Change Schedule",
    "subject": "Update Subject",
    "design": "Edit Design",
    "send": "Send Now"
  },
  "titles": {
    "scheduled": "Sending",
    "unscheduled": "Not Scheduled"
  },
  "subtitle": "{day}, {time}"
}
</i18n>

<template>
  <OwnStack spacing="8">
    <TitleBlock
      :title="$t(`titles.${status}`)"
      :subtitle="
        status === 'scheduled'
          ? $t('subtitle', { day: dayText, time: timeText })
          : undefined
      "
      variant="medium"
    />
    <OwnStack class="campaign-controls" spacing="3">
      <OwnButton
        v-for="{ icon, label, primary, action: key } of actions"
        :key="key"
        :primary="primary"
        :text="label"
        @click="processAction(key)"
      >
        <template #icon-right>
          <component :is="icon" size="24" />
        </template>
      </OwnButton>
    </OwnStack>
  </OwnStack>
</template>

<script>
import { format, isToday, isTomorrow } from 'date-fns'
import {
  PhCalendarBlank,
  PhTextAlignLeft,
  PhWrench,
  PhArrowCircleRight,
} from 'phosphor-vue/dist/phosphor-vue.esm'

import { TitleBlock } from '@/components/typography'
import { OwnButton, OwnStack } from '@/ui'

import { toRawLocation } from '@/utils'
import { mapModel } from '@/utils/computed'

import { emailBuilderRouteManager } from '../../../builders/email/router/emailBuilderRouter'
import { textBuilderRouteManager } from '../../../builders/text'

const DATE_FORMAT = 'MMM dd'

export default {
  name: 'CampaignControls',
  components: { OwnButton, OwnStack, TitleBlock },
  props: {
    /** The ID of the current campaign */
    campaign: { type: String, required: true },

    /** The scheduled date for the campaign */
    executeAt: { type: Date, default: null },

    /** The transport of the current campaign */
    transport: { type: String, default: null },

    /** The action performed */
    value: { type: String, default: null },
  },
  data() {
    return {
      actions: [
        { action: 'schedule', icon: PhCalendarBlank, primary: true },
        ...(this.transport === 'email'
          ? [{ action: 'subject', icon: PhTextAlignLeft }]
          : []),
        { action: 'design', icon: PhWrench },
        { action: 'send', icon: PhArrowCircleRight },
      ].map(({ action, ...rest }) => ({
        action,
        label: this.$t(`actions.${action}`),
        ...rest,
      })),
    }
  },
  computed: {
    ...mapModel('action'),
    dayText() {
      const { executeAt } = this

      if (!executeAt) return

      if (isToday(executeAt)) return this.$t('days.today')

      if (isTomorrow(executeAt)) return this.$t('days.tomorrow')

      return this.$t(`days.future`, { date: format(executeAt, DATE_FORMAT) })
    },
    status() {
      return !this.executeAt ? 'unscheduled' : 'scheduled'
    },
    timeText() {
      const { executeAt } = this

      if (!executeAt) return

      return format(executeAt, 'hh:mm a')
    },
  },
  methods: {
    async processAction(action) {
      switch (action) {
        case 'schedule':
        case 'subject':
        case 'send':
          this.action = action
          this.$emit('show-dialog')
          break
        case 'design':
          const routeManager =
            this.transport === 'sms'
              ? textBuilderRouteManager
              : emailBuilderRouteManager
          const editResourceRoute = await routeManager.editResourcePage(
            this.campaign,
            toRawLocation(this.$route)
          )

          await this.$router.push(editResourceRoute)
          break
        default:
          throw new Error('Not a valid action')
      }
    },
  },
}
</script>
