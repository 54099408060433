<template>
  <!-- eslint-disable vue/no-v-html -- linkify escapes HTML, so there is no risk of XSS from this content -->
  <span
    v-if="hotlinkUrls"
    :class="[
      'preview-typography',
      preserveWhitespace && 'preview-typography--preserve-whitespace',
    ]"
    :style="style"
    v-html="linkifiedText"
  />
  <!-- eslint-enable -->
  <span
    v-else
    :class="[
      'preview-typography',
      preserveWhitespace && 'preview-typography--preserve-whitespace',
    ]"
    :style="style"
    v-text="text"
  ></span>
</template>

<script>
import get from 'lodash/get'

import { linkify } from '@/utils'

import { useMergedTheme } from '../mixins/useMergedTheme'

import { typographyColors, typographyMapping } from './typographyMapping'

export default {
  name: 'PreviewTypographyLegacy',
  mixins: [useMergedTheme],
  props: {
    /** Text alignment */
    align: {
      type: String,
      default: 'left',
      validator: (v) => ['left', 'center', 'right'].includes(v),
    },

    /** Color */
    color: {
      type: String,
      default: undefined,
      validator: (v) =>
        [
          'primary',
          'secondary',
          'placeholder',
          'brand',
          'success',
          'info',
          'warning',
          'danger',
          'white',
          'black',
          'inherit',
          undefined,
        ].includes(v),
    },

    /**
     * Font choice
     */
    font: {
      type: String,
      default: 'primary',
      validator: (v) => ['primary', 'secondary'].includes(v),
    },

    /**
     * Hotlink urls
     */
    hotlinkUrls: { type: Boolean, default: false },

    /**
     * Preserve whitespace in text
     */
    preserveWhitespace: { type: Boolean, default: false },

    /** Text */
    text: { type: String, required: true },

    /** Variant */
    variant: {
      type: String,
      default: 'paragraphRegular',
      validator: (v) =>
        [
          'heading1',
          'heading2',
          'heading3',
          'heading4',
          'titleLarge',
          'titleRegular',
          'titleSmall',
          'titleExtraSmall',
          'paragraphLarge',
          'paragraphRegular',
          'paragraphSmall',
          'paragraphExtraSmall',
          'buttonLarge',
          'buttonRegular',
          'buttonSmall',
          'bold',
          'link',
          'inherit',
          undefined,
        ].includes(v),
    },
  },
  computed: {
    cssColor() {
      const { color, mergedTheme, variant } = this

      if (color === 'white') {
        return '#ffffff'
      }

      if (color === 'black') {
        return '#0D0D0D'
      }

      if (color === 'inherit') {
        return undefined
      }

      if (color) return get(mergedTheme.colors, typographyColors[color])

      return get(
        mergedTheme.colors,
        typographyColors[typographyMapping[variant].defaultColor]
      )
    },
    fontName() {
      const { mergedTheme, variant, font } = this

      if (font) return mergedTheme.font[font]

      const { defaultFontFamilyVariant } = typographyMapping[variant]

      return mergedTheme.font[defaultFontFamilyVariant]
    },
    linkifiedText() {
      const { hotlinkUrls, text } = this

      if (hotlinkUrls) {
        return linkify(text, {
          className: 'preview-typography__link',
        })
      }

      return text
    },
    style() {
      const { fontName, variant, align, cssColor } = this

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { defaultFontFamilyVariant, defaultColor, ...style } =
        typographyMapping[variant]

      return {
        ...style,
        color: cssColor,
        fontFamily: fontName,
        marginBottom: 0,
        textAlign: align,
      }
    },
  },
}
</script>

<style lang="scss">
/* Unscoped because the <a> tags generated by linkify don't have the scoped styles attribute */
.preview-typography .preview-typography__link {
  color: $bluetiful-400;
}
</style>
<style lang="scss" scoped>
.preview-typography {
  cursor: default;

  &--preserve-whitespace {
    white-space: pre-line;
  }
}
</style>

<style lang="scss" scoped src="./fonts.scss" />
