import { BuilderExtension, type BuilderState } from '@/lib/builder'

import { Store } from '@/lib/store'

import { type CampaignResource, type SmsSection } from '../types'

export interface TextBuilderState {
  templateId?: string | null
}

const textBuilder = Store.extend<
  TextBuilderState,
  BuilderState<CampaignResource, SmsSection>
>(
  {
    actions: {},
    getters: {},
    mutations: {},
    state: {},
  },
  BuilderExtension({
    moduleName: 'textBuilder',
  })
)

const textBuilderModule = Store.createModule(textBuilder, true)

export default textBuilderModule
