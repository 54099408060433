<i18n locale="en">
{
  "label": "Corners",
  "options": {
    "rounded": "Rounded",
    "square": "Square"
  }
}
</i18n>

<template>
  <OwnInputContainer :label="$t('label')">
    <OwnSegmentedControl
      :value="themeCorners"
      :options="options"
      @input="onCornerUpdate"
    />
  </OwnInputContainer>
</template>

<script>
import set from 'lodash/set'

import { mapActions } from 'vuex'

import { useMergedTheme } from '@/lib/builder'
import { OwnInputContainer, OwnSegmentedControl } from '@/ui'

const options = [
  {
    value: 'rounded',
  },
  {
    value: 'square',
  },
]

export default {
  name: 'BorderStyleEditor',
  components: {
    OwnInputContainer,
    OwnSegmentedControl,
  },
  inject: ['storeModule'],
  mixins: [useMergedTheme],
  data() {
    return {
      options: options.map((option) => ({
        ...option,
        label: this.$t(`options.${option.value}`),
      })),
    }
  },
  computed: {
    themeCorners() {
      const { mergedTheme } = this

      const controlRadius = mergedTheme?.borderRadius.control

      if (controlRadius > 0) {
        return 'rounded'
      } else {
        return 'square'
      }
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    async onCornerUpdate(newValue) {
      let update

      if (newValue === 'rounded') {
        update = set({}, 'theme.overrides.borderRadius.primary', 0.5)
      } else {
        update = set({}, 'theme.overrides.borderRadius.primary', 0)
      }

      await this.updateResource(update)
    },
  },
}
</script>
