<template>
  <OTCSendConfirmationDialog
    v-if="showConfirmation"
    :builder-config="emailBuilderConfig"
    @close="showConfirmation = false"
  />
  <SendOTCDialog
    v-else
    :action="action"
    :block-modules="blockModules"
    :methods="methods"
    store-module="emailBuilder"
    :value="show"
    @input="$emit('close-dialog')"
    @submit="onSubmit"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import OTCSendConfirmationDialog from '../../../components/otc/OTCSendConfirmationDialog.vue'
import SendOTCDialog from '../../../components/otc/send-dialog/SendOTCDialog.vue'
import { emailBuilderConfig } from '../config/emailBuilderConfig'
import { methods } from '../methods'

interface PublishDialogProps {
  /**
   * Specific sub-operations to perform with the dialog
   */
  action?: 'schedule' | 'subject' | 'send'
  blockModules?: Array<unknown>
  show: boolean
}

withDefaults(defineProps<PublishDialogProps>(), {
  action: undefined,
  blockModules: () => [],
  show: false,
})
const emit = defineEmits(['close-dialog'])

const showConfirmation = ref(false)
const onSubmit = () => {
  emit('close-dialog')
  showConfirmation.value = true
}
</script>
