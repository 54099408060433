
{
  "actions": {
    "done": "Add Job"
  },
  "include-on-website": "Add to website",
  "error": "Failed to create job",
  "error-set-location": "Failed to create job, please select a location and try again",
  "location": {
    "label": "Location",
    "placeholder": "Select a location"
  },
  "options": "Options",
  "role": {
    "label":"Role",
    "placeholder": "Aa"
  },
  "success": "Successfully created job",
  "title": "Add New Role"
}
