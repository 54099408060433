import Vue from 'vue'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'

export const dequeueResourceCommandsMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): void => {
  Vue.set(
    state,
    '_builder_commandQueue',
    state._builder_commandQueue.filter(
      (command) => command.context.scope !== 'resource'
    )
  )
}
