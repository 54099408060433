<i18n locale="en">
{
  "label": "Link Order",
  "description": "Links that don’t fit will fall into a “More” menu.",
  "types": {
    "page": "Page",
    "action": "Action",
    "partner": "Internal Link",
    "external": "External Link"
  },
  "actions": {
    "add-page": "Page",
    "add-link": "Link",
    "add-action": "Action"
  }
}
</i18n>

<template>
  <OwnStack spacing="3">
    <OwnInputContainer :label="$t('label')" :description="$t('description')">
      <Reorderable
        v-slot="{ item, index }"
        :value="navItems"
        :item-key="navItemKey"
        class="space-y-3"
        @reorder="onItemReorder"
      >
        <OwnOption
          :el="item.type === 'partner' ? 'div' : 'button'"
          @click="onEditClick(item, index)"
        >
          <OwnStack spacing="4" row>
            <DragHandle />
            <OwnStack class="flex-1" spacing="1">
              <OwnType
                :text="item?.params?.label"
                variant="subtitle"
                color="primary"
              />
              <OwnType
                :text="$t(`types.${item.type}`)"
                variant="paragraph-small"
              />
            </OwnStack>

            <NavItemActionMenu
              :item="item"
              @action="onActionSelect($event, index)"
            />
          </OwnStack>
        </OwnOption>
      </Reorderable>
    </OwnInputContainer>

    <OwnStack spacing="3" row>
      <AddNavItemButton
        :text="$t('actions.add-page')"
        @click="showAddPageDialog = true"
      />
      <AddNavItemButton
        :text="$t('actions.add-link')"
        @click="showLinkDialog = true"
      />
      <AddNavItemButton
        :text="$t('actions.add-action')"
        @click="showActionDialog = true"
      />
    </OwnStack>

    <AddPageDialog
      v-if="showAddPageDialog"
      :edit-item="editItem"
      @update="onUpdateItem"
      @close="showAddPageDialog = false"
    />
    <AddLinkDialog
      v-if="showLinkDialog"
      :edit-item="editItem"
      @update="onUpdateItem"
      @close="showLinkDialog = false"
    />
    <AddActionDialog
      v-if="showActionDialog"
      :edit-item="editItem"
      @update="onUpdateItem"
      @close="showActionDialog = false"
    />
  </OwnStack>
</template>

<script>
import set from 'lodash/set'
import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'

import {
  OwnOption,
  OwnStack,
  OwnInputContainer,
  OwnType,
  DragHandle,
  Reorderable,
} from '@/ui'
import { removeObserver, reorder } from '@/utils/helpers'

import AddActionDialog from '../../../../common/resource-dialogs/add-action-dialog/AddActionDialog.vue'
import AddLinkDialog from '../../../../common/resource-dialogs/add-link-dialog/AddLinkDialog.vue'
import AddPageDialog from '../../../../common/resource-dialogs/add-page-dialog/AddPageDialog.vue'

import { navItemKey } from '../common/navItemKey'

import AddNavItemButton from './AddNavItemButton.vue'
import NavItemActionMenu from './NavItemActionMenu.vue'

export default {
  name: 'NavigationOrderEditor',
  components: {
    AddActionDialog,
    AddLinkDialog,
    AddNavItemButton,
    AddPageDialog,
    DragHandle,
    NavItemActionMenu,
    OwnInputContainer,
    OwnOption,
    OwnStack,
    OwnType,
    Reorderable,
  },
  data() {
    return {
      editIndex: undefined,
      editItem: undefined,
      showActionDialog: false,
      showAddPageDialog: false,
      showLinkDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      currentResource: 'websiteBuilder/currentResource',
    }),

    navItems() {
      const { currentResource } = this

      // locations is being removed on the server, and will be cleaned up (https://linear.app/owner/issue/GUE-440/remove-locations-nav-item-on-olympus-and-hermes)
      return (currentResource?.nav ?? []).filter(
        (item) => item.type !== 'action' || item.params.action !== 'locations'
      )
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    navItemKey,
    onActionSelect(event, index) {
      if (event.action === 'edit') {
        this.onEditClick(this.navItems[index], index)
      }
      if (event.action === 'delete') {
        this.removeItem(index)
      }
    },
    onEditClick(item, index) {
      this.editIndex = index
      this.editItem = item

      switch (item.type) {
        case 'page':
          this.showAddPageDialog = true
          break
        case 'action':
          this.showActionDialog = true
          break
        case 'external':
          this.showLinkDialog = true
          break
        default:
        // Pass
      }
    },
    onItemReorder({ newIndex, oldIndex }) {
      const { navItems } = this

      const currentItems = removeObserver(navItems)

      this.updateResource(
        set({}, 'nav', reorder(currentItems, oldIndex, newIndex))
      )
    },
    onUpdateItem(newItem) {
      const { navItems, editIndex } = this

      if (editIndex) {
        const currentItems = removeObserver(navItems)

        Vue.set(currentItems, editIndex, newItem)

        this.updateResource(set({}, 'nav', currentItems))
      } else {
        const currentItems = removeObserver(navItems)

        this.updateResource(set({}, 'nav', [...currentItems, newItem]))
      }
    },
    removeItem(delIndex) {
      const { navItems } = this

      const updatedItems = removeObserver(navItems).filter(
        (_item, idx) => idx !== delIndex
      )

      this.updateResource(set({}, 'nav', updatedItems))
    },
  },
}
</script>
