import { type Olympus } from '@owner/olympus-client'
import {
  PhBookOpen,
  PhBriefcase,
  PhCar,
  PhClock,
  PhCreditCard,
  PhForkKnife,
  PhGlobe,
  PhHandbag,
  PhHeart,
  PhHouse,
  PhLeaf,
  PhMapPin,
  PhPhone,
  PhStar,
  PhTote,
  PhTruck,
  PhUsersFour,
} from 'phosphor-vue/dist/phosphor-vue.esm'
import type Vue from 'vue'

import PhGrainsSlash from './PhGrainsSlash.vue'

export const featureIcons: Record<Olympus.Funnel.FeatureIcon, Vue> = {
  car: PhCar,
  card: PhCreditCard,
  cart: PhHandbag,
  delivery: PhTruck,
  dish: PhForkKnife,
  domains: PhGlobe,
  'grains-slash': PhGrainsSlash as unknown as Vue,
  heart: PhHeart,
  leaf: PhLeaf,
  overview: PhHouse,
  phone: PhPhone,
  pin: PhMapPin,
  star: PhStar,
  story: PhBookOpen,
  team: PhUsersFour,
  time: PhClock,
  tote: PhTote,
  work: PhBriefcase,
}
