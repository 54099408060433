<template>
  <OwnStack class="navbar-hours-preview" align="center" spacing="2" row shrink>
    <PreviewTypographyLegacy :text="label" variant="buttonSmall" />
    <OwnIcon
      :style="{
        color: primaryTextColor,
      }"
      :icon="PhCaretDown"
    />
  </OwnStack>
</template>

<script>
import { PhCaretDown } from 'phosphor-vue/dist/phosphor-vue.esm'

import { PreviewTypographyLegacy, useMergedTheme } from '@/lib/builder'
import { OwnIcon, OwnStack } from '@/ui'

export default {
  name: 'NavbarDropdownPreview',
  components: {
    OwnIcon,
    OwnStack,
    PreviewTypographyLegacy,
  },
  mixins: [useMergedTheme],
  props: {
    /**
     * Label to show
     */
    label: { type: String, required: true },
  },
  data() {
    return {
      PhCaretDown,
    }
  },
  computed: {
    primaryTextColor() {
      const { mergedTheme } = this

      return mergedTheme.colors.text.primary
    },
  },
}
</script>
