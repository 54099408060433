
{
  "delete-confirm": {
    "delete": "Delete Application",
    "go-back": "Go Back",
    "message": "Are you sure you want to delete this application?"
  },
  "errors": {
    "load": "Unable to load applications"
  },
  "headers" : {
    "createdAt": "Received",
    "delete": "Delete",
    "email": "Email",
    "jobs": "Roles",
    "name": "Name",
    "phone": "Phone Number",
    "resume": "Resume"
  },
  "loading": "Getting applications...",
  "no-data": "No applications",
  "no-query-results": "No applications match that search",
  "roles":  "All | {count} role | {count} roles",
  "view-resume": "View"
}
