import { locationsApi } from '@/api'

import { convertPathToObject } from '../../utils/convertPathToObject'

export default {
  /**
   * Clears the location store
   * @param {object} Vuex ignore
   * @param {Function} Vuex.commit ignore
   */
  clearLocation: ({ commit }) => {
    commit('RESET_STATE')
  },

  /**
   * Loads a location into the store
   * @param {object} vuex ignore
   * @param {Function} vuex.commit ignore
   * @param {string} locationId Profitboss Location ID
   */
  loadLocation: async ({ commit }, locationId) => {
    const location = await locationsApi.getLocation(locationId)
    commit('SET_LOCATION', location)
  },

  setLocation({ commit }, location) {
    commit('SET_LOCATION', location)
  },

  /**
   * Updates a location object's attributes
   * @param {object} Vuex ignore
   * @param {Function} Vuex.commit ignore
   * @param {Function} Vuex.dispatch ignore
   * @param {object} Vuex.state ignore
   * @param {object} updatedSettings updated location settings
   */
  updateLocation: async ({ commit, dispatch, state }, updatedSettings) => {
    const location = await locationsApi.updateLocation(
      state.location.id,
      updatedSettings
    )

    commit('SET_LOCATION', location)

    await dispatch('core/location/setLocation', location, { root: true })
  },

  /**
   * Update Square location
   * @param {object} Vuex ignore
   * @param {Function} Vuex.commit ignore
   * @param {object} Vuex.state ignore
   * @param {string} updatedSquareId updated square location Id
   */
  updateSquareLocation: async ({ commit, state }, updatedSquareId) => {
    const location = await locationsApi.updateSquareLocation(
      state.location.id,
      updatedSquareId
    )
    commit('SET_LOCATION', location)
  },

  updateViaPath: (() => {
    let locked = false
    let pendingRequestsByPath = {}

    const processChange = async (
      { dispatch, commit, state },
      { locationId, path, value }
    ) => {
      const change = convertPathToObject(path, value)

      const results = await locationsApi.updateLocation(locationId, change)

      if (state.location && state.location.id === locationId) {
        commit('SET_LOCATION', results)
      }

      await dispatch('brand/updateBrandLocation', results, { root: true })
    }

    return async (store, change) => {
      if (locked) {
        pendingRequestsByPath[change.path] = change
      } else {
        locked = true

        await processChange(store, change)

        do {
          const pendingChanges = Object.values(pendingRequestsByPath)
          pendingRequestsByPath = {}

          for (const pendingChange of pendingChanges) {
            await processChange(store, pendingChange)
          }
        } while (Object.keys(pendingRequestsByPath).length > 0)

        locked = false
      }
    }
  })(),
}
