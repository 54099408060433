
{
  "labels": {
    "confirm": "Publish",
    "publishing": "Publishing",
    "success": "Page Published",
    "error": "Uh oh..."
  },
  "descriptions": {
    "edit-success": "Congrats, your updates are live on your website! It may take a couple of minutes to appear."
  },
  "actions": {
    "continue-edit": "Continue Editing",
    "end-edit": "End Editing",
    "publish": "Publish",
    "check": "Go back and check"
  }
}
