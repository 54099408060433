const convertPathToObject = (pathStr, value) => {
  const pathArr = pathStr.split('.')

  if (pathArr.length === 1) {
    return { [pathArr[0]]: value }
  }

  const final = pathArr.pop()

  return pathArr.reduceRight((acc, item) => ({ [item]: acc }), {
    [final]: value,
  })
}

export { convertPathToObject }
